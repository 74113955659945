import { AbstractCookie } from './AbstractCookie'
import { setEnvironmentPrefixToCookie } from '../../utils/setEnvironmentPrefixToCookie'

export class UtmCookie extends AbstractCookie {
  constructor() {
    super({ cookieName: setEnvironmentPrefixToCookie('utm') })
  }

  get gclid(): string | undefined {
    return this.read('gclid')
  }

  set gclid(value) {
    this.write({ key: 'gclid', data: value })
  }

  get utm_source(): string | undefined {
    return this.read('utm_source')
  }

  set utm_source(value) {
    this.write({ key: 'utm_source', data: value })
  }

  get utm_medium(): string | undefined {
    return this.read('utm_medium')
  }

  set utm_medium(value) {
    this.write({ key: 'utm_medium', data: value })
  }

  get utm_campaign(): string | undefined {
    return this.read('utm_campaign')
  }

  set utm_campaign(value) {
    this.write({ key: 'utm_campaign', data: value })
  }

  get utm_campaingtype(): string | undefined {
    return this.read('utm_campaingtype')
  }

  set utm_campaingtype(value) {
    this.write({ key: 'utm_campaingtype', data: value })
  }

  get utm_content(): string | undefined {
    return this.read('utm_content')
  }

  set utm_content(value) {
    this.write({ key: 'utm_content', data: value })
  }

  get utm_term(): string | undefined {
    return this.read('utm_term')
  }

  set utm_term(value) {
    this.write({ key: 'utm_term', data: value })
  }

  get utm_adgroup(): string | undefined {
    return this.read('utm_adgroup')
  }

  set utm_adgroup(value) {
    this.write({ key: 'utm_adgroup', data: value })
  }

  get utm_network(): string | undefined {
    return this.read('utm_network')
  }

  set utm_network(value) {
    this.write({ key: 'utm_network', data: value })
  }

  get utm_device(): string | undefined {
    return this.read('utm_device')
  }

  set utm_device(value) {
    this.write({ key: 'utm_device', data: value })
  }

  get utm_devicemodel(): string | undefined {
    return this.read('utm_devicemodel')
  }

  set utm_devicemodel(value) {
    this.write({ key: 'utm_devicemodel', data: value })
  }

  get utm_matchtype(): string | undefined {
    return this.read('utm_matchtype')
  }

  set utm_matchtype(value) {
    this.write({ key: 'utm_matchtype', data: value })
  }

  get utm_loc_physical_ms(): string | undefined {
    return this.read('utm_loc_physical_ms')
  }

  set utm_loc_physical_ms(value) {
    this.write({ key: 'utm_loc_physical_ms', data: value })
  }
}
