import React from 'react'
import { navigate } from 'gatsby'

import { type CancelSubscriptionResponse, CancelSubscriptionUseCase } from '@legal/subscription'

export const useUnsubscribeHandlerCallback = ({
  subscriptionToCancelId,
  unsubscribeReason
}: {
  subscriptionToCancelId?: string
  unsubscribeReason?: string
}): {
  unsubscribeHandlerCallback: () => void
} => {
  const unsubscribeHandlerCallback = React.useCallback((): void => {
    if (!subscriptionToCancelId) return
    const onUnsubscribeResponse = (response: CancelSubscriptionResponse): void => {
      if (response.unsubscribeAt) {
        void navigate('/my-account/my-profile', {
          state: {
            toast: {
              type: 'success',
              title: 'Unsubscribed successfully',
              autoDismiss: true,
              text: `Your ${response.subscriptionTypeName} has successfully been canceled. You will not receive further charges and can keep using all its benefits until ${response.unsubscribeAt}.`
            }
          }
        })
      }
    }

    CancelSubscriptionUseCase({
      successCallback: onUnsubscribeResponse,
      cancelSubscriptionRequest: {
        subscriptionId: subscriptionToCancelId,
        unsubscribeReason
      }
    })
  }, [subscriptionToCancelId, unsubscribeReason])

  return { unsubscribeHandlerCallback }
}
