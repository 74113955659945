/* eslint-disable @typescript-eslint/strict-boolean-expressions */

/* eslint-disable @typescript-eslint/no-unused-expressions */

/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable no-useless-escape */
import React from 'react'
import sanitizeHtml from 'sanitize-html'

import alphabet from '@legal/shared/data/json/alphabet.json'
import { checkReferenceValueAgainstFormValue } from './newStepStructure'
import { formatDate } from '@legal/shared/utils/formatDate'
import { formatEuropeanNumber } from '../utils/formatEuropeanNumber'
import { formatUSANumber } from '../utils/formatUSANumber'
import { sanitizeOptions } from '../constants/formToTemplateConstants'
import { truncateto2Decimals } from '../utils/truncateTo2Decimals'

const emptyString = '_________'

export const FormToTemplate = (
  template,
  form,
  noRenderFields,
  changedFieldName,
  toggleGroupNames,
  itemChangedKey,
  newRenderedFields
): React.JSX.Element => {
  const scoppedTemplateGroups = template?.templateGroups
  const groupsToPrint = []
  const allGroupsToPrint = []
  const listNumberingObj = {} // Keeps track of the numbering for all template lists.

  scoppedTemplateGroups.forEach((group) => {
    if (group) {
      const refFieldChanged = group.ref.find((ref) => {
        return ref.name === changedFieldName || (newRenderedFields || []).indexOf(ref.name) !== -1
      })
      const content = group.ref.length > 0 ? contentSelector(group, form, noRenderFields) : group.content
      if (content !== undefined) {
        const newTemplateGroup = {
          content,
          name: group.name,
          refFieldChanged: refFieldChanged || '',
          style: group.style ?? '',
          pageBreak: group.pageBreak,
          component: group.component
        }
        if (group.component !== 'hideInTemplate') {
          groupsToPrint.push(newTemplateGroup)
        }
        allGroupsToPrint.push(newTemplateGroup)
      }
    }
  })

  return (
    <div
      className='templates-container copying-not-allowed'
      style={{
        letterSpacing: '-.3px',
        fontWeight: 'normal',
        fontFamily: 'Lora, serif',
        fontSize: '16px',
        lineHeight: '24px'
      }}
    >
      {groupsToPrint.map((group, index) => {
        const isLastGroup = groupsToPrint.length === index + 1
        const inlineStyles = mapClassesToStyles(group, isLastGroup)

        return (
          <div key={group.name} className={'template-group-object-container'} style={inlineStyles}>
            {toggleGroupNames && typeof toggleGroupNames === 'boolean' && <h2>{group.name}</h2>}
            <div
              className={group.refFieldChanged ? 'itemChanged' : ''}
              key={group.refFieldChanged ? `itemChangedKey-${(itemChangedKey.current++).toString()}` : 'group.key'}
            >
              {cleanString(
                group.content,
                form,
                changedFieldName,
                listNumberingObj,
                noRenderFields,
                scoppedTemplateGroups,
                allGroupsToPrint
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}

const cleanString = (
  content,
  form,
  changedFieldName,
  listNumberingObj,
  noRenderFields,
  allTemplateGroups,
  groupsToPrint
): React.JSX.Element => {
  const regexpWithCurlyBrackets = /\{{.*?\}}/g
  const regexp2WithPercentage = /\%%.*?\%%/g
  const regexp2WithAts = /\@@.*?\@@/g
  const resWithCurlyBrackets = content.match(regexpWithCurlyBrackets)
  const resWithPercentage = content.match(regexp2WithPercentage)
  const resWithAts = content.match(regexp2WithAts)
  let cleanStringValue = content
  let isTextArea = false
  const isTable = /<table/

  if (resWithCurlyBrackets) {
    resWithCurlyBrackets.forEach((result) => {
      let cleanValue = String(getCleanValueForFormKey(result, form, listNumberingObj, emptyString, noRenderFields))
      if (getFieldTypeFromValue(form, result) === 'textarea') {
        isTextArea = true
      }
      cleanValue = cleanValue.replace(/[\u00A0-\u9999<>&]/gim, (invalidChar) => `&#${invalidChar.charCodeAt(0)};`)

      cleanValue =
        changedFieldName === result.substring(2, result.length - 2) ?
          `<span class="itemChanged">${cleanValue}</span>`
        : cleanValue
      cleanStringValue = removeDataSheetsValue(cleanStringValue)
      cleanStringValue = cleanStringValue.replace(result, cleanValue)
    })
  }
  if (resWithPercentage) {
    resWithPercentage.forEach((result) => {
      let cleanValue = String(getCleanValueForFormKey(result, form, listNumberingObj, '', noRenderFields))
      if (getFieldTypeFromValue(form, result) === 'textarea') {
        isTextArea = true
      }
      cleanValue = cleanValue.replace(/[\u00A0-\u9999<>&]/gim, (invalidChar) => `&#${invalidChar.charCodeAt(0)};`)

      cleanValue =
        changedFieldName === result.substring(2, result.length - 2) ?
          `<span class="itemChanged">${cleanValue}</span>`
        : cleanValue
      cleanStringValue = removeDataSheetsValue(cleanStringValue)
      cleanStringValue = cleanStringValue.replace(result, cleanValue)
    })
  }
  if (resWithAts) {
    resWithAts.forEach((result) => {
      const cleanValue = result.substring(2, result.length - 2)
      let cleanResult = ''
      allTemplateGroups.forEach((group) => {
        if (group.name === cleanValue && checkIfGroupCanBePrinted(group, groupsToPrint)) {
          cleanResult = cleanString(
            group.content,
            form,
            changedFieldName,
            listNumberingObj,
            noRenderFields,
            allTemplateGroups
          )
          cleanStringValue = cleanStringValue.replace(result, cleanResult.props.dangerouslySetInnerHTML.__html)
        }
      })
      if (!cleanResult) {
        cleanStringValue = cleanStringValue.replace(result, '')
      }
    })
  }

  if (isTable) cleanStringValue = cleanStringValue.replace(/(style="(.*?))/, '$1font-size:12pt;')

  if (isTextArea) {
    cleanStringValue = cleanStringValue.replace(/(<br\s*\/?>\s)/gm, '\n')

    return (
      <span
        style={{ whiteSpace: 'pre-line' }}
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(cleanStringValue, sanitizeOptions)
        }}
      />
    )
  } else {
    return (
      <span
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(cleanStringValue, sanitizeOptions)
        }}
      />
    )
  }
}

const checkIfGroupCanBePrinted = (group, groupsToPrint): boolean => {
  let groupsResult = []
  if (groupsToPrint) {
    groupsResult = groupsToPrint.filter((groupPrint) => groupPrint.name === group.name)
  }
  return groupsResult.length > 0
}

const getFieldTypeFromValue = (form, key): any => {
  const formKey = key.substr(2).slice(0, -2)
  return form[formKey] ? form[formKey].type : ''
}

const mapClassesToStyles = (
  group,
  isLastGroup
): {
  fontSize: string
  wordBreak: string
} => {
  const styleObject = {
    fontSize: '12pt',
    wordBreak: 'break-word'
  }

  if (group.pageBreak) styleObject.pageBreakBefore = 'always'
  if (group.component === 'noPageBreak') styleObject.pageBreakInside = 'avoid'
  if (group.style === 'text-center') styleObject.textAlign = 'center'
  if (group.style === 'align-right') styleObject.textAlign = 'right'
  if (group.style === 'bold-text') styleObject.fontWeight = 'bold'
  if (group.style === 'text-justified') styleObject.textAlign = 'justified'

  if (!isLastGroup) styleObject.marginBottom = '20px'
  else styleObject.marginBottom = '-20px'

  return styleObject
}

function isIsoDate(date: string): boolean {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(date)) return false
  const _date = new Date(date)
  return _date instanceof Date && !isNaN(_date.getTime()) && _date.toISOString() === date
}

// Get value to replace FormKey (i.e {{exampleFormKey}})
const getCleanValueForFormKey = (result, form, listNumberingObj, emptyValue, noRenderFields): string => {
  let cleanValue = ''
  const formKey = result.substr(2).slice(0, -2)
  if (formKey && form[formKey]) {
    cleanValue = form[formKey].value ? form[formKey].value : emptyValue
    if (form[formKey]?.value?.label) {
      cleanValue = form[formKey].value.label
    }
    if (isIsoDate(cleanValue)) {
      cleanValue = formatDate({ date: cleanValue })
    }
    if (cleanValue !== '_________') {
      switch (form[formKey].valueType) {
        case 'USA number':
          cleanValue = formatUSANumber(cleanValue, true)
          break
        case 'European number':
          cleanValue = formatEuropeanNumber(cleanValue, true)
          break
      }
    }
  } else if (formKey.substr(0, 7) === 'numList') {
    const listName = formKey.substr(7)

    listNumberingObj[listName] ? listNumberingObj[listName]++ : (listNumberingObj[listName] = 1)
    cleanValue = listNumberingObj[listName].toString()
  } else if (formKey.substr(0, 13) === 'letterListMin') {
    const listName = formKey.substr(13)

    listNumberingObj[listName] ? listNumberingObj[listName]++ : (listNumberingObj[listName] = 1)
    cleanValue = alphabet.listMin[listNumberingObj[listName].toString() - 1]
  } else if (formKey.substr(0, 13) === 'letterListMax') {
    const listName = formKey.substr(13)

    listNumberingObj[listName] ? listNumberingObj[listName]++ : (listNumberingObj[listName] = 1)
    cleanValue = alphabet.listMax[listNumberingObj[listName].toString() - 1]
  } else if (formKey.includes('[[')) cleanValue = getCleanMath(formKey, form, noRenderFields)
  else cleanValue = emptyValue

  return cleanValue
}

// Remove html data-sheets-value attribute from html, since it gets in  the way of cleaning string
const removeDataSheetsValue = (cleanString): any => {
  if (!cleanString) return cleanString
  const attributeToRemove = 'data-sheets-value="'
  const indexOfAttribute = cleanString.indexOf(attributeToRemove)
  if (indexOfAttribute > -1) {
    const endIndex = cleanString.indexOf('"', indexOfAttribute + attributeToRemove.length)
    cleanString = cleanString.substring(0, indexOfAttribute) + cleanString.substring(endIndex + 1, cleanString.length)
    return removeDataSheetsValue(cleanString)
  }
  return cleanString
}

const contentSelector = (group, form, noRenderedFields): any => {
  const referencesOrLogicActive = group.templateGroupLogic
  const resolvedRefsArray = []

  group.ref.forEach((singleRef) => {
    if (!noRenderedFields.includes(singleRef.name)) {
      let formValue
      const groupNameEqual = group.name === singleRef.name
      const groupRefHasValue = singleRef.value !== ''
      if (form?.[singleRef.name]?.value) {
        formValue = form[singleRef.name].value.value ?? form[singleRef.name].value
      }
      const refFieldEmptyAndFormFieldHasValue = groupNameEqual && !groupRefHasValue && formValue !== ''

      if (checkReferenceValueAgainstFormValue(singleRef.value, formValue) || refFieldEmptyAndFormFieldHasValue) {
        resolvedRefsArray.push(true)
      } else {
        resolvedRefsArray.push(false)
      }
    } else {
      resolvedRefsArray.push(false)
    }
  })

  if (isGroupVisible(resolvedRefsArray, referencesOrLogicActive)) return group.content
}

const isGroupVisible = (resolvedRefsArray, referencesOrLogicActive): boolean => {
  let showGroup = true
  if (resolvedRefsArray.length > 0) {
    const atLeastOneReferenceIsFalse = resolvedRefsArray.indexOf(false) !== -1
    const allReferencesAreFalse = resolvedRefsArray.indexOf(true) === -1
    if (
      (referencesOrLogicActive && allReferencesAreFalse) ||
      (!referencesOrLogicActive && atLeastOneReferenceIsFalse)
    ) {
      showGroup = false
    }
  }
  return showGroup
}

const getCleanMath = (stringToStrip, form, noRenderFields): any => {
  const regexp = /\[\[.*?\]\]/g
  const res = stringToStrip.match(regexp)
  let cleanString = stringToStrip
  let formattingType = 'USA number'
  res.forEach((singleRes) => {
    const cleanKey = singleRes.substr(2).slice(0, -2)
    const formElement = form[cleanKey]
    let formElementValue = formElement?.value

    if (noRenderFields?.includes(cleanKey)) {
      formElementValue = '0'
    }
    let singledOutNumber = 0
    const numberWithoutFormat = removeNumberFormatting(formElementValue, formElement.valueType)
    if (numberWithoutFormat) {
      singledOutNumber = numberWithoutFormat
    }
    singledOutNumber = truncateto2Decimals(singledOutNumber)
    formattingType = formElement.valueType
    cleanString = cleanString.replace(singleRes, singledOutNumber)
  })

  let result = eval(cleanString).toFixed(2) // make calculations inside "[[...]]""
  if (formattingType === 'USA number') {
    result = new Intl.NumberFormat('en-US').format(result)
  } else if (formattingType === 'European number') {
    result = new Intl.NumberFormat('de-DE').format(result)
  }

  return result
}

const removeNumberFormatting = (number, currentFormat): number | undefined => {
  let result
  if (currentFormat === 'USA number') {
    number = number.split(',').join('')
  } else if (currentFormat === 'European number') {
    number = number.split('.').join('')
  }
  const formValue = number ? number.split(',').join('.') : 0
  if (formValue && !isNaN(Number(formValue))) {
    result = Number(formValue)
  }
  return result
}
