/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React from 'react'
import { Spinner } from '@npm_leadtech/legal-lib-components/Spinner'
import { navigate } from 'gatsby'

import { AMPLITUDE_TEST_AB_VERSION, amplitudeCompleteUpgrade } from '@legal/shared/amplitude'
import { pushRegistrationToDataLayer, pushSubscriptionNameToDataLayer } from '@legal/shared/utils'
import { type AuthorizationPageComponentProps } from './AuthorizationPageComponentProps'
import { LoginModal } from '../../template'
import { OverlayContext } from '../../../services/utils/contexts'
import { PaymentCookie } from '../../../services/storage/cookies/PaymentCookie'
import { ProductCookie } from '../../../services/storage/cookies/ProductCookie'
import { SubscriptionCookie } from '../../../services/storage/cookies/SubscriptionCookie'
import { UserCookie } from '../../../services/storage/cookies/UserCookie'
import { navigateToDownload } from '../../../services/utils/navigateToDownload'
import { navigateToPaymentDetails } from '../../../services/utils/navigateToPaymentDetails'
import { useApplicationCookie } from '@legal/shared/hooks'
import { useIstestAB8814 } from '../../../services/hooks/useIstestAB8814'
import { useModal } from '../../../services/hooks/useModal'
import { usePaymentProcessingLogic } from '../../../services/hooks/usePaymentProcessingLogic'
import './AuthorizationPageComponent.scss'

export const AuthorizationPageComponent: React.FC<AuthorizationPageComponentProps> = ({ data }) => {
  const { applicationCookie, setApplicationCookieUrlParams } = useApplicationCookie()
  const paymentCookie = new PaymentCookie()
  const subscriptionCookie = new SubscriptionCookie()
  const transactionStatus = usePaymentProcessingLogic(false)
  const productCookie = new ProductCookie()
  const [isTestAB8814] = useIstestAB8814({
    product: productCookie.product
  })
  const [showOverlay] = React.useState(false)
  const loginModal = useModal(true)
  const userCookie = new UserCookie()
  const toastSubscriptionActiveSuccess = data.strapiAuthorizationPage?.toastSubscriptionActiveSuccess ?? undefined

  const onSuccess = (): void => {
    if (applicationCookie && paymentCookie && subscriptionCookie) {
      amplitudeCompleteUpgrade({
        formName: applicationCookie.form?.name,
        subscriptionTypeName: subscriptionCookie.subscriptionTypeName,
        version: AMPLITUDE_TEST_AB_VERSION.PRODUCTION
      })

      pushSubscriptionNameToDataLayer(subscriptionCookie.subscriptionTypeName)
      setApplicationCookieUrlParams('')
      paymentCookie.clear()

      if (applicationCookie.id) {
        if (isTestAB8814) {
          pushRegistrationToDataLayer()
          navigateToPaymentDetails(
            applicationCookie?.form?.urlParams ?? '',
            applicationCookie.id,
            toastSubscriptionActiveSuccess
          )
        } else {
          navigateToDownload(
            applicationCookie?.form?.urlParams ?? '',
            applicationCookie.id,
            toastSubscriptionActiveSuccess
          )
        }
      } else {
        void navigate('/my-account/my-documents/', {
          state: {
            toast: toastSubscriptionActiveSuccess
          }
        })
      }
    }
  }

  React.useEffect(
    function checkingForStatusChanges() {
      switch (transactionStatus) {
        case 'authorised':
        case 'success':
          onSuccess()
          break
        case 'error':
          paymentCookie.paymentStatus = 'error'
          navigateBackToPayment()
          break
        case 'timeout':
          paymentCookie.paymentStatus = 'paying'
          navigateBackToPayment()
          break
        case 'user-login':
          loginModal.changeModalState()
          break
        case 'user-active':
          handleUserActive()
          break
        default:
          break
      }
    },
    [transactionStatus]
  )

  React.useEffect(() => {
    if (!paymentCookie.transactionId) {
      void navigate('/my-account/my-documents/')
    }
  }, [])

  const navigateBackToPayment = (): void => {
    setApplicationCookieUrlParams('')
    void navigate(`/payment${applicationCookie?.form?.urlParams}`)
  }

  const sendEventCompletePayment = (): void => {
    if (applicationCookie && paymentCookie && subscriptionCookie) {
      amplitudeCompleteUpgrade({
        formName: applicationCookie.form?.name,
        subscriptionTypeName: subscriptionCookie.subscriptionTypeName,
        version: AMPLITUDE_TEST_AB_VERSION.PRODUCTION
      })

      pushSubscriptionNameToDataLayer(subscriptionCookie.subscriptionTypeName)
      setApplicationCookieUrlParams('')
      paymentCookie.clear()
    }
  }

  const handleUserLogin = (): void => {
    if (userCookie.token) {
      sendEventCompletePayment()
      navigateToPaymentDetails(
        `${applicationCookie?.form?.urlParams}&login=true`,
        applicationCookie?.id,
        toastSubscriptionActiveSuccess
      )
    }
  }

  const handleUserActive = (): void => {
    if (userCookie.token) {
      sendEventCompletePayment()
      navigateToPaymentDetails(
        `${applicationCookie?.form?.urlParams}&login=true`,
        applicationCookie?.id,
        toastSubscriptionActiveSuccess
      )
    }
  }

  return (
    <div className={'authorization-container'}>
      <div className={'authorization-card'}>
        <Spinner big className={'spinner--primary'} />
        <div className={'authorization-card-text'}>
          <p>{data.strapiAuthorizationPage?.title}</p>
        </div>
        <OverlayContext.Provider value={showOverlay}>
          {loginModal.modalOpen && (
            <LoginModal
              closeFunction={handleUserLogin}
              noBottomSection
              actionLoginSucess={handleUserLogin}
              loginNoApplicationId
              title={`There is already an account with ${userCookie.email}, sign in to continue`}
              isTestAB8814={isTestAB8814}
            />
          )}
        </OverlayContext.Provider>
      </div>
    </div>
  )
}
