/* eslint-disable @typescript-eslint/no-explicit-any */
import { formatDate } from '@legal/shared/utils/formatDate'

export const FieldType = (type: string, value: string | Date | null | undefined, checked: boolean): any => {
  switch (type) {
    case 'DatePicker':
      return (typeof value === 'string' && value.trim() !== '') || value instanceof Date ?
          formatDate({ date: value })
        : ''
    case 'Check':
      return checked ? '1' : '0'
    default:
      return value
  }
}
