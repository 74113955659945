/* eslint-disable @typescript-eslint/no-explicit-any */

import { datadogLogs } from '@datadog/browser-logs'

import { UpdateTransactionStatus } from '../infra'

export function UpdateTransactionStatusUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  transactionId
}: {
  successCallback?: () => void
  errorCallback?: (error: any) => void
  finallyCallback?: () => void
  transactionId: string
}): void {
  UpdateTransactionStatus(transactionId)
    .then(() => {
      datadogLogs.logger.info('UpdateTransactionStatusUseCase')
      if (successCallback) successCallback()
    })
    .catch((error) => {
      datadogLogs.logger.error('UpdateTransactionStatusUseCase', { transactionId }, error)
      if (errorCallback) errorCallback(error)
    })
    .finally(() => {
      if (finallyCallback) finallyCallback()
    })
}
