/* eslint-disable @typescript-eslint/strict-boolean-expressions */

/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react'

import { Button } from '@npm_leadtech/legal-lib-components/Button'
import DocIcon from '../../../assets/images/componentsSvg/doc-24-upload.svg'
import { DocumentActionsMenu } from '../../molecules'
import { type DocumentCardProps } from './UploadedDocumentCardProps'
import { formatDate } from '@legal/shared/utils/formatDate'

import Download from '../../../assets/images/componentsSvg/save-24-px.svg'

import { Loading } from 'src/components/molecules'
import { TextInput } from '@npm_leadtech/legal-lib-components/TextInput'

export const UploadedDocumentCard: React.FC<DocumentCardProps> = ({
  name,
  applicationId,
  updatedAt,
  uploadingFile,
  previewAction,
  downloadAction,
  editAction,
  updateDocumentName,
  deleteClicked,
  data
}) => {
  const [openMenu, setOpenMenu] = React.useState<boolean>(false)
  const [editingName, setEditingName] = React.useState<boolean>(false)
  const [docName, setDocName] = React.useState<string>(name.split('.').slice(0, -1).join())
  const inputNameRef = React.useRef(null)
  const format = name.split('.')[name.split('.').length - 1].toLowerCase()
  const isPDF = format === 'pdf'
  const formattedName = docName.length > 39 ? docName.substring(0, 38) + '...' : `${docName}.${format}`

  const formatText = data.strapiMyDocumentsPage?.documentsSection?.myDocumentCard?.updatedAtFormatText
  const occurrencies = /\[(.*?)\]/.exec(formatText)
  const updatedAtFormatted =
    updatedAt && occurrencies ?
      formatDate({ date: updatedAt, format: formatText }).replace(',', ` ${occurrencies[1]}`)
    : null

  React.useEffect(() => {
    if (inputNameRef.current) {
      inputNameRef.current.focus()
    }
  }, [editingName])

  const onBlurFormName = (e: any): void => {
    if (editAction) {
      editAction(applicationId, `${e.target.value}.${format}`).then(() => {
        setEditingName(false)
        updateDocumentName(applicationId, `${e.target.value}.${format}`)
      })
    }
  }

  const onChangeFormName = (e: any): void => {
    const target = e.target
    const value = target.value
    if (value.length < 75) {
      setDocName(value)
    }
  }

  return (
    <div className={`document-card ${uploadingFile ? 'uploading' : ''}`}>
      <div className='document-card__inner'>
        <div className={'document-card__icon'}>
          <DocIcon />
        </div>
        <div className={'document-card__information'}>
          <div className={'document-card-column document-card__information__general'}>
            {!editingName && <p className={'document__name sans-serif --medium'}>{formattedName}</p>}
            {editingName && (
              <TextInput
                type={'text'}
                placeholder={''}
                givenClass='document-name'
                value={docName}
                required={false}
                isValidGroup={true}
                maxlength={100}
                validate={true}
                onBlur={onBlurFormName}
                onChange={onChangeFormName}
                ref={inputNameRef}
              />
            )}
            <p className={'document__state sans-serif --small'}>{format.toUpperCase()}</p>
            {updatedAtFormatted && (
              <time dateTime={updatedAtFormatted} className={'document__creation'}>
                {updatedAtFormatted}
              </time>
            )}
          </div>
          <div className='document-card-column document-card-column__status'>
            <div className={'document-status__wrapper'}></div>
          </div>
          <div className='document-card-column document-card-column__button'>
            {!uploadingFile && (
              <Button
                noLink
                onClick={() => {
                  downloadAction(applicationId, `${docName}.${format}`)
                }}
                functionParameters={applicationId}
                color='secondary3'
                label={data.strapiMyDocumentsPage?.documentsSection?.myDocumentCard?.downloadText}
                dataQa='download_document_button'
              >
                <Download />
              </Button>
            )}
          </div>
          {!uploadingFile && (
            <DocumentActionsMenu
              editNameClicked={() => {
                setEditingName(true)
              }}
              previewClicked={() => {
                previewAction(applicationId, `${docName}.${format}`)
              }}
              deleteClicked={() => {
                deleteClicked(applicationId, true)
              }}
              deleteAction
              previewAction={isPDF}
              setOpenMenu={setOpenMenu}
              openMenu={openMenu}
              data={data}
            />
          )}
          {uploadingFile && (
            <div className='document-actions__list'>
              <div className='tooltip-menu__item'>
                <Loading />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
