import { ENDPOINTS } from './Endpoints'
import { type UpdateApplicationDataRequest } from '../../application'
import { put } from '@legal/shared/HttpClient'

export async function UpdateApplicationData({
  applicationId,
  request
}: {
  applicationId: string
  request: UpdateApplicationDataRequest
}): Promise<void> {
  await put(ENDPOINTS.UPDATE_APPLICATION_DATA(applicationId), request)
}
