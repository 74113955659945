import { ENDPOINTS } from './Endpoints'
import { type Subscription } from '../../domain'
import { type SubscriptionData } from '../dataObjects'
import { SubscriptionDataToSubscription } from '../adapters'
import { get } from '@legal/shared/HttpClient'

export async function GetSubscriptionTypeUpgrade(): Promise<Subscription[]> {
  const result = await get<SubscriptionData[]>(ENDPOINTS.SUBSCRIPTION_TYPE_UPGRADE, true)
  return result.map(SubscriptionDataToSubscription)
}
