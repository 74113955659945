import { datadogLogs } from '@datadog/browser-logs'

import { ChangeCreditCard } from '../infra'
import { type ChangeCreditCardRequest } from './interfaces'

export function ChangeCreditCardUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  request
}: {
  successCallback: () => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorCallback?: (error: any) => void
  finallyCallback?: () => void
  request: ChangeCreditCardRequest
}): void {
  ChangeCreditCard(request)
    .then(() => {
      datadogLogs.logger.info('ChangeCreditCardUseCase')
      successCallback()
    })

    .catch((error) => {
      datadogLogs.logger.error('ChangeCreditCardUseCase', undefined, error)
      if (errorCallback) errorCallback(error)
    })
    .finally(() => {
      if (finallyCallback) finallyCallback()
    })
}
