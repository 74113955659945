import React from 'react'

import phoneInfo from '../../../assets/images/svg/info_16px.svg'
import { useConfigs } from '@legal/core/configs'

export const TopBarMobileContent: React.FC = () => {
  const {
    SITE_METADATA: { SITE_PHONE, SITE_SCHEDULE_MOBILE }
  } = useConfigs()
  return (
    <>
      <b>
        <a href={`tel:${SITE_PHONE}`}>
          <img src={phoneInfo} alt='phone info' />
          {SITE_PHONE} &emsp;
        </a>
      </b>
      {SITE_SCHEDULE_MOBILE}
    </>
  )
}
