import React from 'react'
import { navigate } from 'gatsby'

import { AMPLITUDE_TEST_AB_VERSION, sendAmplitudeData } from '@legal/shared/amplitude'
import { GetHtmlPreviewByUuidUseCase } from '@legal/application'
import { Layout } from '../../layout'
import { LoginModal } from '../LoginModal'
import { PaymentCookie } from '../../../services/storage/cookies/PaymentCookie'
import { PreviewAndPricing } from '../../molecules'
import { type PreviewPricingTemplateProps } from './PreviewPricingTemplateProps'
import { ReferrerCookie } from '../../../services/storage/cookies/ReferrerCookie'
import { RegisterModal } from '../RegisterModal'
import { SubscriptionCookie } from '../../../services/storage/cookies/SubscriptionCookie'
import { setCookiePolicyFunc } from '../../../services/utils/setCookiePolicyFunc'
import { useApplicationCookie } from '@legal/shared/hooks'
import { useBenefitsListToShow } from '@legal/shared/hooks/useBenefitsListToshow'
import { useConfigs } from '@legal/core/configs'
import { useModal } from '../../../services/hooks/useModal'
import './PreviewPricingTemplate.scss'

export const PreviewPricingTemplate: React.FC<PreviewPricingTemplateProps> = ({
  location,
  version,
  subscription,
  loadingSubscription,
  data,
  isSevenDayAccess = false
}) => {
  const { TARGET_ADDRESS } = useConfigs()
  const { applicationCookie, setApplicationCookieFormName, setApplicationCookieId } = useApplicationCookie()
  const { benefitsListToShow } = useBenefitsListToShow({ subscription })
  const subscriptionCookie = new SubscriptionCookie()
  const paymentCookie = new PaymentCookie()
  const referrerCookie = new ReferrerCookie()
  const loginModal = useModal(true)
  const registerModal = useModal(true)
  const [htmlPreview, setHtmlPreview] = React.useState<string>('')

  if (location.state) {
    if (location.state.applicationId) setApplicationCookieId(location.state.applicationId)
    if (location.state.subscriptionId) subscriptionCookie.subscriptionId = location.state.subscriptionId

    if (location.state.formName) setApplicationCookieFormName(location.state.formName)
  }

  React.useEffect(() => {
    validateApplicationId()
    sendEvents()
  }, [])

  React.useEffect(() => {
    const applicationId = window.history.state?.applicationId
    if (!applicationId) return
    GetHtmlPreviewByUuidUseCase({
      applicationId,
      successCallback: setHtmlPreview
    })
  }, [])

  const validateApplicationId = (): void => {
    if (!applicationCookie || (!applicationCookie.id && !location.state)) {
      setCookiePolicyFunc()
    } else if (paymentCookie.paymentStatus === 'paying') void navigate('/my-account/my-documents/')
  }

  const sendEvents = (): void => {
    const eventProps = {
      initial_document_type: applicationCookie?.form?.name ?? undefined,
      source: referrerCookie.getReferrer(),
      version: AMPLITUDE_TEST_AB_VERSION.PRODUCTION
    }
    sendAmplitudeData('show_pricing_page', eventProps)
  }

  const toggleOpenRegisterCloseLogin = (): void => {
    loginModal.changeModalState()
    registerModal.changeModalState()
  }

  const toggleOpenLoginCloseRegister = (): void => {
    loginModal.changeModalState()
    registerModal.changeModalState()
  }

  const modalComponent = (
    <>
      {registerModal.modalOpen && (
        <RegisterModal
          toggleOpenLoginCloseRegister={toggleOpenLoginCloseRegister}
          isRegisterModal
          closeFunction={registerModal.closeModal}
          onToggleModal={registerModal.changeModalState}
          loginModalOpen={loginModal.modalOpen}
          urlRedirect={registerModal.urlRedirect}
          changeUrlRedirect={registerModal.changeUrlRedirect}
        />
      )}
      {loginModal.modalOpen && (
        <LoginModal
          toggleOpenRegisterCloseLogin={toggleOpenRegisterCloseLogin}
          isLoginModal
          closeFunction={loginModal.closeModal}
          onToggleModal={loginModal.changeModalState}
          registerModalOpen={registerModal.modalOpen}
          isTestAB8814
        />
      )}
    </>
  )

  const handlePriceSelect = (): void => {}
  return (
    <div className='preview-pricing'>
      <Layout
        noFooter
        disclaimerLinks
        environment={TARGET_ADDRESS}
        toggleRegister={registerModal.changeModalState}
        changeUrlRedirect={registerModal.changeUrlRedirect}
        footerContactBar
        hideDesktopNavbar
        isTestAB8814
        logoShowModal
      >
        {modalComponent}
        <h1 className='serif preview-pricing__title'>{data.strapiPreviewPricingPage?.title}</h1>
        <div className='preview-pricing__preview-and-pricing'>
          <div className='preview-pricing__preview-and-pricing__container'>
            <PreviewAndPricing
              buttonCta={data.strapiPreviewPricingPage?.buttonCta}
              template={<div dangerouslySetInnerHTML={{ __html: htmlPreview }} />}
              formType={'formType'}
              formSubType={'formSubType'}
              draftActive
              benefitsList={benefitsListToShow}
              handleContinue={handlePriceSelect}
              loading={loadingSubscription}
              subscription={subscription}
              subscriptionTypeName={subscriptionType?.name ?? ''}
              isSevenDayAccess={isSevenDayAccess}
              version={version}
            />
          </div>
        </div>
      </Layout>
    </div>
  )
}
