import { datadogLogs } from '@datadog/browser-logs'

import HttpClient, { type HttpClientResponse } from '@legal/core/http/http-client'
import { getConfigs } from '@legal/core/configs'

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class GetCustomerLastPaymentByToken {
  public static async Execute({ userToken }: { userToken: string }): Promise<HttpClientResponse<unknown>> {
    const { API_CONFIG } = getConfigs()
    const http = HttpClient.create({
      baseUrl: API_CONFIG.API_PREFIX,
      defaults: {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      }
    })

    const responsePromise = new Promise<HttpClientResponse<unknown>>((resolve, reject) => {
      http
        .get('customer/last-payment')
        .then((response) => {
          datadogLogs.logger.info('GetCustomerLastPaymentByToken')
          resolve(response)
        })
        .catch((error: object | undefined) => {
          datadogLogs.logger.error('GetCustomerLastPaymentByToken', error)
          reject(error)
        })
    })

    return await responsePromise
  }
}
