import { datadogLogs } from '@datadog/browser-logs'

import { type ApiError } from '@legal/shared/HttpClient'
import { GetSubscriptionTypeUpgrade } from '../infra'
import { type Subscription } from '../domain'

export function GetSubscriptionTypeUpgradeUseCase({
  successCallback,
  errorCallback,
  finallyCallback
}: {
  successCallback: (response: Subscription[]) => void
  errorCallback?: (error: ApiError) => void
  finallyCallback?: () => void
}): void {
  GetSubscriptionTypeUpgrade()
    .then((response) => {
      datadogLogs.logger.info('GetSubscriptionTypeUpgradeUseCase')
      successCallback(response)
    })
    .catch((error: unknown) => {
      if ((error as ApiError).message) {
        datadogLogs.logger.error(`GetSubscriptionTypeUpgradeUseCase ${(error as ApiError).message}`)
        if (errorCallback) errorCallback(error as ApiError)
        return
      }
      datadogLogs.logger.error(`GetSubscriptionTypeUpgradeUseCase unknown error`)
      if (errorCallback) errorCallback({ code: 'unknown', message: 'Unknown error' })
    })
    .finally(() => {
      if (finallyCallback) finallyCallback()
    })
}
