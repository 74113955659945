import { ENDPOINTS } from './Endpoints'
import { type UpdateApplicationFormRequest } from '../../application'
import { put } from '@legal/shared/HttpClient'

export async function UpdateApplicationForm({
  applicationId,
  request
}: {
  applicationId: string
  request: UpdateApplicationFormRequest
}): Promise<void> {
  await put(ENDPOINTS.UPDATE_APPLICATION_FORM(applicationId), request)
}
