import React from 'react'

import { Button } from '@npm_leadtech/legal-lib-components/Button'

import { menuMapper } from '@legal/shared/utils/menuMapper'
import { useConfigs } from '@legal/core/configs'

import { type DocumentListMenuProps } from './DocumentListMenuProps'
import { DocumentListMenuSection } from '../../../../src/components/molecules/DocumentListMenuSection'

import './DocumentListMenu.scss'

export const DocumentListMenu: React.FC<DocumentListMenuProps> = ({
  navbarItems,
  documentActionsPage = false,
  subtitle,
  data
}) => {
  const { TARGET_ADDRESS } = useConfigs()
  const finalMenu = menuMapper(navbarItems)
  const pageClass = documentActionsPage ? 'document-actions' : 'all-documents'

  return (
    <div className={`document-list-menu ${documentActionsPage && 'actions-page-bg'}`}>
      {subtitle && <h4>{subtitle}</h4>}
      <div className={`documents-wrapper ${documentActionsPage && 'actions-page-sizes'}`}>
        {finalMenu.map((item, index) => (
          <div className='products__list products__list__section-container' key={index}>
            <DocumentListMenuSection
              products={item.products}
              type={item.type}
              icon={item.icon}
            ></DocumentListMenuSection>
          </div>
        ))}
      </div>
      <div className={`${pageClass}-button-wrapper`}>
        <Button
          isExternal
          color='primary'
          centered
          size='M'
          givenClass={`${pageClass}-button`}
          link={`${TARGET_ADDRESS}/legal-documents/`}
          dataQa='menu-more-documents'
          label={`See ${data.secondaryActionText}`}
        ></Button>
      </div>
    </div>
  )
}
