import { ENDPOINTS } from './Endpoints'
import { put } from '@legal/shared/HttpClient'

export async function UpdateApplicationName({
  applicationId,
  name
}: {
  applicationId: string
  name: string
}): Promise<void> {
  await put(ENDPOINTS.UPDATE_APPLICATION_NAME(applicationId), { name }, true)
}
