import HttpClient, { type HttpClientResponse } from '@legal/core/http/http-client'
import { HttpParams } from '@legal/core/http/http-params'

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class GetCustomerDocumentsByToken {
  public static async Execute({
    userToken,
    limit,
    offset,
    orderBy,
    orderDir
  }: {
    userToken: string
    limit: number
    offset: number
    orderBy: string
    orderDir: string
  }): Promise<HttpClientResponse<unknown>> {
    const http = HttpClient.create({
      baseUrl: `${process.env.API_PREFIX}`,
      defaults: {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      }
    })

    const params = HttpParams.create()
      .set('limit', limit)
      .set('offset', offset)
      .set('orderBy', orderBy)
      .set('orderDir', orderDir)

    const responsePromise = new Promise<HttpClientResponse<unknown>>((resolve, reject) => {
      http
        .get('documents', params)
        .then((response) => {
          resolve(response)
        })
        .catch((error: object | undefined) => {
          reject(error)
        })
    })

    return await responsePromise
  }
}
