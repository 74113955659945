/* eslint-disable no-console */

/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable @typescript-eslint/no-unused-expressions */

/* eslint-disable @typescript-eslint/strict-boolean-expressions */

import { FunctionalityCardsSection } from '@npm_leadtech/legal-lib-components/FunctionalityCardsSection'
import React from 'react'
import { navigate } from 'gatsby'
import { saveAs } from 'file-saver'

import { AMPLITUDE_TEST_AB_VERSION, sendAmplitudeData, setAmplitudeIncrement } from '@legal/shared/amplitude'
import {
  DeleteApplicationUseCase,
  DownloadApplicationPdfUseCase,
  DuplicateApplicationUseCase,
  FindApplicationByUuidUseCase,
  GetHtmlPreviewByUuidUseCase,
  type PdfEncoded
} from '@legal/application'
import { DeleteCustomerDocument, GetCustomerUploadedDocument, UpdateCustomerDocument } from '@legal/document'
import { DeleteDocumentModal, NewDocumentModal, ReadyDocumentModal } from '../../molecules'
import { useApplicationCookie, useIsMobile, useIsTablet } from '@legal/shared/hooks'
import { DOCS_PER_PAGE } from 'src/services/constants/uploadConstants'
import { DocumentCard } from '../DocumentCard'
import { DocumentsGroup } from 'src/components/atoms/DocumentsGroup'
import { type DocumentsSectionProps } from './DocumentsSectionProps'
import { Modal } from '../../layout'
import { ModalPreview } from '../../template'
import { UploadZone } from 'src/components/molecules/UploadZone/UploadZone'
import { UploadedDocumentCard } from '../UploadedDocumentCard'
import { UserCookie } from 'src/services/storage/cookies/UserCookie'
import { cardFunctionalityFromStrapi } from '@legal/shared/adapters'
import { completedStatus } from 'src/services/constants/documentStatus'
import { downloadPdfHelper } from '../../../services/utils/downloadPdfHelper'
import { isMobileDevice } from '../../../services/utils/isMobileDevice'
import { stringSlugify } from '../../../services/utils/stringSlugify'
import { useConfigs } from '@legal/core/configs'
import { useModal } from '../../../services/hooks/useModal'
import './DocumentsSection.scss'

export const DocumentsSection: React.FC<DocumentsSectionProps> = ({
  initialApplications,
  applicationsLoaded = false,
  fetchAndSetApplications,
  currentSubscription,
  uploadedDocuments,
  uploadingFile,
  updateDocumentName,
  deleteDocument,
  data
}) => {
  const {
    applicationCookie,
    replaceApplicationCookie,
    setApplicationCookieIdToDownload,
    setApplicationCookieIsAutomaticallyDownload,
    clearApplicationCookie
  } = useApplicationCookie()
  const { PA_UPLOAD_DOCUMENT_ENABLED, RATAFIA_CONFIG } = useConfigs()
  const [applications, setApplications] = React.useState([...initialApplications])
  const [completedApplications, setCompletedApplications] = React.useState([])
  const [inProgressApplications, setInProgressApplications] = React.useState([])
  const [deleteFunction, setDeleteFunction] = React.useState(() => {})
  const [deleteModal, updateDeleteModal] = React.useState({ show: false, documentName: '' })
  const [previewModal, setPreviewModal] = React.useState<{
    show: boolean
    documentName?: string
    documentType?: string
    documentStateOrSubType?: string
    htmlPreview?: string
  }>({ show: false })
  const [formName, setFormName] = React.useState<string>(applicationCookie?.form?.name ?? '')
  const [reference, setReference] = React.useState<string>()
  const [currentPage, setCurrentPage] = React.useState([1, 1, 1])
  const storedApplicationId = applicationCookie?.id ?? ''
  const applicationIdToDownload = applicationCookie?.idToDownload ?? ''
  const readyDocumentModal = useModal()
  const newDocumentModal = useModal()
  const isMobile = useIsMobile(0)
  const isTablet = useIsTablet()
  const userCookie = new UserCookie()
  const userToken = userCookie ? userCookie.token : ''

  React.useEffect(() => {
    setApplications([...initialApplications])
  }, [initialApplications])

  React.useEffect(() => {
    if (readyDocumentModal && isMobileDevice()) {
      readyDocumentModal.changeModalState()
    }
  }, [])

  React.useEffect(() => {
    setCompletedApplications(applications.filter((doc) => completedStatus.includes(doc.status)))
    setInProgressApplications(applications.filter((doc) => !completedStatus.includes(doc.status)))
  }, [applications])

  const downloadApplicationPdfSuccess = (pdfEncoded?: PdfEncoded): void => {
    if (!pdfEncoded) return
    downloadPdfHelper({
      base64Pdf: pdfEncoded.content,
      applicationName: formName,
      applicationReference: reference,
      tab: null,
      action: 'download'
    })
  }

  const fetchAndDownloadPdf = (applicationId): void => {
    const application = findApplicationById(applicationId)
    if (applicationId) {
      FindApplicationByUuidUseCase({
        applicationId,
        successCallback: (application) => {
          setFormName(application.form.name)
          setReference(application.reference)
        }
      })
    }

    const eventProps = {
      format: 'pdf',
      document_ID: applicationId,
      document_type: stringSlugify(application?.formType),
      document_subtype: (application?.state && stringSlugify(application.state)) || ''
    }
    sendAmplitudeData('download_paid_document', eventProps)
    setAmplitudeIncrement('documents_downloaded', 1)
    sendAmplitudeData('download_document', { document_property: 'LawDistrict' })

    DownloadApplicationPdfUseCase({
      applicationId,
      successCallback: downloadApplicationPdfSuccess
    })
  }

  const getUploadedDocument = (applicationId: string, fileName: string, download?: boolean): void => {
    GetCustomerUploadedDocument.Execute({
      userToken,
      uid: applicationId
    })
      .then((response) => {
        const eventProps = {
          document_property: 'Client'
        }
        sendAmplitudeData(download ? 'download_document' : 'preview_document', eventProps)
        if (response.result) {
          openPreviewPDF(response.result.content, download ? fileName : '')
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const openPreviewPDF = (base64: string, fileName: string): void => {
    const byteCharacters = atob(base64)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    const blob = new Blob([byteArray], { type: 'application/pdf' })
    const blobURL = URL.createObjectURL(blob)

    if (fileName) {
      downloadPdfHelper({
        base64Pdf: base64,
        finalFileName: fileName,
        applicationName: '',
        applicationReference: '',
        tab: null,
        action: 'download'
      })
      return
    }
    if (isMobile) {
      saveAs(blob, fileName || 'document.pdf')
      return
    }
    window.open(blobURL, '_blank')
  }

  const previewUploadedDocument = (applicationId: string, fileName: string): void => {
    getUploadedDocument(applicationId, fileName)
  }

  const downloadUploadedDocument = (applicationId: string, fileName: string): void => {
    getUploadedDocument(applicationId, fileName, true)
  }

  const editUploadedDocument = async (applicationId: string, fileName: string): Promise<void> => {
    await new Promise<void>((resolve, reject) => {
      UpdateCustomerDocument.Execute({
        userToken,
        uid: applicationId,
        fileName
      })
        .then((response) => {
          resolve(response)
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  React.useEffect(() => {
    if (applicationCookie?.isAutomaticallyDownload && applications.length > 0) {
      fetchAndDownloadPdf(applicationIdToDownload)
      setApplicationCookieIsAutomaticallyDownload(false)
      setApplicationCookieIdToDownload(undefined)
    }
  }, [applications, applicationCookie, applicationIdToDownload])

  const editClicked = React.useCallback((applicationId: string, position): void => {
    window.localStorage.setItem('isEditing', 'editing')
    const application = findApplicationById(applicationId)
    replaceApplicationCookie({
      id: applicationId,
      lastStep: undefined,
      fromEdit: true,
      form: {
        name: application.formTypeName,
        driveId: undefined
      }
    })
    let formUrl = `/${application.formUrl}/form/?product=${application.formType.toLowerCase().split(' ').join('-')}`
    if (application.state !== application.formType) {
      formUrl = `${formUrl}&type=${application.state.toLowerCase().split(' ').join('-')}`
    }
    const eventProps = {
      source: position,
      application_ID: applicationId,
      document_type: stringSlugify(application.formType),
      document_subtype: (application?.state && stringSlugify(application.state)) || '',
      version: AMPLITUDE_TEST_AB_VERSION.PRODUCTION
    }
    sendAmplitudeData('edit_document', eventProps)

    void navigate(formUrl)
  }, [])

  const findApplicationById = (applicationId: string): unknown | undefined => {
    return applications.find((application) => application.applicationId === applicationId)
  }

  const findDocumentById = (applicationId: string): unknown | undefined => {
    return uploadedDocuments.find((application) => application.id === applicationId)
  }

  const getHtmlPreviewSuccess = (htmlPreview: string, applicationId: string): void => {
    const application = findApplicationById(applicationId)
    const documentStateOrSubType = application.state
    const documentType = application.formType
    const eventProps = {
      document_property: 'LawDistrict'
    }
    sendAmplitudeData('preview_document', eventProps)
    setPreviewModal({
      show: true,
      documentType,
      documentStateOrSubType,
      htmlPreview
    })
  }

  const previewClicked = React.useCallback((applicationId: string): void => {
    GetHtmlPreviewByUuidUseCase({
      applicationId,
      successCallback: (htmlPreview: string) => {
        getHtmlPreviewSuccess(htmlPreview, applicationId)
      }
    })
  }, [])

  const deleteClicked = React.useCallback(
    (applicationId: string, document = false): void => {
      setDeleteFunction(() => () => {
        document ? deleteDocumentAction(applicationId) : deleteApplication(applicationId)
      })

      const documentName = document ? findDocumentById(applicationId).name : findApplicationById(applicationId).name
      updateDeleteModal({ show: true, documentName })
    },
    [uploadedDocuments]
  )

  const deleteApplicationSuccess = (applicationId: string): void => {
    let updatedApplications = [...applications]
    updatedApplications = updatedApplications.filter((application) => application.applicationId !== applicationId)
    setApplications(updatedApplications)
    if (storedApplicationId === applicationId) {
      clearApplicationCookie()
    }
  }

  const deleteApplication = (applicationId: string): void => {
    updateDeleteModal({ show: false, documentName: '' })
    DeleteApplicationUseCase({
      applicationId,
      successCallback: () => {
        deleteApplicationSuccess(applicationId)
      }
    })
  }

  const deleteDocumentAction = (applicationId: string): void => {
    updateDeleteModal({ show: false, documentName: '' })
    DeleteCustomerDocument.Execute({
      userToken,
      uid: applicationId
    })
      .then((response) => {
        deleteDocument(applicationId)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const updateApplicationName = (applicationId, name): void => {
    const updatedApplications = [...applications]

    updatedApplications.map((application) => {
      if (application.applicationId === applicationId) {
        application.name = name
      }
      return application
    })
    setApplications(updatedApplications)
  }

  const duplicateClicked = React.useCallback((applicationId: string): void => {
    DuplicateApplicationUseCase({
      applicationId,
      successCallback: fetchAndSetApplications
    })
  }, [])

  const closeDeleteModal = (): void => {
    updateDeleteModal({ show: false, documentName: '' })
    setDeleteFunction(null)
  }

  const closePreviewModal = (): void => {
    setPreviewModal({ show: false, documentName: '' })
  }

  const changeCardListPage = (cardListIndex, pageDiff): void => {
    const cardListsLength = [completedApplications.length, inProgressApplications.length, uploadedDocuments.length]
    const currentLength = cardListsLength[cardListIndex]
    const currentMaxPage = currentLength / DOCS_PER_PAGE + 1

    if (currentPage[cardListIndex] + pageDiff > 0 && currentPage[cardListIndex] + pageDiff <= currentMaxPage) {
      const newCurrentPage: number[] = Object.assign([], currentPage)
      newCurrentPage[cardListIndex] = newCurrentPage[cardListIndex] + pageDiff
      setCurrentPage(newCurrentPage)
    }
  }

  const DocumentsCardList = ({ applications }): JSX.Element[] => {
    return applications.map((application) => (
      <DocumentCard
        key={application.applicationId}
        applicationId={application.applicationId}
        subscriptionId={application.subscriptionId}
        formType={application.formType}
        name={application.name}
        formTypeName={application.formTypeName}
        updatedAt={application.updatedAt}
        state={application.state}
        status={application.status}
        actions={application.actions}
        downloadPdf={() => {
          fetchAndDownloadPdf(application.applicationId)
        }}
        subscriptionTypeId={application.subscriptionTypeId}
        editClicked={editClicked}
        deleteClicked={deleteClicked}
        onEditApplicationName={updateApplicationName}
        previewClicked={previewClicked}
        duplicateClicked={duplicateClicked}
        currentSubscription={currentSubscription}
        data={data}
      />
    ))
  }

  const UploadedDocumentsCardList = ({
    applications,
    previewAction,
    downloadAction,
    editAction
  }): React.JSX.Element[] => {
    return applications.map((application) => (
      <UploadedDocumentCard
        key={application.id}
        applicationId={application.id}
        name={application.name}
        updatedAt={application.createdAt.date}
        previewAction={previewAction}
        downloadAction={downloadAction}
        editAction={editAction}
        updateDocumentName={updateDocumentName}
        deleteClicked={deleteClicked}
        data={data}
      />
    ))
  }

  return (
    <>
      {deleteModal.show && (
        <DeleteDocumentModal
          closeFunction={closeDeleteModal}
          deleteFunction={deleteFunction}
          documentName={deleteModal.documentName}
          data={data}
        />
      )}
      {previewModal.show && (
        <Modal title={previewModal.documentType} closeFunction={closePreviewModal}>
          <ModalPreview
            documentStateOrSubType={previewModal.documentStateOrSubType ?? ''}
            documentType={previewModal.documentType ?? ''}
            htmlPreview={previewModal.htmlPreview ?? ''}
            allowCopy='false'
            data={data}
          />
        </Modal>
      )}
      {readyDocumentModal && readyDocumentModal.modalOpen && (
        <ReadyDocumentModal
          closeFunction={readyDocumentModal.closeModal}
          primaryActionText={data.strapiMyDocumentsPage?.documentsSection?.documentReadyModal?.primaryActionText ?? ''}
          title={data.strapiMyDocumentsPage?.documentsSection?.documentReadyModal?.title ?? ''}
          text={{
            html:
              data.strapiMyDocumentsPage?.documentsSection?.documentReadyModal?.text?.data?.childMarkdownRemark?.html ??
              ''
          }}
        />
      )}
      {newDocumentModal?.modalOpen && <NewDocumentModal closeFunction={newDocumentModal.closeModal} />}
      {RATAFIA_CONFIG.ENABLED && (
        <FunctionalityCardsSection
          {...cardFunctionalityFromStrapi({
            data: data?.strapiMyDocumentsPage?.functionalityCards as Queries.StrapiCardFunctionalityDataFragment[],
            handlerCreateDocument: () => {
              newDocumentModal.changeModalState()
            }
          })}
        />
      )}
      {completedApplications.length > 0 && (
        <DocumentsGroup
          title='Completed'
          page={currentPage[0]}
          total={completedApplications.length}
          prevPage={() => {
            changeCardListPage(0, -1)
          }}
          nextPage={() => {
            changeCardListPage(0, 1)
          }}
        >
          <DocumentsCardList
            applications={
              isMobile || isTablet ? completedApplications : (
                completedApplications.slice((currentPage[0] - 1) * DOCS_PER_PAGE, currentPage[0] * DOCS_PER_PAGE)
              )
            }
          />
        </DocumentsGroup>
      )}
      {inProgressApplications.length > 0 && (
        <DocumentsGroup
          title='In Progress'
          page={currentPage[1]}
          total={inProgressApplications.length}
          prevPage={() => {
            changeCardListPage(1, -1)
          }}
          nextPage={() => {
            changeCardListPage(1, 1)
          }}
        >
          <DocumentsCardList
            applications={
              isMobile || isTablet ? inProgressApplications : (
                inProgressApplications.slice((currentPage[1] - 1) * DOCS_PER_PAGE, currentPage[1] * DOCS_PER_PAGE)
              )
            }
          />
        </DocumentsGroup>
      )}
      {(uploadedDocuments.length > 0 || uploadingFile) && (
        <DocumentsGroup
          title='Uploaded'
          link='uploaded'
          page={currentPage[2]}
          total={uploadedDocuments.length}
          prevPage={() => {
            changeCardListPage(2, -1)
          }}
          nextPage={() => {
            changeCardListPage(2, 1)
          }}
        >
          {uploadingFile && (
            <UploadedDocumentCard
              key={uploadingFile}
              name={uploadingFile}
              updatedAt=''
              applicationId=''
              data={data}
              uploadingFile={uploadingFile}
            />
          )}
          {uploadedDocuments.length > 0 && (
            <UploadedDocumentsCardList
              applications={
                isMobile || isTablet ? uploadedDocuments : (
                  uploadedDocuments.slice((currentPage[2] - 1) * DOCS_PER_PAGE, currentPage[2] * DOCS_PER_PAGE)
                )
              }
              previewAction={previewUploadedDocument}
              downloadAction={downloadUploadedDocument}
              editAction={editUploadedDocument}
            />
          )}
        </DocumentsGroup>
      )}
      {PA_UPLOAD_DOCUMENT_ENABLED && <UploadZone data={data} />}
    </>
  )
}
