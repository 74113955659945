import { ENDPOINTS } from './Endpoints'
import { type Subscription } from '../../domain'
import { type SubscriptionData } from '../dataObjects'
import { SubscriptionDataToSubscription } from '../adapters'
import { get } from '@legal/shared/HttpClient'
import { pricingId } from '@legal/shared/data/constants/pricingId'

export async function FindSubscriptionTypeByCode(tokenRequired: boolean): Promise<Subscription[]> {
  const SUBSCRIPTIONS_CODE = pricingId.default
  const subscriptionsData = await get<SubscriptionData[]>(
    ENDPOINTS.FIND_SUBSCRIPTTION_TTYPE_BY_CODE(SUBSCRIPTIONS_CODE),
    tokenRequired
  )
  return subscriptionsData.map(SubscriptionDataToSubscription)
}
