/* eslint-disable react-compiler/react-compiler */

import { Button } from '@npm_leadtech/legal-lib-components/Button'
import { Divider } from '@npm_leadtech/legal-lib-components/Divider'
import { Feedback } from '@npm_leadtech/legal-lib-components/Feedback'
import { GoogleButton } from '@npm_leadtech/legal-lib-components/GoogleButton'
import React from 'react'
import { navigate } from 'gatsby-link'

import { AMPLITUDE_TEST_AB_VERSION, sendAmplitudeData, setAmplitudeUserProperties } from '@legal/shared/amplitude'
import { type Application, FindApplicationByUuidUseCase } from '@legal/application'
import { type CreateCustomerResponse, CreateCustomerUseCase } from '@legal/customer'
import { useApplicationCookie, useDoGoogleSignInCallback } from '@legal/shared/hooks'
import { Feedback8814 } from '../Feedback8814'
import { FormatHTMLAstChildren } from '../FormatHTMLAstChildren'
import { Loading } from '../'
import { type SignUpProps } from './SignUpProps'
import { SubscriptionCookie } from 'src/services/storage/cookies/SubscriptionCookie'
import { TextInput } from '../../form'
import { UserCookie } from '../../../services/storage/cookies/UserCookie'
import { getParamUrlValue } from '../../../services/utils/paramsUrl'
import { pushRegistrationToDataLayer } from '@legal/shared/utils'
import { replaceValues } from 'src/services/utils/replaceAll'
import { scrollToRef } from '../../../services/form/scrollToRef'
import { setCookiePolicyFuncNoRedirect } from '../../../services/utils/setCookiePolicyFunc'
import { useConfigs } from '@legal/core/configs'
import { useQuerySharedComponentsFromPaymentPage } from '@legal/shared/data/graphql'

export const SignUp: React.FC<SignUpProps> = ({
  isModal,
  isTestAB8814,
  onSuccess,
  onResetSuccess,
  handleChangeStatus,
  showThanks
}) => {
  const {
    SITE_METADATA: { SITE_NAME },
    TARGET_ADDRESS
  } = useConfigs()
  const [loading, updateLoading] = React.useState(false)
  const { signUp } = useQuerySharedComponentsFromPaymentPage()
  const { validator } = useQuerySharedComponentsFromPaymentPage()
  const [application, setApplication] = React.useState<Application>()
  const { applicationCookie } = useApplicationCookie()
  const [formData, setFormData] = React.useState({
    email: {
      value: '',
      isValid: true,
      error: signUp?.emailInput?.errorMessages?.text
    },
    password: {
      value: '',
      isValid: true,
      error: signUp?.passwordInput?.errorMessages?.text
    },
    applicationId: ''
  })

  const [status, updateStatus] = React.useState('initial')

  const { doGoogleSignIn } = useDoGoogleSignInCallback({
    isModal,
    formName: applicationCookie?.form?.name
  })

  React.useEffect(() => {
    if (status === 'success' && onSuccess) {
      onSuccess()
    } else if (status === 'error' && onResetSuccess) {
      onResetSuccess()
    }
    // testAB8814
    if (isTestAB8814 && handleChangeStatus) {
      handleChangeStatus(status)
    }
  }, [status])

  React.useEffect(() => {
    const tempForm = { ...formData }
    tempForm.applicationId = applicationCookie?.id ?? ''
    setFormData(tempForm)
    if (applicationCookie?.id) {
      FindApplicationByUuidUseCase({
        applicationId: applicationCookie.id,
        successCallback: setApplication
      })
    }
  }, [])

  const formProduct = getParamUrlValue('product')
  const formType = getParamUrlValue('type')

  const onChange = React.useCallback(
    (e) => {
      const target = e.target
      const value = target.value
      const name = target.name
      const tempForm = { ...formData }

      tempForm[name].value = value

      setFormData(tempForm)
    },
    [formData]
  )

  const resetFieldError = React.useCallback(
    (e) => {
      const target = e.target
      const name = target.name
      const tempForm = { ...formData }

      tempForm[name].isValid = true
      setFormData(tempForm)
    },
    [formData]
  )

  const submitForm = React.useCallback(
    (e) => {
      e.preventDefault()
      const userCookie = new UserCookie()

      const validate = (): boolean => {
        let validData = true

        const tempForm = { ...formData }
        const reEmail = /^([\w][\w+&.-]+)@([\w-]+\.)+([\w-]{2,4})$/
        const reSpecialCharacters = /[!#$%^&*(),?":{}|<>]/
        const isMail = reEmail.test(String(tempForm.email.value).toLowerCase())
        const emailWithSpecialCharacters = reSpecialCharacters.test(String(tempForm.email.value).toLowerCase())

        if (tempForm.password.value.length < 8) {
          tempForm.password.isValid = false
          scrollToRef('password', 100)
          setFormData(tempForm)
          validData = false
        }

        if (tempForm.email.value === '' || !isMail || emailWithSpecialCharacters) {
          tempForm.email.isValid = false
          tempForm.email.error = validator?.incorrectFormatEmail ?? 'This email address isn’t right.'
          scrollToRef('email', 100)
          if (emailWithSpecialCharacters) {
            tempForm.email.error =
              validator?.specialCharactersEmail ??
              'This email you entered contains special characters and cannot be used.'
          }
          setFormData(tempForm)
          validData = false
        }

        return validData
      }

      const handleEmailAlreadyExist = (errorStatus): boolean => {
        let validData = true
        if (errorStatus === 412) {
          const tempForm = { ...formData }

          tempForm.email.isValid = false
          tempForm.email.error =
            replaceValues(validator?.canotBeUsedOnSiteName, { SITE_NAME }) ??
            `The email address you entered cannot be used on ${SITE_NAME}. Please enter a different email to open your account.`
          validData = false
        }
        updateLoading(false)
        scrollToRef('email', 100)

        return validData
      }
      const isValid = validate()
      if (isValid) {
        updateLoading(true)

        const subscriptionCookie = new SubscriptionCookie()
        const subscriptionId = subscriptionCookie.subscriptionId

        const createCustomerSuccessCallback = (response: CreateCustomerResponse): void => {
          const version = AMPLITUDE_TEST_AB_VERSION.PRODUCTION
          userCookie.token = response.token
          userCookie.email = formData.email.value

          let urlParameters = ''
          urlParameters = formProduct ? urlParameters + `product=${formProduct}` : urlParameters + ''
          urlParameters = formType ? urlParameters + `&type=${formType}` : urlParameters + ''

          const eventProps = {
            login_type: 'email',
            initial_document_type: applicationCookie?.form?.name ?? undefined,
            version
          }
          sendAmplitudeData('complete_registration', eventProps)
          setAmplitudeUserProperties('login_type', 'email')

          if (isModal) {
            updateLoading(false)
            updateStatus('success')
            pushRegistrationToDataLayer()
            return
          }
          if (!applicationCookie?.id) {
            void navigate(`/my-account/my-documents?${urlParameters}`, {
              state: {
                toast: {
                  type: 'success',
                  title: signUp?.toastSuccess?.title ?? 'Welcome to your workspace!',
                  text: signUp?.toastSuccess?.text ?? 'All your documents and account details can be found here'
                }
              }
            })
          } else {
            if (response.isInProgress || response.isNew) {
              const step = response.isNew ? 1 : applicationCookie.lastStep + 1
              const urlWithParameters = `${application?.form.url}/form/?product=${application?.form.type
                .toLowerCase()
                .split(' ')
                .join('-')}&type=${application?.stateSlug}`
              void navigate(urlWithParameters, { lastStep: step })
            } else {
              void navigate(`/pricing?${urlParameters}`)
            }
          }
          pushRegistrationToDataLayer()
        }

        CreateCustomerUseCase({
          request: {
            email: formData.email.value,
            password: formData.password.value,
            applicationId: applicationCookie?.id ?? undefined,
            subscriptionId
          },
          successCallback: createCustomerSuccessCallback,
          errorCallback: (error) => {
            handleEmailAlreadyExist(error?.code)
            updateLoading(false)
            if (isModal) {
              updateStatus('error')
            }
          }
        })
      }
    },
    [applicationCookie, formData, formProduct, formType, isModal]
  )

  const resetStatus = React.useCallback(() => {
    updateStatus('initial')
    if (onResetSuccess) {
      onResetSuccess()
    }
  }, [onResetSuccess])

  const feedbackMessage = {
    error: {
      theme: 'error',
      title: signUp?.feedBackError?.title,
      text: signUp?.feedBackError?.text,
      button: {
        label: signUp?.feedBackError?.btnLabel,
        noLink: true,
        onClick: resetStatus
      }
    },
    success: {
      theme: 'success',
      title: signUp?.feedBackSuccess?.title,
      text: signUp?.feedBackSuccess?.text
    }
  }

  const documentApplication = applicationCookie?.form?.name ?? null
  let titleFeedbackMessage = signUp?.alternativeFeedBackSucces?.title
  if (documentApplication) {
    titleFeedbackMessage =
      replaceValues((signUp?.alternativeFeedBackSucces?.alternativeTitle as string) ?? '', {
        FORM_NAME: documentApplication
      }) ?? ''
  }

  const feedbackMessageTestAB8814 = {
    success: {
      theme: 'success',
      title: titleFeedbackMessage,
      text: replaceValues(signUp?.alternativeFeedBackSucces?.text, { SITE_NAME }),
      subText: signUp?.alternativeFeedBackSucces?.subtext,
      children: (
        <Button
          givenClass='btn btn--full'
          label={signUp?.alternativeFeedBackSucces?.btnLabel ?? 'Close'}
          noLink
          onClick={() => {
            location.reload()
          }}
        />
      )
    }
  }

  const handleNoThanks = React.useCallback(() => {
    const URL_LOGO = `${TARGET_ADDRESS}/`
    setCookiePolicyFuncNoRedirect()
    void navigate(URL_LOGO)
  }, [])

  const renderForm = (): React.JSX.Element | undefined => {
    switch (status) {
      case 'initial':
        return (
          <form onSubmit={submitForm}>
            <GoogleButton onClick={doGoogleSignIn} label={signUp?.googleLabel ?? 'Sign up with Google'}></GoogleButton>
            <Divider text={signUp?.divider ?? 'or'}></Divider>
            <TextInput
              type={'text'}
              placeholder={signUp?.emailInput?.placeholder}
              value={formData.email.value}
              name={'email'}
              label={signUp?.emailInput?.label}
              validate={formData.email.isValid}
              errorMessage={formData.email.error}
              isValidGroup={true}
              required={false}
              onChange={onChange}
              onClick={resetFieldError}
            />
            <TextInput
              type={'password'}
              placeholder={signUp?.passwordInput?.placeholder}
              maxlength={'16'}
              value={formData.password.value}
              name={'password'}
              label={signUp?.passwordInput?.label}
              validate={formData.password.isValid}
              errorMessage={formData.password.error}
              required={false}
              isValidGroup={true}
              onChange={onChange}
              onClick={resetFieldError}
            />
            <div className='box--form__body__footer'>
              <Button
                label={signUp?.createAccountCta ?? 'Create account'}
                givenClass='box--form__button--main stretch-button'
                noLinkNoFunc
                dataQa={'createAccount'}
              />
              {showThanks && (
                <div className='box--form__button-noThanks'>
                  <Button
                    dataQa='dataqa-no-thanks'
                    label={signUp?.noThanksCta ?? 'No thanks'}
                    noLink
                    onClick={handleNoThanks}
                    color='tertiary'
                  />
                </div>
              )}
              {signUp?.termsAndConditions?.data?.childMarkdownRemark?.htmlAst && (
                <FormatHTMLAstChildren
                  htmlAstChildren={
                    signUp.termsAndConditions.data.childMarkdownRemark.htmlAst.children as
                      | React.ReactNode
                      | React.ReactNode[]
                  }
                />
              )}
            </div>
          </form>
        )
      case 'error':
        return <Feedback {...feedbackMessage.error} />
      case 'success':
        if (showThanks) {
          return <Feedback8814 {...feedbackMessageTestAB8814.success} />
        }
        return <Feedback {...feedbackMessage.success} />
    }
  }
  return (
    <>
      {loading ?
        <Loading />
      : renderForm()}
    </>
  )
}
