import { datadogLogs } from '@datadog/browser-logs'

import HttpClient, { type HttpClientResponse } from '@legal/core/http/http-client'
import { HttpParams } from '@legal/core/http/http-params'
import { getConfigs } from '@legal/core/configs'

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class GetFormsByType {
  public static async Execute({
    type,
    country
  }: {
    type: string
    country: string
  }): Promise<HttpClientResponse<unknown>> {
    const { API_CONFIG } = getConfigs()
    const http = HttpClient.create({
      baseUrl: API_CONFIG.API_PREFIX,
      defaults: {
        headers: {
          Authorization: API_CONFIG.HTTP_SECRET_KEY
        }
      }
    })

    const params = HttpParams.create().set('type', type).set('country', country)

    const responsePromise = new Promise<HttpClientResponse<unknown>>((resolve, reject) => {
      http
        .get('forms', params)
        .then((response) => {
          datadogLogs.logger.info('GetFormsByType', response)
          resolve(response)
        })
        .catch((error: object | undefined) => {
          datadogLogs.logger.error('GetFormsByType', error)
          reject(error)
        })
    })

    return await responsePromise
  }
}
