/* eslint-disable @typescript-eslint/no-explicit-any */

import { datadogLogs } from '@datadog/browser-logs'

import type { UpgradeSubscriptionRequest, UpgradeSubscriptionResponse } from './interfaces'
import { type ApiError } from '@legal/shared/HttpClient'
import { UpgradeSubscription } from '../infra'

export function UpgradeSubscriptionUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  request
}: {
  successCallback: (response: UpgradeSubscriptionResponse) => void
  errorCallback?: (error: any) => void
  finallyCallback?: () => void
  request: UpgradeSubscriptionRequest
}): void {
  UpgradeSubscription(request)
    .then((response) => {
      datadogLogs.logger.info('UpgradeSubscriptionUseCase')
      successCallback(response)
    })
    .catch((error: ApiError) => {
      datadogLogs.logger.error(`UpgradeSubscriptionUseCase ${error.message}`, request)
      if (errorCallback) errorCallback(error)
    })
    .finally(() => {
      if (finallyCallback) finallyCallback()
    })
}
