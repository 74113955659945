import { datadogLogs } from '@datadog/browser-logs'

import { UpdateApplicationName } from '../infra'

export function UpdateApplicationNameUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  applicationId,
  name
}: {
  successCallback: () => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorCallback?: (error: any) => void
  finallyCallback?: () => void
  applicationId: string
  name: string
}): void {
  UpdateApplicationName({ applicationId, name })
    .then(() => {
      datadogLogs.logger.info(`UpdateApplicationNameUseCase applicationId: ${applicationId}`, { name })
      successCallback()
    })

    .catch((error: Error | undefined) => {
      datadogLogs.logger.error(`UpdateApplicationNameUseCase applicationId: ${applicationId}`, { name }, error)
      if (errorCallback) errorCallback(error)
    })
    .finally(() => {
      if (finallyCallback) finallyCallback()
    })
}
