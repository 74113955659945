import React from 'react'

import { AMPLITUDE_TEST_AB_VERSION, sendAmplitudeData } from '@legal/shared/amplitude'
import { Layout } from '../../layout'
import { PricingComponentOldFunnel } from '../PricingComponentOldFunnel'
import { type PricingPageOldFunnelTemplateProps } from './PricingPageOldFunnelTemplateProps'
import { ReferrerCookie } from '../../../services/storage/cookies/ReferrerCookie'
import { SubscriptionCookie } from '../../../services/storage/cookies/SubscriptionCookie'
import { useApplicationCookie } from '@legal/shared/hooks'
import { useConfigs } from '@legal/core/configs'

export const PricingPageOldFunnelTemplate: React.FC<PricingPageOldFunnelTemplateProps> = ({
  location,
  isSevenDayAccess,
  subscription,
  loadingSubscription,
  subscriptionsType
}) => {
  const { TARGET_ADDRESS } = useConfigs()
  const { applicationCookie, setApplicationCookieId, setApplicationCookieFormName } = useApplicationCookie()
  const subscriptionCookie = new SubscriptionCookie()
  const referrerCookie = new ReferrerCookie()

  if (location.state) {
    if (location.state.applicationId) setApplicationCookieId(location.state.applicationId)
    if (location.state.subscriptionId) subscriptionCookie.subscriptionId = location.state.subscriptionId

    if (location.state.formName) setApplicationCookieFormName(location.state.formName)
  }

  React.useEffect(() => {
    const eventProps = {
      initial_document_type: applicationCookie?.form?.name ?? undefined,
      source: referrerCookie.getReferrer(),
      version: AMPLITUDE_TEST_AB_VERSION.PRODUCTION
    }
    sendAmplitudeData('show_pricing_page', eventProps)
  }, [])

  return (
    <div>
      <Layout
        noFooter
        disclaimerLinks
        environment={TARGET_ADDRESS}
        footerContactBar
        isTestAB8814
        headerHasNoProducts
        hideDesktopNavbar
      >
        <PricingComponentOldFunnel
          subscriptionsType={subscriptionsType}
          subscription={subscription}
          location={location}
          version={'default'}
          isSevenDayAccess={isSevenDayAccess}
          loadingSubscription={loadingSubscription}
        />
      </Layout>
    </div>
  )
}
