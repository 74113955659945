import { ENDPOINTS } from './Endpoints'
import { type SubscriptionTypeOffer } from '../../domain'
import { type SubscriptionTypeOffersData } from '../dataObjects'
import { get } from '@legal/shared/HttpClient'

export async function GetSubscriptionTypeOffers(): Promise<SubscriptionTypeOffer[]> {
  const result = await get<SubscriptionTypeOffersData[]>(ENDPOINTS.SUBSCRIPTION_TYPE_OFFERS, true)
  return result.map((item) => {
    return {
      id: item.id,
      currency: item.currency,
      initialAmount: item.initialAmount,
      displayPrice: item.displayPrice,
      discount: item.discount,
      type: {
        name: item.type.name
      }
    }
  })
}
