/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { BottomOverlay } from '@npm_leadtech/legal-lib-components/BottomOverlay'
import { ButtonIcon } from '@npm_leadtech/legal-lib-components/ButtonIcon'
import { MenuItems } from '@npm_leadtech/legal-lib-components/MenuItems'
import React from 'react'
import { TooltipMenu } from '@npm_leadtech/legal-lib-components/TooltipMenu'

import Delete from '../../../assets/images/componentsSvg/delete-24-p.svg'
import { type DocumentActionsMenuProps } from './DocumentActionsMenuProps'
import Duplicate from '../../../assets/images/componentsSvg/icon-duplicate-24-px.svg'
import Edit from '../../../assets/images/componentsSvg/edit-24-px.svg'
import IA from '../../../assets/images/componentsSvg/edit-text-24-px.svg'
import MenuCollapse from '../../../assets/images/componentsSvg/menu-colapse-24-p.svg'
import Preview from '../../../assets/images/componentsSvg/eye-24-px-optimized.svg'
import { useIsSmallDevice } from '@legal/shared/hooks'
import './DocumentActionsMenu.scss'

export const DocumentActionsMenu: React.FC<DocumentActionsMenuProps> = ({
  editActive,
  editClicked,
  editNameClicked,
  previewAction,
  previewClicked,
  deleteAction,
  deleteClicked,
  duplicateAction,
  duplicateClicked,
  data
}) => {
  const [openMenu, setOpenMenu] = React.useState(false)
  const isSmallDevice = useIsSmallDevice()

  let editAction = null
  if (editActive) {
    editAction = (
      <div className='document-actions__list__button'>
        <ButtonIcon
          onClick={editClicked}
          textTooltip={data.strapiMyDocumentsPage?.documentsSection?.myDocumentCard?.editActionText}
          dataQa='edit_document_button'
        >
          <Edit />
        </ButtonIcon>
      </div>
    )
  }

  let buttonPreviewAction = null
  if (previewAction) {
    buttonPreviewAction = (
      <div className='document-actions__list__button'>
        <ButtonIcon
          onClick={previewClicked}
          textTooltip={data.strapiMyDocumentsPage?.documentsSection?.myDocumentCard?.previewActionText}
          dataQa='preview_document_button'
        >
          <Preview />
        </ButtonIcon>
      </div>
    )
  }

  const options = [
    {
      content: data.strapiMyDocumentsPage?.documentsSection?.myDocumentCard?.editNameText,
      image: <IA />,
      onClick: editNameClicked,
      dataQa: 'edit_document_name_action'
    }
  ]

  if (deleteAction) {
    options.push({
      content: data.strapiMyDocumentsPage?.documentsSection?.myDocumentCard?.deleteText,
      image: <Delete />,
      onClick: deleteClicked,
      dataQa: 'delete_document_action'
    })
  }
  if (duplicateAction) {
    options.push({
      content: data.strapiMyDocumentsPage?.documentsSection?.myDocumentCard?.duplicateText,
      image: <Duplicate />,
      onClick: duplicateClicked,
      dataQa: 'duplicate_document_action'
    })
  }

  let menu = null
  if (typeof window !== 'undefined') {
    if (!isSmallDevice) {
      menu = (
        <div className='document-actions__list'>
          {editAction}
          {buttonPreviewAction}
          <TooltipMenu
            options={options}
            label={data.strapiMyDocumentsPage?.documentsSection?.myDocumentCard?.actionsText}
          />
        </div>
      )
    } else {
      const bottomOverlayBody = <MenuItems className={'overlay-list-my-docs'} list={options} />

      menu = (
        <ul className={'document-actions__list'}>
          {editAction && <li className='document-actions__item'>{editAction}</li>}
          {buttonPreviewAction && <li className='document-actions__item'>{buttonPreviewAction}</li>}
          <li
            className={`document-actions__item menu ${openMenu ? '--is-open' : ''}`}
            onClick={() => {
              setOpenMenu(!openMenu)
            }}
          >
            <MenuCollapse />
            <span className={'sans-serif --extra-small'}>
              {data.strapiMyDocumentsPage?.documentsSection?.myDocumentCard?.actionsText}
            </span>
            {openMenu && (
              <BottomOverlay
                open={openMenu}
                body={bottomOverlayBody}
                onClose={() => {
                  setOpenMenu(false)
                }}
              ></BottomOverlay>
            )}
          </li>
        </ul>
      )
    }
  }

  return menu
}
