/* eslint-disable @typescript-eslint/no-explicit-any */

import { datadogLogs } from '@datadog/browser-logs'

import { type Application } from '../domain'
import { CreateApplication } from '../infra'
import { type CreateApplicationRequest } from './interfaces'
import { CreateApplicationRequestToApplication } from './adapters/CreateApplicationRequestToApplication'

export function CreateApplicationUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  request
}: {
  successCallback: (application: Partial<Application>) => void
  errorCallback?: (error: any) => void
  finallyCallback?: () => void
  request: CreateApplicationRequest
}): void {
  CreateApplication({ request })
    .then((applicationId: string) => {
      const data = { ...request }
      if (data.htmlPreview) delete data.htmlPreview
      datadogLogs.logger.info('CreateApplicationUseCase', data)
      successCallback(CreateApplicationRequestToApplication(request, applicationId))
    })
    .catch((error: Error | undefined) => {
      if (request.htmlPreview) delete request.htmlPreview
      datadogLogs.logger.error('CreateApplicationUseCase', request, error)
      if (errorCallback) errorCallback(error)
    })
    .finally(() => {
      if (finallyCallback) finallyCallback()
    })
}
