import { datadogLogs } from '@datadog/browser-logs'

import { type ApiError } from '@legal/shared/HttpClient'
import { CreateCustomerSubscription } from '../infra'
import { type CreateCustomerSubscriptionRequest } from './interfaces'

export function CreateCustomerSubscriptionUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  request
}: {
  successCallback: (subscriptionId: string) => void
  errorCallback?: (error?: ApiError) => void
  finallyCallback?: () => void
  request: CreateCustomerSubscriptionRequest
}): void {
  CreateCustomerSubscription(request)
    .then(({ subscriptionId }: { subscriptionId: string }) => {
      datadogLogs.logger.info('CreateCustomerSubscriptionUseCase', request)
      successCallback(subscriptionId)
    })
    .catch((error?: ApiError) => {
      datadogLogs.logger.error(`CreateCustomerSubscriptionUseCase: ${error?.message}`, request)
      if (errorCallback) errorCallback(error)
    })
    .finally(() => {
      if (finallyCallback) finallyCallback()
    })
}
