import { datadogLogs } from '@datadog/browser-logs'

import { type CreateCustomerRequest, type CreateCustomerResponse } from './interfaces'
import { type ApiError } from '@legal/shared/HttpClient'
import { CreateCustomer } from '../infra'
import { UserCookie } from 'src/services/storage/cookies/UserCookie'
import { setAmplitudeUserId } from '@legal/shared/amplitude'

export function CreateCustomerUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  request
}: {
  successCallback: (response: CreateCustomerResponse) => void
  errorCallback?: (error?: ApiError) => void
  finallyCallback?: () => void
  request: CreateCustomerRequest
}): void {
  const user = new UserCookie()
  if (user.token) {
    datadogLogs.logger.error('CreateCustomerUseCase', { error: 'User already authenticated' })
    return
  }
  CreateCustomer(request)
    .then((response) => {
      response.isInProgress = response.status === 'in_progress'
      response.isNew = response.status === 'new'
      if (response.customerId) setAmplitudeUserId(response.customerId)
      datadogLogs.logger.info('CreateCustomerUseCase')
      successCallback(response)
    })

    .catch((error: ApiError | undefined) => {
      datadogLogs.logger.error(`CreateCustomerUseCase: ${error?.message}`)
      if (errorCallback) errorCallback(error)
    })
    .finally(() => {
      if (finallyCallback) finallyCallback()
    })
}
