/* eslint-disable @typescript-eslint/no-explicit-any */

import { datadogLogs } from '@datadog/browser-logs'

import { SubscriptionUpdatePay } from '../infra'

export function SubscriptionUpdatePayUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  request
}: {
  successCallback: () => void
  errorCallback?: (error: any) => void
  finallyCallback?: () => void
  request: { subscriptionPriceId: string }
}): void {
  SubscriptionUpdatePay(request.subscriptionPriceId)
    .then(() => {
      datadogLogs.logger.info('SubscriptionUpdatePayUseCase', request)
      successCallback()
    })
    .catch((error) => {
      datadogLogs.logger.error('SubscriptionUpdatePayUseCase', request, error)
      if (errorCallback) errorCallback(error)
    })
    .finally(() => {
      if (finallyCallback) finallyCallback()
    })
}
