/* eslint-disable @typescript-eslint/no-explicit-any */

import { datadogLogs } from '@datadog/browser-logs'

import { GetTransactionStatus } from '../infra'

export function GetTransactionStatusUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  transactionId
}: {
  successCallback: (status: string) => void
  errorCallback?: (error: any) => void
  finallyCallback?: () => void
  transactionId: string
}): void {
  GetTransactionStatus(transactionId)
    .then((status) => {
      datadogLogs.logger.info('GetTransactionStatusUseCase')
      successCallback(status)
    })
    .catch((error) => {
      datadogLogs.logger.error('GetTransactionStatusUseCase', { transactionId }, error)
      if (errorCallback) errorCallback(error)
    })
    .finally(() => {
      if (finallyCallback) finallyCallback()
    })
}
