import { type CreateSubscriptionNoAuthRequest, type CreateSubscriptionNoAuthResponse } from '../../application'
import { ENDPOINTS } from './Endpoints'
import { post } from '@legal/shared/HttpClient'

export async function CreateSubscriptionNoAuth(
  request: CreateSubscriptionNoAuthRequest
): Promise<CreateSubscriptionNoAuthResponse> {
  const body = { ...request }
  if (!body.lastCreatedSubscriptionId) {
    delete body.lastCreatedSubscriptionId
  }
  return await post<CreateSubscriptionNoAuthResponse>(ENDPOINTS.SUBSCRIPTION_NO_AUTH, body)
}
