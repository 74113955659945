import { graphql, useStaticQuery } from 'gatsby'

export interface SideMenuProps {
  strapiMyDocumentsPage: {
    sideMenu: {
      menuText: string
      logoutText: string
      sideMenuItems: Document[]
    }
    documentsSection: {
      newDocumentButtonText: string
    }
  }
}

export const useQuerySideMenu = (): SideMenuProps => {
  const { strapiMyDocumentsPage } = useStaticQuery(graphql`
    query {
      strapiMyDocumentsPage {
        uploadButtonText
        sideMenu {
          menuText
          logoutText
          sideMenuItems {
            mobileText
            desktopText
          }
        }
        documentsSection {
          newDocumentButtonText
        }
      }
    }
  `)

  return strapiMyDocumentsPage
}
