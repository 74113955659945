import { datadogLogs } from '@datadog/browser-logs'

import HttpClient, { type HttpClientResponse } from '@legal/core/http/http-client'
import { getConfigs } from '@legal/core/configs'

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class UpdateCustomerPasswordByToken {
  public static async Execute({
    userToken,
    oldPassword,
    newPassword
  }: {
    userToken: string
    oldPassword: string
    newPassword: string
  }): Promise<HttpClientResponse<unknown>> {
    const { API_CONFIG } = getConfigs()
    const http = HttpClient.create({
      baseUrl: API_CONFIG.API_PREFIX,
      defaults: {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      }
    })

    const body = { oldPassword, newPassword }

    const responsePromise = new Promise<HttpClientResponse<unknown>>((resolve, reject) => {
      http
        .put('customer/update-password', body)
        .then((response) => {
          datadogLogs.logger.info('UpdateCustomerPasswordByToken')
          resolve(response)
        })
        .catch((error: object | undefined) => {
          datadogLogs.logger.error('UpdateCustomerPasswordByToken', error)
          reject(error)
        })
    })

    return await responsePromise
  }
}
