import { ENDPOINTS } from './Endpoints'
import { type Subscription } from '../../domain'
import { type SubscriptionData } from '../dataObjects'
import { SubscriptionDataToSubscription } from '../adapters'
import { get } from '@legal/shared/HttpClient'

export async function GetSubscription(id: string): Promise<Subscription> {
  const result = await get<SubscriptionData>(ENDPOINTS.GET_SUBSCRIPTION(id), true)
  return SubscriptionDataToSubscription(result)
}
