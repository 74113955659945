/* eslint-disable @typescript-eslint/no-explicit-any */

import { datadogLogs } from '@datadog/browser-logs'

import { GetApplicationStatus } from '../infra'

export function GetApplicationStatusUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  applicationId
}: {
  successCallback: (status: string) => void
  errorCallback?: (error: any) => void
  finallyCallback?: () => void
  applicationId: string
}): void {
  GetApplicationStatus(applicationId)
    .then((status: string) => {
      datadogLogs.logger.info(`GetApplicationStatusUseCase applicationId: ${applicationId}`)
      successCallback(status)
    })
    .catch((error: Error | undefined) => {
      datadogLogs.logger.error(`GetApplicationStatusUseCase applicationId: ${applicationId}`, undefined, error)
      if (errorCallback) errorCallback(error)
    })
    .finally(() => {
      if (finallyCallback) finallyCallback()
    })
}
