/* eslint-disable no-console */

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { Children } from 'react'
import { navigate } from 'gatsby'

import { uploadMaxFilesize, uploadMimeTypes } from 'src/services/constants/uploadConstants'
import { Loading } from '../../molecules'
import { NewDocumentModal } from '../../molecules/NewDocumentModal'
import { PrivateAreaContent } from '../PrivateAreaContent'
import { type PrivateAreaLayoutProps } from './PrivateAreaLayoutProps'
import { SideMenu } from '../../organisms'
import { SubscriptionCookie } from '../../../services/storage/cookies/SubscriptionCookie'
import { UploadCustomerDocument } from '@legal/document'
import { UserCookie } from '../../../services/storage/cookies/UserCookie'
import { sendAmplitudeData } from '@legal/shared/amplitude'
import { useApplicationCookie } from '@legal/shared/hooks'
import { useModal } from '../../../services/hooks/useModal'
import { useToastsCustom } from 'src/services/hooks/useToastsCustom'
import './PrivateAreaLayout.scss'

export const PrivateAreaLayout: React.FC<PrivateAreaLayoutProps> = ({
  children,
  title,
  wide = false,
  customClass,
  activeMenuOption,
  sideBarIdentifier,
  applicationsLoaded,
  fetchUploadedDocuments,
  setUploadingFile,
  data
}) => {
  const [loading, updateLoading] = React.useState<boolean>(true)
  const { clearApplicationCookie } = useApplicationCookie()
  const { addToastCustom } = useToastsCustom()
  const newDocumentModal = useModal()

  const userCookie = new UserCookie()
  const userToken = userCookie ? userCookie.token : ''
  const subscriptionCookie = new SubscriptionCookie()

  React.useEffect(() => {
    window.localStorage.setItem('isEditing', '')
    handleAuthentication()
  }, [])

  const handleAuthentication = (): void => {
    if (!userCookie.token) {
      clearApplicationCookie()
      subscriptionCookie.clear()
      void navigate('/login')
    } else {
      updateLoading(false)
    }
  }

  const showWrongUpload = (): void => {
    addToastCustom({
      autoDismiss: false,
      type: 'error',
      text: data.strapiMyDocumentsPage?.documentsSection?.uploadErrorToast?.text
    })
  }

  const showWrongUploadMimetype = (): void => {
    addToastCustom({
      autoDismiss: false,
      type: 'error',
      text: data.strapiMyDocumentsPage?.documentsSection?.uploadWrongToast?.text
    })
  }

  const showWrongUploadSize = (): void => {
    addToastCustom({
      autoDismiss: false,
      type: 'error',
      text: 'File’s too large. Maximum size is 20MB.'
    })
  }

  const documentUploadFileDialog = (): void => {
    const input = document.createElement('input')
    input.type = 'file'
    input.accept = '.pdf,.doc,.docx'
    input.onchange = (e: Event) => {
      const file = e.target?.files[0]
      uploadFileProcess(file)
    }
    input.click()
  }

  const uploadFileProcess = (file): void => {
    if (file.size > uploadMaxFilesize) {
      const eventProps = {
        format_document: file.type,
        weight: file.size
      }
      sendAmplitudeData('error_max_weight', eventProps)
      showWrongUploadSize()
      return
    }
    if (!uploadMimeTypes.includes(file.type as string)) {
      const eventProps = {
        format_document: file.type
      }
      sendAmplitudeData('error_format', eventProps)
      showWrongUploadMimetype()
      return
    }

    const formData = new FormData()
    formData.append('file', file as Blob, file.name as string)
    formData.append('name', file.name as string)

    if (setUploadingFile) {
      setUploadingFile(file.name as string)
    }

    UploadCustomerDocument.Execute({ userToken, formData })
      .then((response) => {
        const eventProps = {
          format_document: file.type
        }
        sendAmplitudeData('upload_document', eventProps)
        if (fetchUploadedDocuments) {
          fetchUploadedDocuments()
        } else {
          void navigate('/my-account/my-documents/#uploaded')
        }
      })
      .catch((e: unknown) => {
        console.log(e)
        if (setUploadingFile) {
          setUploadingFile('')
        }
        showWrongUpload()
      })
  }

  const ChildrenComponent = (): any[] => {
    const elements = []
    Children.forEach(children, (child, index) => {
      if (child) {
        elements.push(React.cloneElement(child, { modal: newDocumentModal, key: `child-${index}` }))
      }
    })
    return elements
  }

  const getChildren = ChildrenComponent()

  if (loading) return <Loading />

  return (
    <div className='private-area'>
      {newDocumentModal.modalOpen && <NewDocumentModal closeFunction={newDocumentModal.closeModal} />}
      <SideMenu
        activeMenuOption={activeMenuOption}
        newDocumentModal={newDocumentModal}
        sideBarIdentifier={sideBarIdentifier}
        documentUploadFileDialog={documentUploadFileDialog}
        data={data}
      />
      <PrivateAreaContent
        title={title}
        wide={wide}
        customClass={customClass}
        activeMenuOption={activeMenuOption}
        applicationsLoaded={applicationsLoaded}
        documentUploadFileDialog={documentUploadFileDialog}
        uploadFileProcess={uploadFileProcess}
      >
        {getChildren}
      </PrivateAreaContent>
    </div>
  )
}
