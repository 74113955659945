import { datadogLogs } from '@datadog/browser-logs'

import { DeleteCreditCard } from '../infra'

export function DeleteCreditCardUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  request
}: {
  successCallback: () => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorCallback?: (error: any) => void
  finallyCallback?: () => void
  request: { cardId: string }
}): void {
  DeleteCreditCard(request.cardId)
    .then(() => {
      datadogLogs.logger.info('DeleteCreditCardUseCase', request)
      successCallback()
    })

    .catch((error) => {
      datadogLogs.logger.error('DeleteCreditCardUseCase', request, error)
      if (errorCallback) errorCallback(error)
    })
    .finally(() => {
      if (finallyCallback) finallyCallback()
    })
}
