import { datadogLogs } from '@datadog/browser-logs'

import { CreateCustomerWithSubscription } from '../infra'
import { type CreateCustomerWithSubscriptionRequest } from './interfaces'
import { UserCookie } from 'src/services/storage/cookies/UserCookie'
import { setAmplitudeUserId } from '@legal/shared/amplitude'

export function CreateCustomerWithSubscriptionUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  request
}: {
  successCallback: (token: string) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorCallback?: (error: any) => void
  finallyCallback?: () => void
  request: CreateCustomerWithSubscriptionRequest
}): void {
  const user = new UserCookie()
  if (user.token) {
    datadogLogs.logger.error('CreateCustomerWithSubscription', { error: 'User already authenticated' })
    return
  }
  CreateCustomerWithSubscription(request)
    .then((response) => {
      if (response.customerId) setAmplitudeUserId(response.customerId)
      datadogLogs.logger.info('CreateCustomerWithSubscription')
      successCallback(response.token)
    })

    .catch((error: Error | undefined) => {
      datadogLogs.logger.error('CreateCustomerWithSubscription', undefined, error)
      if (errorCallback) errorCallback(error)
    })
    .finally(() => {
      if (finallyCallback) finallyCallback()
    })
}
