import { GetInfoDatalayerUseCase, type InfoDatalayer } from '@legal/application'
import { type Subscription } from '@legal/subscription'

const pushPaymentDataToDataLayer = ({ sku, subscription }: { sku: string; subscription: Subscription }): void => {
  if (sku) {
    window.dataLayer = window.dataLayer || []

    dataLayer.push({
      event: 'add_to_cart',
      value: subscription.priceValue,
      items: [
        {
          id: sku,
          google_business_vertical: 'custom'
        }
      ]
    })
  }
}

const pushRegistrationToDataLayer = (): void => {
  window.dataLayer = window.dataLayer || []
  dataLayer.push({ event: 'registration' })
}

const pushSubscriptionNameToDataLayer = (name): void => {
  window.dataLayer = window.dataLayer || []
  dataLayer.push({ event: name })
}

const pushPaymentFailedFreeTrialToDataLayer = (): void => {
  window.dataLayer = window.dataLayer || []
  dataLayer.push({ event: 'payment_failed' })
}

const pushTryAgainPaymentToDataLayer = (): void => {
  window.dataLayer = window.dataLayer || []
  dataLayer.push({ event: 'try_again_payment' })
}

const getInfoDatalayerSuccess = (applicationId: string, infoDatalayer: InfoDatalayer): void => {
  window.dataLayer = window.dataLayer || []

  dataLayer.push({
    event: 'transaction',
    ecommerce: {
      purchase: {
        actionField: {
          id: applicationId,
          affiliation: '',
          revenue: infoDatalayer.productPrice,
          tax: '',
          shipping: '',
          coupon: ''
        },
        products: [
          {
            name: infoDatalayer.productName,
            id: infoDatalayer.productSKU,
            price: infoDatalayer.productPrice,
            brand: infoDatalayer.transactionId,
            category: infoDatalayer.productCategory,
            variant: '',
            quantity: infoDatalayer.productQuantity,
            coupon: ''
          }
        ]
      }
    }
  })

  dataLayer.push({
    event: 'purchase',
    value: infoDatalayer.productPrice,
    items: [
      {
        id: infoDatalayer.productSKU,
        google_business_vertical: 'custom'
      }
    ]
  })
}

const getAndSetDataLayer = ({ applicationId }: { applicationId: string }): void => {
  GetInfoDatalayerUseCase({
    applicationId,
    successCallback: (infoDatalayer) => {
      getInfoDatalayerSuccess(applicationId, infoDatalayer)
    }
  })
}

export {
  getAndSetDataLayer,
  pushPaymentDataToDataLayer,
  pushRegistrationToDataLayer,
  pushSubscriptionNameToDataLayer,
  pushPaymentFailedFreeTrialToDataLayer,
  pushTryAgainPaymentToDataLayer
}
