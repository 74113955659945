/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-console */

import React from 'react'
import classNames from 'classnames'

import { AccordionItem } from '../AccordionItem'
import { type AccordionProps } from './AccordionProps'

export const Accordion: React.FC<AccordionProps> = ({ children, className, changeActiveItem }) => {
  const [itemActive, setItemActive] = React.useState<number | null>(null)

  const changeActiveItems = (index: number): void => {
    changeActiveItem && changeActiveItem(index)
    setItemActive(index)
  }

  const accordionItems = (): (React.JSX.Element | undefined)[] | null | undefined => {
    if (!children) return null

    let auxChildren = children

    if (!Array.isArray(auxChildren) && typeof auxChildren === 'object') {
      auxChildren = [auxChildren]
    }

    if (Array.isArray(auxChildren)) {
      return auxChildren.map((item, index) => {
        if (item.type !== AccordionItem) {
          console.error("Error Accordion has children that aren't AccordionItem components")
        } else {
          const active = itemActive === index
          return (
            <AccordionItem
              {...item.props}
              key={`${item.title}_${index}`}
              index={index}
              active={active}
              onChangeItem={changeActiveItems}
            />
          )
        }
      })
    }
  }

  const classAccordion = classNames({
    accordion: true,
    [className]: className
  })

  return <ul className={classAccordion}>{accordionItems()}</ul>
}
