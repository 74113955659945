import { InfoBox } from '@npm_leadtech/legal-lib-components/InfoBox'
import React from 'react'

import { isDateBefore, toDate } from '@legal/shared/utils/formatDate'

export interface Card {
  createdAt: string
  expirationYear: string
  expirationMonth: string
}

interface Payment {
  paidAt: string
}

export const isCardExpiringThisMonth = (card: Card): boolean => {
  if (card.expirationMonth && card.expirationYear) {
    const expirationMonth = parseInt(card.expirationMonth)

    const expirationYear = parseInt(card.expirationYear)
    const currentDate = toDate()
    const currentMonth = currentDate.getMonth() + 1
    const currentYear = currentDate.getFullYear()
    return currentYear === expirationYear && currentMonth === expirationMonth
  } else {
    return false
  }
}

export const isCardExpired = (card: Card): boolean => {
  if (card.expirationMonth && card.expirationYear) {
    const expirationMonth = parseInt(card.expirationMonth)
    const expirationYear = parseInt(card.expirationYear)
    const currentDate = toDate()
    const currentMonth = currentDate.getMonth() + 1
    const currentYear = currentDate.getFullYear()
    return currentYear > expirationYear || (currentYear === expirationYear && currentMonth > expirationMonth)
  } else {
    return false
  }
}

export const getLastPayment = (payments?: Payment[]): unknown => {
  if (payments && payments.length > 0) {
    payments.sort((payment1: Payment, payment2: Payment) => {
      if (isDateBefore(payment1.paidAt, payment2.paidAt)) {
        return 1
      }
      return 0
    })
    return payments[0]
  }
  return null
}

export const hasPaymentFailed = (lastPaymentMessage: string): boolean => {
  return lastPaymentMessage === 'change_payment_card'
}

// If the last payment was made with an older card because our current one didn't work, return true.
export const oldCardWasUsedForPayment = (card: Card, lastPaymentMessage: string, lastPayment?: Payment): boolean => {
  if (!lastPayment) return false
  return lastPaymentMessage === 'payment_with_other_card' && isDateBefore(card.createdAt, lastPayment.paidAt)
}

export const isCardExpiredAndPaymentFailed = (card: Card, lastPaymentMessage: string): boolean => {
  return lastPaymentMessage === 'change_payment_card' && isCardExpired(card)
}

export const renderInfoBoxUnderCard = (
  card?: Card,
  subscriptions,
  lastPaymentMessage: string,
  lastPayment: Payment
): unknown => {
  if (!card || !subscriptions) return null
  let infoBox
  if (isCardExpiringThisMonth(card)) {
    infoBox = (
      <InfoBox type='warning'>
        <p>
          <strong>Your card expires soon.</strong> Your old credit card will be used for payment unless the details are
          updated.
        </p>
      </InfoBox>
    )
  } else if (isCardExpiredAndPaymentFailed(card, lastPaymentMessage)) {
    infoBox = (
      <InfoBox type='error'>
        <p>
          <strong>Your credit card has expired.</strong> Please, update your payment method within 25 days or your
          subscription will be canceled.
        </p>
      </InfoBox>
    )
  } else if (isCardExpired(card)) {
    infoBox = (
      <InfoBox type='error'>
        <p>
          <strong>Your credit card has expired.</strong> Please, update a new one.
        </p>
      </InfoBox>
    )
  } else if (oldCardWasUsedForPayment(card, lastPaymentMessage, lastPayment)) {
    infoBox = (
      <InfoBox type='warning'>
        <p>Your payment information is not valid. Your old credit card was used for payment.</p>
      </InfoBox>
    )
  }
  return infoBox
}
