import HttpClient, { type HttpClientResponse } from '@legal/core/http/http-client'

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class DeleteCustomerDocument {
  public static async Execute({
    userToken,
    uid
  }: {
    userToken: string
    uid: string
  }): Promise<HttpClientResponse<unknown>> {
    const http = HttpClient.create({
      baseUrl: `${process.env.API_PREFIX}`,
      defaults: {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      }
    })

    return await http.delete(`documents/${uid}`)
  }
}
