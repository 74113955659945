import { datadogLogs } from '@datadog/browser-logs'

import { type ApiError } from '@legal/shared/HttpClient'
import { FilterNotSingleDocument } from '../domain/services'
import { FindSubscriptionTypeByCode } from '../infra'
import { type Subscription } from '../domain'

export function GetSubscriptionTypeByCodeUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  filterNotSingleDocument = false,
  tokenRequired = false
}: {
  successCallback: (subscriptions: Subscription[]) => void
  errorCallback?: (error: ApiError) => void
  finallyCallback?: () => void
  filterNotSingleDocument?: boolean
  tokenRequired?: boolean
}): void {
  FindSubscriptionTypeByCode(tokenRequired)
    .then((subscriptions) => {
      datadogLogs.logger.info('GetSubscriptionTypeByCodeUseCase')
      if (filterNotSingleDocument) subscriptions = FilterNotSingleDocument(subscriptions)
      successCallback(subscriptions)
    })
    .catch((error: ApiError) => {
      datadogLogs.logger.error(`GetSubscriptionTypeByCodeUseCase ${error.message}`)
      if (errorCallback) errorCallback(error)
    })
    .finally(() => {
      if (finallyCallback) finallyCallback()
    })
}
