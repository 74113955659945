/* eslint-disable @typescript-eslint/strict-boolean-expressions */

import React from 'react'

import { FormatHTMLAstChildren } from '../FormatHTMLAstChildren'
import { formatPrice } from '@legal/shared/utils'
import { replaceValues } from '../../../services/utils/replaceAll'
import { useConfigs } from '@legal/core/configs'

import { type TextUnderPaymentProps } from './TextUnderPaymentProps'

import './TextUnderPayment.scss'

export const TextUnderPaymentForm = ({
  htmlAstChildren,
  subscriptionTypeName,
  subscriptionDisplayPrice,
  transactionInYourAccountAppearAs,
  trialSevenDays
}: TextUnderPaymentProps): React.JSX.Element => {
  const {
    SITE_METADATA: { SITE_NAME }
  } = useConfigs()
  const subscriptionDisplayPriceFormat = formatPrice(subscriptionDisplayPrice)

  const trial7Days =
    subscriptionTypeName &&
    (subscriptionTypeName.includes('7-Day Free Trial') || subscriptionTypeName.includes('7-Day Access'))

  return (
    <div className='subscribe-now-text'>
      <p className='subscribe-now-text__p'>
        <FormatHTMLAstChildren htmlAstChildren={htmlAstChildren} />
        {trial7Days && (
          <span className='one-line-paragraph'>
            {replaceValues(trialSevenDays, { DISPLAY_PRICE: subscriptionDisplayPriceFormat })}
          </span>
        )}
        {` ${replaceValues(transactionInYourAccountAppearAs, { SITE_NAME })}.`}
      </p>
    </div>
  )
}
