import { datadogLogs } from '@datadog/browser-logs'

import HttpClient, { type HttpClientResponse } from '@legal/core/http/http-client'
import { HttpParams } from '@legal/core/http/http-params'
import { getConfigs } from '@legal/core/configs'

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class GetCustomerApplicationByToken {
  public static async Execute({
    userToken,
    limit,
    offset,
    orderBy,
    orderDir
  }: {
    userToken: string
    limit: number
    offset: number
    orderBy: string
    orderDir: string
  }): Promise<HttpClientResponse<unknown>> {
    const { API_CONFIG } = getConfigs()
    const http = HttpClient.create({
      baseUrl: API_CONFIG.API_PREFIX,
      defaults: {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      }
    })

    const params = HttpParams.create()
      .set('limit', limit)
      .set('offset', offset)
      .set('orderBy', orderBy)
      .set('orderDir', orderDir)

    const responsePromise = new Promise<HttpClientResponse<unknown>>((resolve, reject) => {
      http
        .get('customer/applications', params)
        .then((response) => {
          datadogLogs.logger.info('GetCustomerApplicationByToken', response)
          resolve(response)
        })

        .catch((error: object | undefined) => {
          datadogLogs.logger.error('GetCustomerApplicationByToken', error)

          reject(error)
        })
    })

    return await responsePromise
  }
}
