/* eslint-disable @typescript-eslint/no-explicit-any */

import { datadogLogs } from '@datadog/browser-logs'

import { type Application } from '../domain'
import { FindApplicationByUuid } from '../infra'

export function FindApplicationByUuidUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  applicationId
}: {
  successCallback: (application: Application) => void
  errorCallback?: (error: any) => void
  finallyCallback?: () => void
  applicationId: string
}): void {
  FindApplicationByUuid(applicationId)
    .then((application: Application) => {
      datadogLogs.logger.info(`FindApplicationByUuidUseCase applicationId: ${applicationId}`)
      successCallback(application)
    })
    .catch((error: Error | undefined) => {
      datadogLogs.logger.error(`FindApplicationByUuidUseCase applicationId: ${applicationId}`, undefined, error)
      if (errorCallback) errorCallback(error)
    })
    .finally(() => {
      if (finallyCallback) finallyCallback()
    })
}
