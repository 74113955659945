/* eslint-disable @typescript-eslint/no-explicit-any */

import { datadogLogs } from '@datadog/browser-logs'

import { type CancelSubscriptionRequest, type CancelSubscriptionResponse } from './interfaces'
import { CancelSubscription } from '../infra'

export function CancelSubscriptionUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  cancelSubscriptionRequest
}: {
  successCallback: (response: CancelSubscriptionResponse) => void
  errorCallback?: (error: any) => void
  finallyCallback?: () => void
  cancelSubscriptionRequest: CancelSubscriptionRequest
}): void {
  CancelSubscription(cancelSubscriptionRequest)
    .then((response) => {
      datadogLogs.logger.info('CancelSubscriptionUseCase')
      successCallback(response)
    })
    .catch((error) => {
      datadogLogs.logger.error('CancelSubscriptionUseCase', undefined, error)
      if (errorCallback) errorCallback(error)
    })
    .finally(() => {
      if (finallyCallback) finallyCallback()
    })
}
