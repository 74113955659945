import { ENDPOINTS } from './Endpoints'
import { type Transaction } from '../../domain'
import { type TransactionExecuteResponse } from '../dataObjects'
import { TransactionToTransactionData } from '../adapters/TransactionToTransactionData'
import { post } from '@legal/shared/HttpClient'

export async function CreateTransactionExecute(transaction: Transaction): Promise<void> {
  const body = TransactionToTransactionData(transaction)
  const result = await post<TransactionExecuteResponse>(ENDPOINTS.EXECUTE_TRANSACTION(transaction.id), body, true)
  if (result.transaction_id !== transaction.id) throw new Error('Transaction ID does not match')
}
