import React from 'react'

import {
  CreateSubscriptionPaymentUseCase,
  type SubscriptionPaymentInformation,
  SubscriptionPaymentMethod
} from '@legal/subscription'
import { HeaderWithIcon, PaymentErrorModal, PaypalErrorModal, TimeoutModalContent } from '../../molecules'
import { type PSPGatewayData, PaymentForm } from '../../organisms'
import { type FeedBack } from '../../molecules/SignIn/SignInProps'
import { Layout } from '../../layout'
import { LoginModal } from '../LoginModal'
import { PaymentCookie } from '../../../services/storage/cookies/PaymentCookie'
import { type PaymentGatewayData } from '../../organisms/PaymentGateway'
import { type PaymentPageTemplateProps } from './PaymentPageTemplateProps'
import { type PaymentSummaryData } from '../../organisms/PaymentSummary'
import { ProductCookie } from '../../../services/storage/cookies/ProductCookie'
import { type ProvidersCustomMessages } from '../../../components/template'
import { RegisterModal } from '../RegisterModal'
import { SubscriptionCookie } from '../../../services/storage/cookies/SubscriptionCookie'
import { UserCookie } from '../../../services/storage/cookies/UserCookie'
import { type ValidatorsI18n } from '@legal/shared/utils/validator.types'
import { getParamUrlValue } from '../../../services/utils/paramsUrl'
import securityGrey from '../../../assets/images/svg/icon-24-px-security-grey.svg'
import { setCookiePolicyFunc } from '../../../services/utils/setCookiePolicyFunc'
import { useApplicationCookie } from '@legal/shared/hooks'
import { useConfigs } from '@legal/core/configs'
import { useCountDown } from '../../../services/hooks/useCountDown'
import { useModal } from '../../../services/hooks/useModal'
import './PaymentPageTemplate.scss'

export const PaymentPageTemplate: React.FC<PaymentPageTemplateProps> = ({ data, location }) => {
  const { TARGET_ADDRESS } = useConfigs()
  const {
    applicationCookie,
    clearApplicationCookie,
    setApplicationCookieId,
    setApplicationCookieFormName,
    setApplicationCookieUrlParams
  } = useApplicationCookie()
  const paymentCookie = new PaymentCookie()
  const subscriptionCookie = new SubscriptionCookie()
  const [applicationId, setApplicationId] = React.useState(applicationCookie?.id)
  const [formName, setFormName] = React.useState(applicationCookie?.form?.name)
  const [subscriptionId, setSubscriptionId] = React.useState<string | undefined>(subscriptionCookie.subscriptionId)
  const [subscriptionTypeId, setSubscriptionTypeId] = React.useState(subscriptionCookie.subscriptionTypeId)
  const [islocationStateChecked, setIslocationStateChecked] = React.useState(false)
  const [paymentProvider, setPaymentProviderForm] = React.useState<SubscriptionPaymentInformation>()
  const [hasPaymentError, setHasPaymentError] = React.useState<boolean>(false)
  const time = useCountDown()

  const loginModal = useModal(false)
  const paypalErrorModal = useModal(false)
  const paymentErrorModal = useModal(false)
  const paypalTimeoutModal = useModal(false)
  const userCookie = new UserCookie()
  const paymentGateway = getParamUrlValue('payment') ?? undefined

  const registerModal = useModal(true)

  React.useEffect(() => {
    const subscriptionIdFromState = location.state ? location.state.subscriptionId : null
    const subscriptionTypeIdFromState = location.state ? location.state.subscriptionTypeId : null
    const applicationIdFromState = location.state ? location.state.applicationId : null
    const formNameFromState = location.state ? location.state.formName : null

    subscriptionCookie.subscriptionId = subscriptionId ?? subscriptionIdFromState
    subscriptionCookie.subscriptionTypeId = subscriptionTypeIdFromState || subscriptionTypeId

    setApplicationCookieId(applicationIdFromState || applicationId)

    setApplicationCookieFormName(formNameFromState || formName)
    setSubscriptionId(subscriptionId ?? subscriptionIdFromState)
    setSubscriptionTypeId(subscriptionTypeIdFromState || subscriptionTypeId)

    setApplicationId(applicationIdFromState || applicationId)

    setFormName(formNameFromState || formName)
    setIslocationStateChecked(true)

    // testAB8814
    const productInUrl = getParamUrlValue('product')
    if (productInUrl) {
      const productCookie = new ProductCookie()
      productCookie.product = productInUrl
    }
    // fin testAB8814
  }, [])

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      setApplicationCookieUrlParams(window.location.search)
    }
  }, [])

  React.useEffect(() => {
    switch (paymentCookie.paymentStatusPaypal) {
      case 'error':
      case 'timeout':
        paypalErrorModal.changeModalState()
        break
    }
    switch (paymentCookie.paymentStatus) {
      case 'error':
      case 'timeout':
        paymentErrorModal.changeModalState()
        break
    }
    paymentCookie.paymentStatusPaypal = ''
    paymentCookie.paymentStatus = ''
  }, [])

  React.useEffect(() => {
    if (islocationStateChecked && (!subscriptionId || !subscriptionTypeId)) {
      clearApplicationCookie()
      userCookie.clear()
      subscriptionCookie.clear()
      setCookiePolicyFunc()
    }
  }, [islocationStateChecked])

  const referrer = location.state ? location.state.referrer : null

  const paymentInformationSuccessPaypal = (res: SubscriptionPaymentInformation): void => {
    if (!res.forwardUrl || typeof window === 'undefined') return
    window.location.href = res.forwardUrl
  }

  const onPayWithPaypalClicked = (): void => {
    paypalErrorModal.closeModal()
    if (!subscriptionId) return
    CreateSubscriptionPaymentUseCase({
      request: {
        subscriptionId,
        referrer,
        method: SubscriptionPaymentMethod.PAYPAL
      },
      successCallback: paymentInformationSuccessPaypal,
      errorCallback: () => {
        setHasPaymentError(true)
      }
    })
  }

  const getPaymentInformationForSubscriptionId = React.useCallback((): void => {
    if (!subscriptionId) return
    CreateSubscriptionPaymentUseCase({
      request: {
        subscriptionId,
        referrer,
        gateway: paymentGateway
      },
      successCallback: setPaymentProviderForm,
      errorCallback: () => {
        setHasPaymentError(true)
      }
    })
  }, [subscriptionId, referrer, paymentGateway])

  const toggleOpenRegisterCloseLogin = (): void => {
    loginModal.changeModalState()
    registerModal.changeModalState()
  }

  const toggleOpenLoginCloseRegister = (): void => {
    loginModal.changeModalState()
    registerModal.changeModalState()
  }

  React.useEffect(() => {
    getPaymentInformationForSubscriptionId()
  }, [subscriptionId])

  const logoClick = (event): void => {
    event.preventDefault()
  }

  return (
    <div className='payment'>
      <Layout
        hideMenuButton={true}
        menuPayment={true}
        hideHeader={true}
        isAnonymousPaymentPage={true}
        noFooter={true}
        environment={TARGET_ADDRESS}
        toggleRegister={registerModal.changeModalState}
        changeUrlRedirect={registerModal.changeUrlRedirect}
        headerBackground={'payment8814'}
        footerContactBar={true}
        isTestAB8814={true}
        hideDesktopNavbar={true}
        logoShowModal={true}
        logoClick={logoClick}
      >
        {loginModal.modalOpen && (
          <LoginModal
            toggleOpenRegisterCloseLogin={toggleOpenRegisterCloseLogin}
            paymentPage
            isLoginModal
            closeFunction={loginModal.closeModal}
            noBottomSection
            isTestAB8814={true}
          />
        )}

        {registerModal.modalOpen && (
          <RegisterModal
            toggleOpenLoginCloseRegister={toggleOpenLoginCloseRegister}
            isRegisterModal
            closeFunction={registerModal.closeModal}
            onToggleModal={registerModal.changeModalState}
            loginModalOpen={loginModal.modalOpen}
            urlRedirect={registerModal.urlRedirect}
            changeUrlRedirect={registerModal.changeUrlRedirect}
          />
        )}

        {paymentErrorModal.modalOpen && (
          <PaymentErrorModal
            closeFunction={paymentErrorModal.closeModal}
            title={data.strapiPaymentPage?.paymentErrorModal?.title ?? ''}
            feedBackError={data.strapiPaymentPage?.paymentErrorModal?.errorPaymentFeedback as FeedBack}
          />
        )}
        {paypalErrorModal.modalOpen && (
          <PaypalErrorModal
            closeFunction={paypalErrorModal.closeModal}
            onPayWithPaypalClicked={onPayWithPaypalClicked}
            title={data.strapiPaymentPage?.paypalErrorModal?.title ?? ''}
            feedBackError={data.strapiPaymentPage?.paypalErrorModal?.feedBackError as FeedBack}
          />
        )}
        {paypalTimeoutModal.modalOpen && (
          <TimeoutModalContent
            title={data.strapiPaymentPage?.timeOutModal?.title ?? ''}
            subtitle={data.strapiPaymentPage?.timeOutModal?.subtitle ?? ''}
            btnLabel={data.strapiPaymentPage?.timeOutModal?.btnLabel ?? ''}
            closeFunction={paypalTimeoutModal.changeModalState}
          />
        )}
        <div className='payment-snow__background'></div>
        <div className='wrapper'>
          <HeaderWithIcon icon={securityGrey} title={data.strapiPaymentPage?.title ?? ''} />
          <PaymentForm
            htmlAstChildren={
              (data.strapiPaymentPage?.subscribeNowText?.data?.childMarkdownRemark?.htmlAst?.children as
                | React.ReactNode
                | React.ReactNode[]) ?? []
            }
            subscriptionId={subscriptionId}
            subscriptionTypeId={subscriptionTypeId}
            applicationId={applicationId}
            paymentProvider={paymentProvider}
            hasPaymentError={hasPaymentError}
            formNameForAmplitude={formName}
            timeCountDown={time}
            timeoutModal={data.strapiPaymentPage?.timeOutModal}
            paymentGatewayData={data.strapiPaymentPage?.paymentGateway as PaymentGatewayData}
            paymentSummaryData={data.strapiPaymentPage?.paymentSummary as PaymentSummaryData}
            pspGatewayData={data.strapiPaymentPage?.pspGateway as PSPGatewayData}
            currencyData={data.strapiPaymentPage?.currency ?? ''}
            everyFourWeeks={data.strapiPaymentPage?.everyFourWeeks ?? ''}
            validatorsI18n={data.strapiPaymentPage?.validator as ValidatorsI18n}
            trialSevenDays={data.strapiPaymentPage?.trialSevenDays ?? ''}
            currencySymbol={data.strapiPaymentPage?.currencySymbol ?? ''}
            transactionInYourAccountAppearAs={data.strapiPaymentPage?.transactionInYourAccountAppearAs ?? ''}
            providersCustomMessages={
              (data.strapiPaymentPage?.providersCustomMessages as ProvidersCustomMessages[]) ?? []
            }
            callbackUserCreate={getPaymentInformationForSubscriptionId}
          />
        </div>
      </Layout>
    </div>
  )
}
