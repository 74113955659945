import { useQuerySharedComponentsFromPaymentPage } from '../data/graphql'

export const useSubscriptionDataStrapi = ({
  nameSubscription
}: {
  nameSubscription: string
}): { subscriptionData: Queries.Maybe<Queries.STRAPI__COMPONENT_FORMS_SUBSCRIPTION> | undefined } => {
  const { subscriptions } = useQuerySharedComponentsFromPaymentPage()

  const subscriptionData = subscriptions?.find(
    (items: Queries.Maybe<Queries.STRAPI__COMPONENT_FORMS_SUBSCRIPTION> | undefined) => items?.sku === nameSubscription
  )

  return {
    subscriptionData
  }
}
