export const ENDPOINTS = {
  FIND_SUBSCRIPTTION_TTYPE_BY_CODE: (code: string) => `subscription/type/${code}`,
  CANCEL_SUBSCRIPTION: (id: string) => `subscription/${id}/cancel`,
  SUBSCRIPTION: 'subscription',
  SUBSCRIPTION_NO_AUTH: 'subscription/no-auth',
  SUBSCRIPTION_PAYMENT: (subscriptionId: string) => `subscription/${subscriptionId}/payment`,
  SUBSCRIPTION_UPDATE_PAY: 'subscription/updatePay',
  GET_SUBSCRIPTION: (id: string) => `subscription/${id}`,
  SUBSCRIPTION_UPGRADE: 'subscription/upgrade',
  SUBSCRIPTION_TYPE_OFFERS: 'subscription/type/offers',
  SUBSCRIPTION_TYPE_UPGRADE: 'subscription/type/upgrade'
}
