import { datadogLogs } from '@datadog/browser-logs'

import { type CreditCard } from '../domain'
import { FindCreditCardsActiveByCustomer } from '../infra'

export function FindCreditCardsActiveByCustomerUseCase({
  successCallback,
  errorCallback,
  finallyCallback
}: {
  successCallback: (creditCards: CreditCard[]) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorCallback?: (error: any) => void
  finallyCallback?: () => void
}): void {
  FindCreditCardsActiveByCustomer()
    .then((creditCards) => {
      datadogLogs.logger.info('FindCreditCardsActiveByCustomerUseCase')
      successCallback(creditCards)
    })

    .catch((error) => {
      datadogLogs.logger.error('FindCreditCardsActiveByCustomerUseCase', undefined, error)
      if (errorCallback) errorCallback(error)
    })
    .finally(() => {
      if (finallyCallback) finallyCallback()
    })
}
