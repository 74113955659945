import HttpClient, { type HttpClientResponse } from '@legal/core/http/http-client'

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class UploadCustomerDocument {
  public static async Execute({
    userToken,
    formData
  }: {
    userToken: string
    formData: FormData
  }): Promise<HttpClientResponse<unknown>> {
    const http = HttpClient.create({
      baseUrl: `${process.env.API_PREFIX}`,
      defaults: {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      }
    })

    return await http.postForm('documents', formData)
  }
}
