/* eslint-disable @typescript-eslint/no-explicit-any */

import { BillCard } from '@npm_leadtech/legal-lib-components/BillCard'
import { Button } from '@npm_leadtech/legal-lib-components/Button'
import React from 'react'
import { SearchSelect } from '@npm_leadtech/legal-lib-components/SearchSelect'

import { type BillingDocumentsProps } from './BillingDocumentsProps'
import { Section } from '../Section'
import { formatDate } from '@legal/shared/utils/formatDate'
import getFormattedAmount from '../../../services/utils/getFormattedAmount'
import { useQueryCommonsText } from '@legal/shared/data/graphql/queries/useQueryCommonsText'
import './BillingDocuments.scss'

export const BillingDocuments: React.FC<BillingDocumentsProps> = ({ invoices, filterInvoices }) => {
  const [collapsed, updateListState] = React.useState(true)
  const { currrencySymbol } = useQueryCommonsText()
  if (!invoices.length) {
    return null
  }

  const currentYear = new Date().getFullYear()
  const initYears = (): {
    label: number
    value: number
  }[] => {
    const firstInvoiceYear = new Date(invoices[0].paidAt).getFullYear()
    const years = []
    for (let year = firstInvoiceYear; year <= currentYear; year++) {
      years.push({ label: year, value: year })
    }
    return years
  }

  const initInvoices = (): any[] => {
    return invoices
      .filter((invoice) => invoice.filtered)
      .filter((_, index) => (collapsed ? index < 4 : true))
      .map((invoice) => {
        invoice.parsedDate = parseInvoiceDate(invoice.paidAt)
        return invoice
      })
  }

  const parseInvoiceDate = (date: string): string => {
    const dateFormatted = formatDate({ date, format: 'MMMM, YYYY' })
    return dateFormatted
  }

  const filterDocuments = ({
    target: {
      value: { value: selectedYear }
    }
  }): void => {
    filterInvoices({ invoices, selectedYear })
  }

  const filteredInvoices = initInvoices()
  const years = initYears()

  const attributes = {
    title: 'Billing Documents',
    className: 'section--list-item',
    optionalComponent: years.length > 1 && (
      <SearchSelect
        onChange={filterDocuments}
        customClass='search-select--bare search-select--small'
        items={years}
        name='billing_year'
        value={currentYear}
        validate
        isValidGroup
      />
    )
  }

  const toggleList = (): void => {
    updateListState(!collapsed)
  }

  const buttonText = collapsed ? 'Show more' : 'Show less'
  const showToggleButton = invoices.length > 4

  return (
    <Section {...attributes}>
      {filteredInvoices.length > 0 ?
        <>
          <ul>
            {filteredInvoices.map((invoice) => (
              <li key={invoice.id} className='billing-documents__document'>
                <BillCard
                  date={invoice.parsedDate}
                  amount={getFormattedAmount({ amount: invoice.amount, currrencySymbol })}
                  subscriptionType={invoice.subscriptionTypeName}
                ></BillCard>
              </li>
            ))}
          </ul>
          {showToggleButton && (
            <div className='billing-documents__footer'>
              <Button color='tertiary' noLink label={buttonText} onClick={toggleList} />
            </div>
          )}
        </>
      : <div>No billing documents exist for the selected year</div>}
    </Section>
  )
}
