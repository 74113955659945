import { datadogLogs } from '@datadog/browser-logs'

import { SendContactForm } from '../infra'
import { type SendContactFormRequest } from './interfaces'

export function SendContactFormUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  request
}: {
  successCallback: () => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorCallback?: (error: any) => void
  finallyCallback?: () => void
  request: SendContactFormRequest
}): void {
  SendContactForm({ data: request })
    .then(() => {
      datadogLogs.logger.info('SendContactFormUseCase', request)
      successCallback()
    })

    .catch((error: Error | undefined) => {
      datadogLogs.logger.error('SendContactFormUseCase', request, error)
      if (errorCallback) errorCallback(error)
    })
    .finally(() => {
      if (finallyCallback) finallyCallback()
    })
}
