/* eslint-disable @typescript-eslint/no-explicit-any */

import { datadogLogs } from '@datadog/browser-logs'

import { UpdateApplicationForm } from '../infra'
import { type UpdateApplicationFormRequest } from './interfaces'

export function UpdateApplicationFormUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  applicationId,
  request
}: {
  successCallback: () => void
  errorCallback?: (error: any) => void
  finallyCallback?: () => void
  applicationId: string
  request: UpdateApplicationFormRequest
}): void {
  UpdateApplicationForm({ applicationId, request })
    .then(() => {
      datadogLogs.logger.info(`UpdateApplicationFormUseCase applicationId: ${applicationId}`, request)
      successCallback()
    })
    .catch((error: Error | undefined) => {
      datadogLogs.logger.error(`UpdateApplicationFormUseCase applicationId: ${applicationId}`, request, error)
      if (errorCallback) errorCallback(error)
    })
    .finally(() => {
      if (finallyCallback) finallyCallback()
    })
}
