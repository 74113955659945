import { datadogLogs } from '@datadog/browser-logs'

import { UpdateApplicationData } from '../infra'
import { type UpdateApplicationDataRequest } from './interfaces'

export function UpdateApplicationDataUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  applicationId,
  request
}: {
  successCallback?: () => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorCallback?: (error: any) => void
  finallyCallback?: () => void
  applicationId: string
  request: UpdateApplicationDataRequest
}): void {
  UpdateApplicationData({ applicationId, request })
    .then(() => {
      datadogLogs.logger.info(`UpdateApplicationDataUseCase applicationId: ${applicationId}`, request)
      if (successCallback) successCallback()
    })

    .catch((error: Error | undefined) => {
      datadogLogs.logger.error(`UpdateApplicationDataUseCase applicationId: ${applicationId}`, request, error)
      if (errorCallback) errorCallback(error)
    })
    .finally(() => {
      if (finallyCallback) finallyCallback()
    })
}
