import { datadogLogs } from '@datadog/browser-logs'

import { type ApiError } from '@legal/shared/HttpClient'
import { GetSubscription } from '../infra'
import { type Subscription } from '../domain'

export function GetSubscriptionUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  request
}: {
  successCallback: (subscriptions: Subscription) => void
  errorCallback?: (error: ApiError) => void
  finallyCallback?: () => void
  request: { subscriptionId: string }
}): void {
  GetSubscription(request.subscriptionId)
    .then((subscription) => {
      datadogLogs.logger.info('GetSubscriptionUseCase')
      successCallback(subscription)
    })
    .catch((error: ApiError) => {
      datadogLogs.logger.error(`GetSubscriptionUseCase ${error.message}`, request)
      if (errorCallback) errorCallback(error)
    })
    .finally(() => {
      if (finallyCallback) finallyCallback()
    })
}
