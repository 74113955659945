import { datadogLogs } from '@datadog/browser-logs'

import { type Customer } from '../domain'
import { GetCustomer } from '../infra'

export function GetCustomerUseCase({
  successCallback,
  errorCallback,
  finallyCallback
}: {
  successCallback: (response: Customer) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorCallback?: (error: any) => void
  finallyCallback?: () => void
}): void {
  GetCustomer()
    .then((response) => {
      datadogLogs.logger.info('GetCustomer')
      successCallback(response)
    })

    .catch((error: Error | undefined) => {
      datadogLogs.logger.error('GetCustomer', undefined, error)
      if (errorCallback) errorCallback(error)
    })
    .finally(() => {
      if (finallyCallback) finallyCallback()
    })
}
