import { mockStateSelectMessi, mockStepState } from '../../constants/statesStepMessi'
import { cloneObjectOrArray } from '../cloneObjectOrArray/cloneObjectOrArray'
import { getParamUrlValue } from '../paramsUrl'
import { stringSlugify } from '../stringSlugify'

interface AddStepsTypeAndStateParams {
  formJson: unknown
  formStates: unknown
  positionOfDynamicStates: number
}

interface AddStatesStepParams {
  formJson: unknown
  formStates: unknown
  nameFirstStep: string
  sectionNavFirstStep: string
  positionOfDynamicStates: number
}

const addStepsTypeAndState = ({
  formJson,
  formStates,
  positionOfDynamicStates = 1
}: AddStepsTypeAndStateParams): unknown => {
  let newFormJson = cloneObjectOrArray(formJson)
  if (newFormJson.plainJson) {
    let nameFirstStep = ''
    const sectionNavFirstStep = newFormJson.structureJson.steps[positionOfDynamicStates].sectionNav
    if (positionOfDynamicStates === 0) {
      nameFirstStep = newFormJson.structureJson.steps[positionOfDynamicStates].name
    }

    if (positionOfDynamicStates > 0) {
      nameFirstStep = newFormJson.structureJson.steps[positionOfDynamicStates].name
      newFormJson.structureJson.steps[positionOfDynamicStates - 1].defaultNextStep = mockStepState.name
    }

    newFormJson = addStatesStep({
      formJson: newFormJson,
      nameFirstStep,
      formStates,
      positionOfDynamicStates,
      sectionNavFirstStep
    })
  }

  return newFormJson
}

const addStatesStep = ({
  formJson,
  nameFirstStep,
  sectionNavFirstStep,
  formStates,
  positionOfDynamicStates = 1
}: AddStatesStepParams): unknown => {
  const newFormJson = cloneObjectOrArray(formJson)

  const dataMockStateSelectMessi = mockStateSelectMessi
  dataMockStateSelectMessi.items = formStates
  const state = getParamUrlValue('type')
  const stateFound = mockStateSelectMessi.items.find((item) => stringSlugify(item.label) === state)
  dataMockStateSelectMessi.value = stateFound?.label || 'California'
  newFormJson.plainJson[mockStateSelectMessi.name] = dataMockStateSelectMessi
  const dataMockStepState = mockStepState

  dataMockStepState.defaultNextStep = nameFirstStep
  dataMockStepState.sectionNav = sectionNavFirstStep

  if (positionOfDynamicStates === 0) {
    newFormJson.structureJson.steps.splice(positionOfDynamicStates, 0, dataMockStepState)
  }

  if (positionOfDynamicStates > 0) {
    newFormJson.structureJson.steps.splice(positionOfDynamicStates, 0, dataMockStepState)
  }

  return newFormJson
}

export { addStepsTypeAndState, addStatesStep }
