import { Button } from '@npm_leadtech/legal-lib-components/Button'
import { Radio } from '@npm_leadtech/legal-lib-components/Radio'
import React from 'react'
import { navigate } from 'gatsby-link'

import { Modal } from '../../layout'
import { SubscriptionCookie } from '../../../services/storage/cookies/SubscriptionCookie'
import { type UpgradeSubscriptionModalProps } from './UpgradeSubscriptionModalProps'
import { UpgradeSubscriptionUseCase } from '@legal/subscription'
import { isMobileDevice } from '../../../services/utils/isMobileDevice'
import { stringSlugify } from '../../../services/utils/stringSlugify'
import './UpgradeSubscriptionModal.scss'

export const UpgradeSubscriptionModal: React.FC<UpgradeSubscriptionModalProps> = ({
  availableSubscriptions,
  closeFunction,
  currentSubscriptionId
}) => {
  const subscriptionCookie = new SubscriptionCookie()
  const [subscriptionChosenMobile, setSubscriptionChosenMobile] = React.useState('Best Value')
  const [optionSelectedMobile, setOptionSelectedMobile] = React.useState(true)
  const [options, setOptions] = React.useState<
    {
      value: string
      label: string
      iconName?: string
    }[]
  >([])
  const [reverseSubscriptions] = React.useState([...availableSubscriptions].reverse())

  React.useEffect(() => {
    const newOptions: {
      value: string
      label: string
      iconName?: string
    }[] = []
    reverseSubscriptions.forEach((item) => {
      const option = {
        value: item.type.name,
        label: item.type.name,
        checked: item.type.name === subscriptionChosenMobile
      }
      newOptions.push(option)
    })
    setOptions(newOptions)
  }, [subscriptionChosenMobile])

  const onTypeChange = (e): void => {
    setSubscriptionChosenMobile(e.target.value)
    setOptionSelectedMobile(!optionSelectedMobile)
  }

  const changeToNewSubscription = (subName, currentSubType): void => {
    subscriptionCookie.subscriptionId = currentSubscriptionId
    subscriptionCookie.subscriptionTypeId = currentSubType

    const slug = subName.toLowerCase().split(' ').join('-')

    UpgradeSubscriptionUseCase({
      request: {
        currentSubscriptionId,
        subscriptionPriceId: currentSubType
      },
      successCallback: (response) => {
        if (response.subscriptionId) subscriptionCookie.subscriptionId = response.subscriptionId
        void navigate(`/payment?subs-type=${slug}`)
      }
    })
  }

  const optionalComponent = (): React.JSX.Element => (
    <div className='buttons-mobile'>
      <Radio
        name={'subcription'}
        class='--tabs'
        value={subscriptionChosenMobile}
        items={options}
        checked={subscriptionChosenMobile}
        isValidGroup={true}
        validate={true}
        onChange={onTypeChange}
        narrow
      />
    </div>
  )
  const buttonUpgrade = (name, id): React.JSX.Element => {
    const dataQASubscriptionName = stringSlugify(name)
    return (
      <Button
        givenClass={'upgrade-button'}
        color={name === 'Best Value' ? 'primary' : 'secondary'}
        label='Upgrade'
        onClick={() => {
          changeToNewSubscription(name, id)
        }}
        noLink
        dataQa={`upgrade-${dataQASubscriptionName}-modal-button`}
      />
    )
  }

  const isMobile = isMobileDevice()

  return (
    <Modal
      title='Upgrade now and get unlimited access for much less!'
      size='L'
      type='default'
      closeFunction={closeFunction}
      customClass='upgrade-modal'
    >
      <div className={reverseSubscriptions.length > 1 ? 'pane-upgrade' : 'pane-upgrade-one-plan'}>
        <div className={` ${subscriptionChosenMobile === 'Best Value' ? 'selector' : 'selector-secondary'}`}>
          {reverseSubscriptions.length > 1 && optionalComponent()}
          {isMobile &&
            reverseSubscriptions.map((subscription) => {
              if (reverseSubscriptions.length === 1) {
                return (
                  <div key={subscription.id} className='subscription-card-pane with-background'>
                    <div className='section-width'>
                      <div className='title'>{subscription.type.name}</div>
                      <div className='price'>{subscription.type.config.paymentPage?.displayPrice?.value}</div>
                      <div className='separator-mobile'></div>
                      <div className='disclaimer-desktop-one-item'>
                        <div>
                          <div className='disclaimer'>
                            {subscription.type.config.pricingPage.displayPrice.annotation}
                          </div>
                          <div>{buttonUpgrade(subscription.type.name, subscription.id)}</div>
                        </div>
                      </div>
                    </div>
                    <div className='benefits'>
                      <ul>
                        {subscription.type.config.pricingPage.content.map((info, index) => (
                          <li key={index} className='item-benefits'>
                            {info}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className='disclaimer-button-upgrade'>
                      <div>{buttonUpgrade(subscription.type.name, subscription.id)}</div>
                      <div className='disclaimer'>{subscription.type.config.pricingPage.displayPrice.annotation}</div>
                    </div>
                  </div>
                )
              } else {
                const primary =
                  subscription.isBestValue ? 'subscription-card-pane with-background' : 'subscription-card-pane'

                return (
                  <div
                    key={subscription.id}
                    className={`${primary} ${subscription.type.isBestValue ? 'first-selected' : 'last-selected'}`}
                  >
                    <div className='title'>{subscription.type.name}</div>
                    <div className='price'>{subscription.type.config.paymentPage?.displayPrice?.value}</div>
                    <div className='separator-mobile'></div>
                    <div className='benefits'>
                      <ul>
                        {subscription.type.config.pricingPage.content.map((info, index) => (
                          <li key={index} className='item-benefits'>
                            {info}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div>{buttonUpgrade(subscription.type.name, subscription.id)}</div>
                    <div className='disclaimer'>
                      {!subscription.type.isSevenDayAccess ?
                        subscription.type.config.pricingPage.displayPrice.annotation
                      : subscription.type.config.pricingPage.disclaimer}
                    </div>
                  </div>
                )
              }
            })}
        </div>
        {!isMobile &&
          reverseSubscriptions.map((subscription) => {
            if (reverseSubscriptions.length === 1) {
              return (
                <div key={subscription.id} className='subscription-card-pane with-background'>
                  <div className='section-width'>
                    <div className='title'>{subscription.type.name}</div>
                    <div className='price'>{subscription.type.config.paymentPage?.displayPrice?.value}</div>
                    <div className='separator-mobile'></div>
                    <div className='disclaimer-desktop-one-item'>
                      <div>
                        <div className='disclaimer'>{subscription.type.config.pricingPage.displayPrice.annotation}</div>
                        <div>{buttonUpgrade(subscription.type.name, subscription.id)}</div>
                      </div>
                    </div>
                  </div>
                  <div className='benefits'>
                    <ul>
                      {subscription.type.config.pricingPage.content.map((info, index) => (
                        <li key={index} className='item-benefits'>
                          {info}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className='disclaimer-button-upgrade'>
                    <div>{buttonUpgrade(subscription.type.name, subscription.id)}</div>
                    <div className='disclaimer'>{subscription.type.config.pricingPage.displayPrice.annotation}</div>
                  </div>
                </div>
              )
            } else {
              const primary =
                subscription.isBestValue ? 'subscription-card-pane with-background' : 'subscription-card-pane'

              return (
                <div
                  key={subscription.id}
                  className={`${primary} ${subscription.type.isBestValue ? 'first-selected' : 'last-selected'}`}
                >
                  <div className='title'>{subscription.type.name}</div>
                  <div className='price'>{subscription.type.config.paymentPage?.displayPrice?.value}</div>
                  <div className='separator-mobile'></div>
                  <div className='benefits'>
                    <ul>
                      {subscription.type.config.pricingPage.content.map((info, index) => (
                        <li key={index} className='item-benefits'>
                          {info}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div>{buttonUpgrade(subscription.type.name, subscription.id)}</div>
                  <div className='disclaimer'>
                    {!subscription.type.isSevenDayAccess ?
                      subscription.type.config.pricingPage.displayPrice.annotation
                    : subscription.type.config.pricingPage.disclaimer}
                  </div>
                </div>
              )
            }
          })}
      </div>
    </Modal>
  )
}
