import { ENDPOINTS } from './Endpoints'
import { post } from '@legal/shared/HttpClient'

export async function CreateCustomerSubscription({
  subscriptionPriceId,
  applicationId
}: {
  subscriptionPriceId: string
  applicationId: string
}): Promise<{ subscriptionId: string }> {
  const body = { subscriptionPriceId, applicationId }
  return await post<{ subscriptionId: string }>(ENDPOINTS.SUBSCRIPTION, body, true)
}
