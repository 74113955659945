import { datadogLogs } from '@datadog/browser-logs'

import HttpClient, { type HttpClientResponse } from '@legal/core/http/http-client'
import { getConfigs } from '@legal/core/configs'

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class GetFormsByName {
  public static async Execute({ name }: { name: string }): Promise<HttpClientResponse<unknown>> {
    const { API_CONFIG } = getConfigs()
    const http = HttpClient.create({
      baseUrl: API_CONFIG.API_PREFIX,
      defaults: {
        headers: {
          Authorization: API_CONFIG.HTTP_SECRET_KEY
        }
      }
    })

    const responsePromise = new Promise<HttpClientResponse<unknown>>((resolve, reject) => {
      http
        .get(`forms/${name}`)
        .then((response) => {
          datadogLogs.logger.info('GetFormsByName', response)
          resolve(response)
        })
        .catch((error: object | undefined) => {
          datadogLogs.logger.error('GetFormsByName', error)
          reject(error)
        })
    })

    return await responsePromise
  }
}
