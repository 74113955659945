import { datadogLogs } from '@datadog/browser-logs'

import { GetHtmlPreviewByUuid } from '../infra'

export function GetHtmlPreviewByUuidUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  applicationId
}: {
  successCallback: (htmlPreview: string) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorCallback?: (error: any) => void
  finallyCallback?: () => void
  applicationId: string
}): void {
  GetHtmlPreviewByUuid(applicationId)
    .then((htmlPreview: string) => {
      datadogLogs.logger.info(`GetHtmlPreviewByUuidUseCase applicationId: ${applicationId}`)
      successCallback(htmlPreview)
    })

    .catch((error: Error | undefined) => {
      datadogLogs.logger.error(`GetHtmlPreviewByUuidUseCase applicationId: ${applicationId}`, undefined, error)
      if (errorCallback) errorCallback(error)
    })
    .finally(() => {
      if (finallyCallback) finallyCallback()
    })
}
