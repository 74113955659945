/* eslint-disable @typescript-eslint/strict-boolean-expressions */

import { Button } from '@npm_leadtech/legal-lib-components/Button'
import { Link } from 'gatsby'
import React from 'react'

import { type DesktopPaneProps } from './DesktopPaneProps'
import { LoginContext } from '../../../services/utils/contexts'
import { NavbarItems } from '../NavbarItems'
import { UserCookie } from '../../../services/storage/cookies/UserCookie'
import phoneIcon from '../../../assets/images/svg/icon-24-px-pohne.svg'
import { useConfigs } from '@legal/core/configs'
import './DesktopPane.scss'

export const DesktopPane: React.FC<DesktopPaneProps> = ({
  loginModalOpen = false,
  registerModalOpen = false,
  toggleRegister,
  toggleLogin,
  headerHasNoProducts = false,
  isURLForm = false,
  baseLinkForTestAB,
  tokenActive,
  isTestAB8814 = false,
  changeUrlRedirect,
  hideDesktopNavbar = false,
  hideDashboardButton = false,
  showModalOnLinks = false
}) => {
  const {
    IS_JONSNOW,
    SITE_METADATA: { SITE_PHONE }
  } = useConfigs()
  const [userActions, setUserActions] = React.useState(null)
  const userCookie = new UserCookie()
  React.useEffect(() => {
    const tokenCookie = userCookie ? userCookie.token : ''

    let tempUserActions
    if (isTestAB8814) {
      tempUserActions =
        tokenCookie ?
          <div className={'navigation-pane__user-menu'}>
            <p className={'navigation-pane__user-name longer-text'}>Hello {userCookie.email.split('@')[0]}</p>
            {!hideDashboardButton && (
              <Button
                color='secondary'
                givenClass='go-to-dashboard-button'
                link={'/my-account/my-documents'}
                LinkComponent={Link}
                label={'Go to Dashboard'}
              />
            )}
          </div>
        : <div className={'navigation-pane__user-menu'}>
            <Button givenClass='login-button' color='tertiary' label={'Log in'} onClick={toggleLogin} noLink />
            <Button
              givenClass='go-to-free-account-button'
              color='secondary'
              label={'Create Free Account'}
              onClick={toggleRegister}
              noLink
            />
          </div>
    } else {
      tempUserActions =
        tokenCookie ?
          <div className={'navigation-pane__user-menu'}>
            <p className={'navigation-pane__user-name'}>Hello {userCookie.email.split('@')[0]}</p>
            {!hideDashboardButton && (
              <Button
                color='secondary'
                givenClass='go-to-dashboard-button'
                link={'/my-account/my-documents'}
                LinkComponent={Link}
                label={'Go to Dashboard'}
              />
            )}
          </div>
        : registerModalOpen === undefined && loginModalOpen === undefined ?
          <div className={'navigation-pane__user-menu'}>
            <Button givenClass='login-button' color='tertiary' label={'Log in'} link={'/login'} LinkComponent={Link} />
            <Button
              givenClass='go-to-free-account-button'
              color='secondary'
              label={'Create Free Account'}
              link={'/register'}
              LinkComponent={Link}
            />
          </div>
        : <div className={'navigation-pane__user-menu'}>
            <Button givenClass='login-button' color='tertiary' label={'Log in'} onClick={toggleLogin} noLink />
            <Button
              givenClass='go-to-free-account-button'
              color='secondary'
              label={'Create Free Account'}
              onClick={toggleRegister}
              noLink
            />
          </div>
    }
    setUserActions(tempUserActions)
  }, [isTestAB8814])

  const buttonConditional = React.useContext(LoginContext)

  return (
    <nav className={`navigation-pane --is-desktop ${headerHasNoProducts ? '--no-products' : ''}`}>
      {!IS_JONSNOW && isTestAB8814 && (
        <div className='header-phone'>
          <img src={phoneIcon} alt='' />
          <p className='header-phone__text'>
            <strong>{SITE_PHONE}</strong>
          </p>
        </div>
      )}
      {!hideDesktopNavbar && (
        <NavbarItems
          headerHasNoProducts={headerHasNoProducts}
          isURLForm={isURLForm}
          baseLinkForTestAB={baseLinkForTestAB}
          tokenActive={tokenActive}
          toggleRegister={toggleRegister}
          changeUrlRedirect={changeUrlRedirect}
          isTestAB8814={isTestAB8814}
          showModalOnLinks={showModalOnLinks}
        />
      )}
      {!hideDesktopNavbar && <>{!buttonConditional && userActions}</>}
    </nav>
  )
}
