import React from 'react'

import { type BenefitsProps } from './BenefitsProps'
import CheckIcon from '../../../assets/images/componentsSvg/check.svg'
import { replaceValues } from '../../../services/utils/replaceAll'
import { useQueryFormPage } from '@legal/shared/data/graphql'
import './Benefits.scss'

export const Benefits: React.FC<BenefitsProps> = ({ list = [], displayPrice }) => {
  const { benefitsTitle } = useQueryFormPage()
  return (
    <div className='benefits'>
      <p className='benefits__title'>{benefitsTitle}</p>
      <ul className='benefits__list'>
        {list.map((item) => {
          return (
            <li key={item} className='benefits__list__item'>
              <CheckIcon className='benefits__list__item__icon' />
              <span className='benefits__list__item__text'>{replaceValues(item, { DISPLAY_PRICE: displayPrice })}</span>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
