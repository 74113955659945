/* eslint-disable @typescript-eslint/strict-boolean-expressions */

import { BottomOverlayMenu } from '@npm_leadtech/legal-lib-components/BottomOverlayMenu'
import { ContactBox } from '@npm_leadtech/legal-lib-components/ContactBox'
import { Logo } from '@npm_leadtech/legal-lib-components/Logo'
import React from 'react'
import { WideInfoBar } from '@npm_leadtech/legal-lib-components/WideInfoBar'

import { AcceptCookieContext } from '../../../services/utils/contexts'
import { CookiePolicyBar } from '../../molecules'
import { CookiePolicyCookie } from '../../../services/storage/cookies/CookiePolicyCookie'
import { DisclaimerWrapper } from '../../atoms'
import { type FooterProps } from './FooterProps'
import { IconWeb } from '../../../assets/images/componentsSvg/IconWeb'
import { ProductCategories } from '../ProductCategories'
import facebook from '../../../assets/images/svg/facebook.svg'
import guaranteeIconGrey from '../../../assets/images/svg/icon-24-px-satisfaction-grey.svg'
import linkedin from '../../../assets/images/svg/linkedin.svg'
import pinterest from '../../../assets/images/svg/pinterest.svg'
import securityIconBlack from '../../../assets/images/svg/icon-24-px-security_black.svg'
import { setCookiePolicyFunc } from '../../../services/utils/setCookiePolicyFunc'
import { useAllFeaturedProducts } from '@legal/shared/hooks'
import { useConfigs } from '@legal/core/configs'
import { useQueryAllDocumentsFooter } from '@legal/shared/data/graphql'

import { menuMapper } from '@legal/shared/utils/menuMapper'
import x from '../../../assets/images/svg/x.svg'
import './Footer.scss'

const securityText = 'Secure and Confidential Process'
const guaranteeText = '100% Customer Satisfaction Guarantee'

export const Footer: React.FC<FooterProps> = ({
  isTestAB8814 = false,
  footerContactBar = false,
  isAnonymousPaymentPage = false,
  noFooter = false,
  ctaFooter,
  disclaimerLinks = false,
  environment,
  bottomOverlayContent,
  isFooterGivenClass = true,
  isDisclaimerHided = false
}) => {
  const {
    HAS_COOKIE_MESSAGE,
    IS_JONSNOW,
    SITE_METADATA: { SITE_PHONE, SITE_SCHEDULE },
    TARGET_ADDRESS,
    STRAPI_CONFIG,
    HAS_GRAV_SITEMAP,
    HAS_TRUSTPILOT
  } = useConfigs()
  const navbarItems = useAllFeaturedProducts()
  const productsCategories = menuMapper(navbarItems)
  const footerAllDocuments = useQueryAllDocumentsFooter()
  const [showCookieBar, setShowCookieBar] = React.useState(true)
  let acceptCookie = React.useContext(AcceptCookieContext)
  const cookiePolicyCookie = new CookiePolicyCookie()
  if (cookiePolicyCookie.cookiePolicyAccepted) {
    acceptCookie = true
  } else if (acceptCookie) {
    cookiePolicyCookie.cookiePolicyAccepted = true
  }
  const showMobileNavMenu = !!bottomOverlayContent
  const showBottomFixedContainer = showMobileNavMenu || (showCookieBar && acceptCookie === false)

  const handleHideCookieBar = (): void => {
    cookiePolicyCookie.cookiePolicyAccepted = true
    setShowCookieBar(false)
  }
  const trustpilotRef = React.useRef(null)
  React.useEffect(() => {
    if (HAS_TRUSTPILOT && window.Trustpilot) {
      window.Trustpilot.loadFromElement(trustpilotRef.current, true)
    }
  }, [])

  return (
    <>
      {ctaFooter && (
        <section className={'prefooter'}>
          <div className='wrapper'>
            <div className='prefooter-container'>
              <div className='serif --extra-large prefooter__title'>Try LawDistrict Now</div>
              <div className={'prefooter--columns'}>
                <p>Instant and complete access to our entire library of legal forms</p>
                <p>Edit, download and print in PDF and Word format from any device</p>
                <p>Save time and money on legal document creation</p>
              </div>
            </div>
          </div>
        </section>
      )}
      <footer className={isTestAB8814 ? 'test8814 extra-margin' : ''}>
        {!noFooter && (
          <section className={`footer__wrapper ${noFooter ? 'hideMobile' : ''}`}>
            <div className={'footer__products'}>
              <p className={'sans-serif --small footer-links__title'}>Products</p>
              <div className={'products__list'}>
                <ProductCategories productsCategories={productsCategories} setCookiePolicyFunc={setCookiePolicyFunc} />
                <ul className={'sans-serif --extra-small product-categories'}>
                  <a
                    title={footerAllDocuments?.footerTitle ?? ''}
                    onClick={setCookiePolicyFunc}
                    href={`${TARGET_ADDRESS}/${footerAllDocuments?.slug ?? ''}/`}
                  >
                    {footerAllDocuments?.footerTitle ?? ''}
                  </a>
                </ul>
              </div>
            </div>
            <div className={'footer__popular-documents'}>
              <p className={'sans-serif --small footer-links__title'}>Popular Documents</p>
              <ul>
                {navbarItems
                  .filter((product) => product.footerPopularDocument)
                  .map((product) => {
                    const productLink = product.categoryUrl ? `${product.categoryUrl}/${product.slug}` : product.slug
                    return (
                      <li className={'sans-serif --extra-small'} key={product.linkText}>
                        <a
                          title={product.linkText}
                          onClick={setCookiePolicyFunc}
                          href={`${TARGET_ADDRESS}/${productLink}/`}
                        >
                          {product.linkText}
                        </a>
                      </li>
                    )
                  })}
              </ul>
              <div className='footer__help__tablet'>
                <p className={'sans-serif --small footer-links__title'}>Help</p>
                <ul>
                  {!IS_JONSNOW && (
                    <li className={'sans-serif --extra-small'}>
                      <a title={'FAQs'} onClick={setCookiePolicyFunc} href={`${TARGET_ADDRESS}/faqs/`}>
                        FAQs
                      </a>
                    </li>
                  )}
                  <li className={'sans-serif --extra-small'}>
                    <a title={'Contact Us'} onClick={setCookiePolicyFunc} href={`${TARGET_ADDRESS}/contact-us/`}>
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className={'footer__company'}>
              <p className={'sans-serif --small footer-links__title'}>Company</p>
              <ul>
                <li className={'sans-serif --extra-small'}>
                  <a
                    title={'About Us'}
                    onClick={setCookiePolicyFunc}
                    href={`${TARGET_ADDRESS}/about-us/`}
                    rel='noindex'
                  >
                    About Us
                  </a>
                </li>
                <li className={'sans-serif --extra-small'}>
                  <a
                    title={'Terms and Conditions'}
                    onClick={setCookiePolicyFunc}
                    href={`${TARGET_ADDRESS}/terms-and-conditions/`}
                    rel='nofollow noopener'
                  >
                    Terms and Conditions
                  </a>
                </li>
                <li className={'sans-serif --extra-small'}>
                  <a
                    title={'Terms of Use'}
                    onClick={setCookiePolicyFunc}
                    href={`${TARGET_ADDRESS}/terms-of-use/`}
                    rel='nofollow noopener'
                  >
                    Terms of Use
                  </a>
                </li>
                <li className={'sans-serif --extra-small'}>
                  <a
                    title='Privacy and Cookies Policy'
                    onClick={setCookiePolicyFunc}
                    href={`${TARGET_ADDRESS}/privacy-and-cookies-policy/`}
                    rel='nofollow noopener'
                  >
                    Privacy and Cookies Policy
                  </a>
                </li>
              </ul>
              <div className='footer__findus__mobile'>
                {IS_JONSNOW && (
                  <div className={'footer__logo'}>
                    <Logo
                      siteName={STRAPI_CONFIG.DOMAIN}
                      className='footer__lawdistrict'
                      width='138'
                      height='24'
                      type='white'
                    />
                  </div>
                )}
              </div>
            </div>
            <div className='footer__company__resources__findUs'>
              <p className={'sans-serif --small footer-links__title'}>Company</p>
              <ul>
                <li className={'sans-serif --extra-small'}>
                  <a
                    title={'About Us'}
                    onClick={setCookiePolicyFunc}
                    href={`${TARGET_ADDRESS}/about-us/`}
                    rel='noindex'
                  >
                    About Us
                  </a>
                </li>
                <li className={'sans-serif --extra-small'}>
                  <a
                    title={'Terms and Conditions'}
                    onClick={setCookiePolicyFunc}
                    href={`${TARGET_ADDRESS}/terms-and-conditions/`}
                    rel='nofollow noopener'
                  >
                    Terms and Conditions
                  </a>
                </li>
                <li className={'sans-serif --extra-small'}>
                  <a
                    title={'Terms of Use'}
                    onClick={setCookiePolicyFunc}
                    href={`${TARGET_ADDRESS}/terms-of-use/`}
                    rel='nofollow noopener'
                  >
                    Terms of Use
                  </a>
                </li>
                <li className={'sans-serif --extra-small'}>
                  <a
                    title='Privacy and Cookies Policy'
                    onClick={setCookiePolicyFunc}
                    href={`${TARGET_ADDRESS}/privacy-and-cookies-policy/`}
                    rel='nofollow noopener'
                  >
                    Privacy and Cookies Policy
                  </a>
                </li>
              </ul>
              {!IS_JONSNOW && HAS_GRAV_SITEMAP && (
                <div className='footer__resources'>
                  <p className={'sans-serif --small footer-links__title'}>Resources</p>
                  <ul>
                    <li className={'sans-serif --extra-small'}>
                      <a title={'Articles'} onClick={setCookiePolicyFunc} href={`${TARGET_ADDRESS}/articles`}>
                        Articles
                      </a>
                    </li>
                    <li className={'sans-serif --extra-small'}>
                      <a
                        title={'Legal Dictionary'}
                        onClick={setCookiePolicyFunc}
                        href={`${TARGET_ADDRESS}/legal-dictionary`}
                      >
                        Legal Dictionary
                      </a>
                    </li>
                  </ul>
                </div>
              )}
            </div>

            <div className={'footer__help__resources'}>
              <div>
                <div className='footer__help'>
                  <p className={'sans-serif --small footer-links__title'}>Help</p>
                  <ul>
                    {!IS_JONSNOW && (
                      <li className={'sans-serif --extra-small'}>
                        <a title={'FAQs'} onClick={setCookiePolicyFunc} href={`${TARGET_ADDRESS}/faqs/`}>
                          FAQs
                        </a>
                      </li>
                    )}
                    <li className={'sans-serif --extra-small'}>
                      <a title={'Contact Us'} onClick={setCookiePolicyFunc} href={`${TARGET_ADDRESS}/contact-us/`}>
                        Contact Us
                      </a>
                    </li>
                  </ul>
                </div>
                {!IS_JONSNOW && HAS_GRAV_SITEMAP && (
                  <div className='footer__resources'>
                    <p className={'sans-serif --small footer-links__title'}>Resources</p>
                    <ul>
                      <li className={'sans-serif --extra-small'}>
                        <a title={'Articles'} onClick={setCookiePolicyFunc} href={`${TARGET_ADDRESS}/articles`}>
                          Articles
                        </a>
                      </li>
                      <li className={'sans-serif --extra-small'}>
                        <a
                          title={'Legal Dictionary'}
                          onClick={setCookiePolicyFunc}
                          href={`${TARGET_ADDRESS}/legal-dictionary`}
                        >
                          Legal Dictionary
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div className={'footer__findUs'}>
              <div className='find-us-container'>
                {!IS_JONSNOW && (
                  <>
                    <div className='find-us-content'>
                      <p className={'sans-serif --small footer-links__title'}>Find us</p>
                      <ul>
                        <li>
                          <a
                            href={'https://www.facebook.com/lawdistrictlegal'}
                            rel='noopener noreferrer'
                            target='_blank'
                          >
                            <img src={facebook} alt='Facebook logo' width='32' height='32' />
                          </a>
                        </li>
                        <li>
                          <a href={'https://twitter.com/district_law'} rel='noopener noreferrer' target='_blank'>
                            <img src={x} alt='Twitter logo' width='32' height='32' />
                          </a>
                        </li>
                        <li>
                          <a
                            href={'https://www.linkedin.com/company/law-district/'}
                            rel='noopener noreferrer'
                            target='_blank'
                          >
                            <img src={linkedin} alt='Linkedin logo' width='32' height='32' />
                          </a>
                        </li>
                        <li>
                          <a href={'https://www.pinterest.com/lawdistrict/'} rel='noopener noreferrer' target='_blank'>
                            <img src={pinterest} alt='Pinterest logo' width='32' height='32' />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </>
                )}
                {!IS_JONSNOW && (
                  <div className='contact-box-container'>
                    <ContactBox phone={SITE_PHONE} schedule={SITE_SCHEDULE} mobileGtmTag='clicked_phone_footer' />
                  </div>
                )}
                <div className={'footer__logo'}>
                  <Logo
                    siteName={STRAPI_CONFIG.DOMAIN}
                    className='footer__lawdistrict'
                    width='138'
                    height='24'
                    type='white'
                  />
                </div>
              </div>
              {!IS_JONSNOW && HAS_TRUSTPILOT && (
                <div
                  ref={trustpilotRef}
                  className='trustpilot-widget'
                  data-locale={STRAPI_CONFIG.LOCALE}
                  data-template-id='53aa8807dec7e10d38f59f32'
                  data-businessunit-id='60dedd318223de00019548bb'
                  data-style-height='86px'
                  data-style-width='138px'
                  data-theme='dark'
                >
                  <a href='https://www.trustpilot.com/review/lawdistrict.com' target='_blank' rel='noreferrer'>
                    Trustpilot
                  </a>
                </div>
              )}
            </div>
            {!IS_JONSNOW && (
              <div className={'footer__mobile-contact'}>
                <ContactBox
                  mobile={true}
                  phone={SITE_PHONE}
                  schedule={SITE_SCHEDULE}
                  mobileGtmTag='clicked_phone_footer'
                  darkMode
                />
              </div>
            )}
          </section>
        )}
        {/* SuperTest  */}
        {footerContactBar && (
          <WideInfoBar {...(!isAnonymousPaymentPage && { color: 'green' })}>
            <>
              <div className={`contact-bar-wrapper ${(IS_JONSNOW || isAnonymousPaymentPage) && 'hidden'}`}>
                <IconWeb />
                <div className='contact-bar-wrapper__text'>
                  <div className='is-mobile'>
                    <a className='phone' href={`tel:${SITE_PHONE}`}>
                      <strong>{SITE_PHONE}</strong>
                    </a>
                    {` - ${SITE_SCHEDULE}`}
                  </div>
                  <div className='no-mobile'>
                    <strong>{SITE_PHONE}</strong> - {SITE_SCHEDULE}
                  </div>
                </div>
              </div>
              <div className='contact-bar-wrapper'>
                <img src={securityIconBlack} alt='' />
                <p className='contact-bar-wrapper__text'>{securityText}</p>
              </div>
              <div className='contact-bar-wrapper'>
                <img src={guaranteeIconGrey} alt='' />
                <p className='contact-bar-wrapper__text'>{guaranteeText}</p>
              </div>
            </>
          </WideInfoBar>
        )}
        {!isDisclaimerHided && (
          <DisclaimerWrapper
            givenClass={isFooterGivenClass ? 'grey-color' : ''}
            disclaimerLinks={disclaimerLinks}
            environment={environment}
          />
        )}
      </footer>
      {showBottomFixedContainer && HAS_COOKIE_MESSAGE && (
        <div id='bottom-fixed-container' className='bottom-fixed-container'>
          {showMobileNavMenu && <BottomOverlayMenu>{bottomOverlayContent}</BottomOverlayMenu>}
          {showCookieBar && acceptCookie === false && <CookiePolicyBar clickFunc={handleHideCookieBar} />}
        </div>
      )}
    </>
  )
}
