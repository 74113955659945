/* eslint-disable @typescript-eslint/no-explicit-any */

import { datadogLogs } from '@datadog/browser-logs'

import { GetTransactionStatusNoAuth } from '../infra'
import { type GetTransactionStatusNoAuthResponse } from './interfaces'

export function GetTransactionStatusNoAuthUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  transactionId
}: {
  successCallback: (result: GetTransactionStatusNoAuthResponse) => void
  errorCallback?: (error: any) => void
  finallyCallback?: () => void
  transactionId: string
}): void {
  GetTransactionStatusNoAuth(transactionId)
    .then((result: GetTransactionStatusNoAuthResponse) => {
      datadogLogs.logger.info('GetTransactionStatusNoAuthUseCase')
      successCallback(result)
    })
    .catch((error) => {
      datadogLogs.logger.error('GetTransactionStatusNoAuthUseCase', { transactionId }, error)
      if (errorCallback) errorCallback(error)
    })
    .finally(() => {
      if (finallyCallback) finallyCallback()
    })
}
