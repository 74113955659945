/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react'
import { Spinner } from '@npm_leadtech/legal-lib-components/Spinner'

import { GetApplicationStatusUseCase } from '@legal/application'
import { type LoadingPdfGenerationProps } from './LoadingPdfGenerationProps'
import { SubscriptionCookie } from '../../../services/storage/cookies/SubscriptionCookie'
import noDocs from '../../../assets/images/png/ld-illust-empty-state-dash.png'
import { setCookiePolicyFunc } from '../../../services/utils/setCookiePolicyFunc'
import { useApplicationCookie } from '@legal/shared/hooks'
import { useConfigs } from '@legal/core/configs'
import { useQueryCreateDocumentPdf } from '@legal/shared/data/graphql/queries/useQueryCreateDocumentPdf'
import './LoadingPdfGeneration.scss'

export const LoadingPdfGenerationDocumentActions: React.FC<LoadingPdfGenerationProps> = ({ handleChangeStatus }) => {
  const dataCreateDocumentPdf = useQueryCreateDocumentPdf()

  const { TARGET_ADDRESS } = useConfigs()
  const [attempts, setAttempts] = React.useState(0)
  const [applicationStatus, setApplicationStatus] = React.useState<string>()
  const maxAttempts = 15
  const { applicationCookie, setApplicationCookieIdToDownload, setApplicationCookieIsAutomaticallyDownload } =
    useApplicationCookie()
  const subscriptionCookie = new SubscriptionCookie()

  const getApplicationStatusSuccess = (status: string): void => {
    setApplicationStatus(status)
    if (applicationStatus !== 'pdf_generated' && applicationStatus !== 'downloaded') return
    subscriptionCookie.clear()
    setApplicationCookieIdToDownload(applicationCookie?.id)
    setApplicationCookieIsAutomaticallyDownload(true)
    handleChangeStatus('success')
  }

  const getApplicationStatusError = (error: any): void => {
    if (error.status === 401) {
      setCookiePolicyFunc()
      window.location = TARGET_ADDRESS
    }
    setApplicationStatus(undefined)
  }

  const getApplicationStatus = (): void => {
    if (!applicationCookie?.id) return
    GetApplicationStatusUseCase({
      applicationId: applicationCookie.id,
      successCallback: getApplicationStatusSuccess,
      errorCallback: getApplicationStatusError
    })
  }

  React.useEffect(() => {
    if (attempts < maxAttempts && applicationCookie?.id) {
      setTimeout(() => {
        getApplicationStatus()
        setAttempts(attempts + 1)
      }, 2000)
    } else {
      handleChangeStatus('error')
    }
  }, [attempts, applicationStatus])

  return (
    <div className={'wait-active-pdf-container'}>
      <div className={'wait-active-pdf-card'}>
        <img src={noDocs} alt='' />
        <div className={'wait-active-pdf-card-text'}>
          <h3>{dataCreateDocumentPdf.title}</h3>
          <p>{dataCreateDocumentPdf.text}</p>
        </div>
        <Spinner big className={'spinner--primary'} />
      </div>
    </div>
  )
}
