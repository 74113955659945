import { datadogLogs } from '@datadog/browser-logs'

import { type ApiError } from '@legal/shared/HttpClient'
import { GetSubscriptionTypeOffers } from '../infra'
import { type SubscriptionTypeOffer } from '../domain'

export function GetSubscriptionTypeOffersUseCase({
  successCallback,
  errorCallback,
  finallyCallback
}: {
  successCallback: (response: SubscriptionTypeOffer[]) => void
  errorCallback?: (error: ApiError) => void
  finallyCallback?: () => void
}): void {
  GetSubscriptionTypeOffers()
    .then((response) => {
      datadogLogs.logger.info('GetSubscriptionTypeOffersUseCase')
      successCallback(response)
    })
    .catch((error: ApiError) => {
      datadogLogs.logger.error(`GetSubscriptionTypeOffersUseCase ${error.message}`)
      if (errorCallback) errorCallback(error)
    })
    .finally(() => {
      if (finallyCallback) finallyCallback()
    })
}
