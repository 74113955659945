import { type CancelSubscriptionResponse } from '../../application'

import { ENDPOINTS } from './Endpoints'
import { formatDate } from '@legal/shared/utils/formatDate'
import { put } from '@legal/shared/HttpClient'

export async function CancelSubscription({
  subscriptionId,
  unsubscribeReason = ''
}: {
  subscriptionId: string
  unsubscribeReason?: string
}): Promise<CancelSubscriptionResponse> {
  const body = { unsubscribeReason }
  const result = await put<CancelSubscriptionResponse>(ENDPOINTS.CANCEL_SUBSCRIPTION(subscriptionId), body, true)
  return {
    subscriptionTypeName: result.subscriptionTypeName,
    unsubscribeAt: result.unsubscribeAt ? formatDate({ date: result.unsubscribeAt }) : undefined
  }
}
