import { datadogLogs } from '@datadog/browser-logs'

import { GetContactCustomerDetails } from '../infra'

export function GetContactCustomerDetailsUseCase({
  successCallback,
  errorCallback,
  finallyCallback
}: {
  successCallback: (reference: string) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorCallback?: (error: any) => void
  finallyCallback?: () => void
}): void {
  GetContactCustomerDetails()
    .then((reference) => {
      datadogLogs.logger.info('GetContactCustomerDetailsUseCase')
      successCallback(reference)
    })

    .catch((error: Error | undefined) => {
      datadogLogs.logger.error('GetContactCustomerDetailsUseCase', undefined, error)
      if (errorCallback) errorCallback(error)
    })
    .finally(() => {
      if (finallyCallback) finallyCallback()
    })
}
