/* eslint-disable @typescript-eslint/no-explicit-any */

import { datadogLogs } from '@datadog/browser-logs'

import { DeleteApplication } from '../infra'

export function DeleteApplicationUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  applicationId
}: {
  successCallback: () => void
  errorCallback?: (error: any) => void
  finallyCallback?: () => void
  applicationId: string
}): void {
  DeleteApplication(applicationId)
    .then(() => {
      datadogLogs.logger.info(`DeleteApplicationUseCase applicationId: ${applicationId}`)
      successCallback()
    })
    .catch((error: Error | undefined) => {
      datadogLogs.logger.error(`DeleteApplicationUseCase applicationId: ${applicationId}`, undefined, error)
      if (errorCallback) errorCallback(error)
    })
    .finally(() => {
      if (finallyCallback) finallyCallback()
    })
}
