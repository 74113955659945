/* eslint-disable @typescript-eslint/no-unused-vars */

import { navigate } from 'gatsby'

import { DeleteApplicationUseCase } from '@legal/application'
import { PreformTypeFormCookie } from '../../storage/cookies/PreformTypeFormCookie'
import { getParamUrlValue } from '../paramsUrl'
import { replaceAll } from '../replaceAll'
import { stringSlugify } from '../stringSlugify'

const propertyTypeRadio = 'propertyTypeRadioRla'
const propertyTypeRadioOther = 'propertyOtherRla'
const propertyTypeRadioInputName = 'other'

const propertyTypeRadioList = [propertyTypeRadio, 'whichPropertyRadioRnt']
const propertyTypeRadioOtherList = [propertyTypeRadioOther, 'whatPropertyRnt']

const createPreFormDataToSend = (formJson): unknown => {
  const data = {}

  formJson?.structureJson.steps[0].groups.forEach((group) => {
    group.fields.forEach((field) => {
      const name = formJson.plainJson[field]?.name
      const value = formJson.plainJson[field]?.value
      if (name && value) {
        data[name] = value
      }
    })
  })

  return data
}

const findStepWidthField = (formJsonData, keyField): unknown => {
  let itemFind = false
  const stepFind = formJsonData.structureJson.steps.find((step) => {
    step.groups.map((stepGroup) => {
      const itemStepGroup = stepGroup.fields.find((field) => field === keyField)
      if (itemStepGroup) {
        itemFind = true
      }
      return stepGroup
    })
    if (itemFind) {
      return step
    }
  })
  return stepFind
}

const getValueOtherInPreForm = (preformTypeFormData): string => {
  let valueOtherPreform = ''
  Object.entries(preformTypeFormData?.items).forEach(([key, value]) => {
    if (isValueOtherInPreForm(value?.namePreform)) {
      valueOtherPreform = {
        name: value?.name,
        value: value?.value
      }
    }
  })
  return valueOtherPreform
}

const isValueOtherInPreForm = (value = ''): boolean => {
  const includeItem = propertyTypeRadioOtherList.includes(value)
  return includeItem
}

const findFieldIntStepAndChangeValue = (formJson, step, newValue): void => {
  step.groups.map((stepGroup) => {
    stepGroup.fields.map((field) => {
      if (field.includes(newValue.name)) {
        formJson.plainJson[field].value = newValue.value
      }
    })
  })
}

const changeDataFormCasePropertyTypeRadio = ({ formJson, value, valueOtherPreform }): null => {
  Object.entries(formJson.plainJson).forEach(([keyPlainJson, valuePlainJson]) => {
    if (valuePlainJson?.name?.includes(value?.name)) {
      const itemFound = formJson.plainJson[keyPlainJson].items?.find((field) => field.value === value?.value)
      if (value?.value === propertyTypeRadioInputName) {
        formJson.plainJson[keyPlainJson].value = value?.value

        const stepFind = findStepWidthField(formJson, keyPlainJson)
        if (stepFind) {
          findFieldIntStepAndChangeValue(formJson, stepFind, valueOtherPreform)
        }
      } else if (itemFound) {
        formJson.plainJson[keyPlainJson].value = value?.value
      } else {
        formJson.plainJson[keyPlainJson].value = propertyTypeRadioInputName

        const stepFind = findStepWidthField(formJson, keyPlainJson)
        if (stepFind) {
          findFieldIntStepAndChangeValue(formJson, stepFind, value?.value)
        }
      }
    }
  })
  return null
}

const setPrefillParameterInForm = (formJson, preformTypeFormData): unknown => {
  if (preformTypeFormData?.items && !preformTypeFormData?.firstStepSkipped) {
    if (formJson.plainJson) {
      const valueOtherPreform = getValueOtherInPreForm(preformTypeFormData)
      Object.entries(preformTypeFormData?.items).forEach(([key, value]) => {
        if (value?.namePreform === propertyTypeRadio) {
          return changeDataFormCasePropertyTypeRadio({ formJson, value, valueOtherPreform })
        }

        if (formJson.plainJson[value.name]) {
          formJson.plainJson[value.name].value = value?.value
        }
      })
    }
  }

  return formJson
}

// testAB8814
const generateDataPreformType = (structure, form): unknown => {
  const nameFormStep0Type = structure?.steps?.[0]?.groups?.[0]?.fields?.[0]
  const valuePropertyTypeRadio = getPropertyTypeRadio({ list: propertyTypeRadioList, value: nameFormStep0Type })
  if (valuePropertyTypeRadio) {
    const nameFormStep0Other = structure?.steps?.[0]?.groups?.[1]?.fields?.[0]
    const valuePropertyTypeRadioOther = getPropertyTypeRadio({
      list: propertyTypeRadioOtherList,
      value: nameFormStep0Other
    })

    if (nameFormStep0Type && nameFormStep0Other) {
      const dataItemFormType = form[nameFormStep0Type]
      const dataItemFormTypeOther = form[nameFormStep0Other]

      if (dataItemFormType && dataItemFormTypeOther) {
        const data = {
          items: {
            [valuePropertyTypeRadio]: {
              name: valuePropertyTypeRadio,
              namePreform: propertyTypeRadio,
              value: dataItemFormType?.value || ''
            },
            [valuePropertyTypeRadioOther]: {
              name: valuePropertyTypeRadioOther,
              namePreform: propertyTypeRadioOther,
              value: dataItemFormTypeOther?.value || ''
            }
          }
        }
        return data
      }
    }
  }

  return null
}
// fin testAB8814
interface PropsGetPropertyTypeRadio {
  list: string[]
  value: string
}

const getPropertyTypeRadio = ({ list = [], value = '' }: PropsGetPropertyTypeRadio): string => {
  let valueFind = ''
  list.forEach((element) => {
    if (value.includes(element)) {
      valueFind = element
    }
  })
  return valueFind
}

// testAB8814
const changeSelectStatePreform = (
  structure,
  form,
  stateValue,
  resetCookies,
  applicationId,
  userToken,
  setDisabledNextStep
): void => {
  const stateInUrl = getParamUrlValue('type')
  const slugifyStateValue = stringSlugify(stateValue)
  if (stateValue && stateInUrl && slugifyStateValue !== stateInUrl) {
    setDisabledNextStep(true)
    const preformTypeFormCookie = new PreformTypeFormCookie()
    const urlProductActive = getParamUrlValue('product')?.trim()
    const dataTypeSelect = generateDataPreformType(structure, form)
    if (dataTypeSelect) {
      dataTypeSelect.firstStepSkipped = false
      preformTypeFormCookie.newItem(urlProductActive, dataTypeSelect)
    }

    let url = window.location.pathname + window.location.search + window.location.hash
    url = replaceAll(url, stateInUrl, slugifyStateValue)

    if (userToken && applicationId) {
      removeApplication(applicationId, resetCookies, url)
    } else {
      resetCookies()
      void navigate(url)
    }
  }
}

const removeApplication = (applicationId: string, resetCookies, url: string): void => {
  DeleteApplicationUseCase({
    applicationId,
    successCallback: () => {
      resetCookies()
      void navigate(url)
    }
  })
}
// fin testAB8814

export {
  propertyTypeRadio,
  propertyTypeRadioOther,
  propertyTypeRadioInputName,
  createPreFormDataToSend,
  setPrefillParameterInForm,
  changeSelectStatePreform
}
