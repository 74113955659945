import React from 'react'

import { type BillingRefundsProps } from './BillingRefundsProps'

import { Section } from '../Section'
import { formatDate } from '@legal/shared/utils/formatDate'
import getFormattedAmount from '../../../services/utils/getFormattedAmount'
import { useQueryCommonsText } from '@legal/shared/data/graphql/queries/useQueryCommonsText'
import './BillingRefunds.scss'

const renderRefunds = ({ amount, id, paidAt, subscriptionTypeName }): React.JSX.Element => {
  const date = formatDate({ date: paidAt.date, format: 'MMMM, YYYY' }).split(' ').reverse().join(' ')
  const { currrencySymbol } = useQueryCommonsText()
  const formattedAmount = getFormattedAmount({ amount, currrencySymbol })
  return (
    <div key={id} className='refund-content'>
      <span>
        {date} - {subscriptionTypeName}
      </span>
      <span>{formattedAmount}</span>
    </div>
  )
}

export const BillingRefunds: React.FC<BillingRefundsProps> = ({ refunds }) => {
  if (!refunds?.length) {
    return null
  }

  return (
    <Section title='Refunds' className='section--list-item'>
      <div className='card-info refund-card'>{refunds.map(renderRefunds)}</div>
    </Section>
  )
}
