import React from 'react'

import { GetSubscriptionTypeByCodeUseCase, type Subscription } from '@legal/subscription'

export const useSubscriptionToShowCustomer = (): {
  isSevenDayAccess: boolean
  subscription?: Subscription
  loadingSubscription: boolean
  subscriptionsType: Subscription[]
} => {
  const [subscription, setSubscription] = React.useState<Subscription>()
  const [subscriptionsType, setSubscriptionsType] = React.useState<Subscription[]>([])
  const [loadingSubscription, setLoadingSubscription] = React.useState<boolean>(true)
  const [isSevenDayAccess, setIsSevenDayAccess] = React.useState<boolean>(false)

  React.useEffect(() => {
    GetSubscriptionTypeByCodeUseCase({
      successCallback: (subscriptions) => {
        setSubscriptionsType(subscriptions)
        setSubscription(subscriptions[0])
        setIsSevenDayAccess(subscriptions[0].isSevenDayAccess)
      },
      finallyCallback: () => {
        setLoadingSubscription(false)
      }
    })
  }, [])

  return {
    isSevenDayAccess,
    subscription,
    loadingSubscription,
    subscriptionsType
  }
}
