/* eslint-disable @typescript-eslint/no-explicit-any */

import { TypeSelectorValidate } from '../../../../services/form/TypeSelectorValidate'
import { getUsedFields } from '.'

export const validator = ({
  stepsData,
  updateState,
  form
}): {
  groupValidator: (step: any) => boolean
  stepValidator: (step: any) => boolean
  fieldValidator: (fieldName: any) => boolean
} => {
  const groupValidator = (step): boolean => {
    const stepStructure = stepsData.newStepStructure.steps[step + 1]
    let groupValidation = false

    if (stepStructure) {
      if (stepStructure.groups.length === 0) groupValidation = true
      else {
        stepStructure.groups.map((group) => {
          if (!group.required) groupValidation = true
          else {
            group.fields.map((field) => {
              const fieldHasValue = TypeSelectorValidate(form[field])
              fieldValidator(field)
              form[field].isValidGroup = true
              if (fieldHasValue) {
                group.fields.forEach((field) => {
                  form[field].isValidGroup = true
                })
                groupValidation = true
              } else if (!groupValidation) {
                form[field].isValidGroup = false
              }
            })
          }
        })
      }
    }

    return groupValidation
  }

  const stepValidator = (step): boolean => {
    const stepStructure = stepsData.newStepStructure.steps[step]
    let stepVal = true
    const fields = getUsedFields(stepStructure, form)
    let firstField = ''

    fields.map((field) => {
      const fieldValidation = fieldValidator(field)
      if (stepVal) {
        stepVal = stepVal && fieldValidation
        if (!stepVal && firstField === '') firstField = field
      }
    })

    return stepVal
  }

  const fieldValidator = (fieldName): boolean => {
    const tempForm = form
    const field = tempForm[fieldName]
    const required = field.required
    const validation = required ? TypeSelectorValidate(field) === required : true
    field.validate = validation

    updateState({ form: tempForm })

    return validation
  }

  return {
    groupValidator,
    stepValidator,
    fieldValidator
  }
}
