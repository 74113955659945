/* eslint-disable @typescript-eslint/no-explicit-any */

import { datadogLogs } from '@datadog/browser-logs'

import { GetInfoDatalayer } from '../infra'
import { type InfoDatalayer } from '../domain'

export function GetInfoDatalayerUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  applicationId
}: {
  successCallback: (infoDatalayer: InfoDatalayer) => void
  errorCallback?: (error: any) => void
  finallyCallback?: () => void
  applicationId: string
}): void {
  GetInfoDatalayer(applicationId)
    .then((infoDatalayer: InfoDatalayer) => {
      datadogLogs.logger.info(`GetInfoDatalayerUseCase applicationId: ${applicationId}`)
      successCallback(infoDatalayer)
    })
    .catch((error: Error | undefined) => {
      datadogLogs.logger.error(`GetInfoDatalayerUseCase applicationId: ${applicationId}`, error)
      if (errorCallback) errorCallback(error)
    })
    .finally(() => {
      if (finallyCallback) finallyCallback()
    })
}
