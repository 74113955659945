/* eslint-disable @typescript-eslint/no-explicit-any */

import { datadogLogs } from '@datadog/browser-logs'

import { DownloadApplicationPdf } from '../infra'
import { type PdfEncoded } from '../domain'

export function DownloadApplicationPdfUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  applicationId
}: {
  successCallback: (pdfEncoded?: PdfEncoded) => void
  errorCallback?: (error: any) => void
  finallyCallback?: () => void
  applicationId: string
}): void {
  DownloadApplicationPdf(applicationId)
    .then((pdfEncoded: PdfEncoded) => {
      datadogLogs.logger.info(`DownloadApplicationPdfUseCase applicationId: ${applicationId}`)
      successCallback(pdfEncoded)
    })
    .catch((error: Error | undefined) => {
      datadogLogs.logger.error(`DownloadApplicationPdfUseCase applicationId: ${applicationId}`, undefined, error)
      if (errorCallback) errorCallback(error)
    })
    .finally(() => {
      if (finallyCallback) finallyCallback()
    })
}
