/* eslint-disable react-compiler/react-compiler */

/* eslint-disable @typescript-eslint/no-explicit-any */

import { Button } from '@npm_leadtech/legal-lib-components/Button'
import { Radio } from '@npm_leadtech/legal-lib-components/Radio'
import React from 'react'
import { SearchSelect } from '@npm_leadtech/legal-lib-components/SearchSelect'

import { type ProfileDetailsSectionProps } from './ProfileDetailsSectionProps'
import { Section } from '../Section'
import { TextInput } from '../../form'
import { UpdateCustomerByToken } from '@legal/customer'
import { UpdatePasswordModal } from '../../molecules'
import { UserCookie } from '../../../services/storage/cookies/UserCookie'
import { useModal } from '../../../services/hooks/useModal'
import { useToastsCustom } from '../../../services/hooks/useToastsCustom'
import './ProfileDetailsSection.scss'

const reSpecialCharacters = /[!#$%^&*(),?":{}|<>]/
const emailRegex = /^([\w][\w+&.-]+)@([\w-]+\.)+([\w-]{2,4})$/

export const ProfileDetailsSection: React.FC<ProfileDetailsSectionProps> = ({
  customer,
  changeUserEmail,
  profileInformation,
  changePasswordData,
  endpoinstErrorsChangePassword
}) => {
  const { addToastCustom } = useToastsCustom()

  const errorEmailData = profileInformation?.email?.errorMessages?.internal.content
  const errorEmailDataJson: { default: string } = errorEmailData && JSON.parse(errorEmailData)

  const companySectorOptionsData = profileInformation?.companySector?.items?.internal.content
  const jsonCompanySectorOptions: { data: [{ label: string; value: string }] } =
    (companySectorOptionsData && JSON.parse(companySectorOptionsData)) ?? []
  const companySectorOptions = jsonCompanySectorOptions.data

  const employesData = profileInformation?.numberOfEmployees?.items?.internal.content
  const jsonEmployesData: { data: [{ label: string; value: string; checked: boolean }] } =
    employesData && JSON.parse(employesData)
  const numberOfEmployees = jsonEmployesData.data

  const userCookie = new UserCookie()

  const formTypes = {
    text: TextInput,
    select: SearchSelect,
    radio: Radio
  }

  const emptyFormData = {
    firstName: '',
    lastName: '',
    email: '',
    password: '*********',
    companySector: '',
    companySize: '',
    type: 'particular'
  }

  const customerTypeOptions = [
    {
      value: 'particular',
      label: profileInformation?.typeIndividual,
      checked: true
    },
    {
      value: 'company',
      label: profileInformation?.typeCompany,
      checked: false
    }
  ]

  const toastData = {
    success: {
      type: profileInformation?.saveToastSuccess?.type ?? '',
      title: profileInformation?.saveToastSuccess?.title ?? '',
      text: profileInformation?.saveToastSuccess?.text ?? ''
    },
    invalidMail: {
      type: profileInformation?.invalidMailToast?.type ?? '',
      title: profileInformation?.invalidMailToast?.title ?? '',
      text: profileInformation?.invalidMailToast?.text ?? ''
    },
    error: {
      type: profileInformation?.errorSaveToast?.type ?? '',
      title: profileInformation?.errorSaveToast?.title ?? '',
      text: profileInformation?.errorSaveToast?.text ?? ''
    }
  }

  const { modalOpen, changeModalState, closeModal } = useModal()
  const [initialFormData, updateInitialFormData] = React.useState(emptyFormData)
  const [formData, updateFormData] = React.useState(initialFormData)
  const [isEditing, updateIsEditing] = React.useState(false)

  const basicFields = [
    {
      name: 'firstName',
      label: profileInformation?.firstName?.label ?? '',
      placeholder: profileInformation?.firstName?.placeholder ?? '',
      type: 'text',
      validate: true,
      isValidGroup: true,
      required: false
    },
    {
      name: 'lastName',
      label: profileInformation?.lastName?.label ?? '',
      placeholder: profileInformation?.lastName?.placeholder ?? '',
      type: 'text',
      validate: true,
      isValidGroup: true,
      required: false
    },
    {
      name: 'email',
      label: profileInformation?.email?.label ?? '',
      placeholder: profileInformation?.email?.placeholder ?? '',
      type: 'text',
      validate: true,
      isValidGroup: true,
      required: true,
      regex: emailRegex,
      errorMessage: errorEmailDataJson.default ?? ''
    },
    {
      name: 'password',
      label: profileInformation?.password?.label ?? '',
      placeholder: profileInformation?.password?.placeholder ?? '',
      type: 'text',
      validate: true,
      isValidGroup: true,
      disabled: true,
      children: (
        <Button
          givenClass='formgroup--input__button'
          color='tertiary'
          label={profileInformation?.ctaChangePassword ?? ''}
          noLink
          onClick={changeModalState}
        />
      )
    }
  ]

  const additionalFields = [
    {
      name: 'companySector',
      label: profileInformation?.companySector?.label ?? '',
      type: 'select',
      items: companySectorOptions,
      validate: true,
      isValidGroup: true,
      required: false,
      customClass: 'searchselect--fullwidth'
    },
    {
      name: 'companySize',
      label: profileInformation?.numberOfEmployees?.label ?? '',
      type: 'radio',
      items: numberOfEmployees,
      validate: true,
      isValidGroup: true,
      required: false,
      class: '--regular',
      narrow: true
    }
  ]

  const [structure, updateStructure] = React.useState([...basicFields])

  const initData = React.useCallback((): void => {
    const withCustomerData = {
      firstName: customer.firstName ?? formData.firstName,
      lastName: customer.lastName ?? formData.lastName,
      email: customer.email ?? formData.email,
      password: formData.password,
      companySector: customer.company.sector ?? formData.companySector,
      companySize: customer.company.size ?? formData.companySize,
      type: customer.type ?? formData.type
    }
    updateInitialFormData(withCustomerData)
    updateFormData(withCustomerData)
  }, [customer])

  const handleStructure = React.useCallback((customerIsCompany: boolean): void => {
    let currentStructure = [...structure]
    if (customerIsCompany) {
      currentStructure = currentStructure.concat(additionalFields)
    } else {
      currentStructure = currentStructure.filter((field) => {
        return basicFields.find((basicField) => basicField.name === field.name)
      })
    }

    updateStructure(currentStructure)
  }, [])

  React.useEffect(() => {
    initData()
    handleStructure(customer.isCompany)
  }, [customer, handleStructure, initData])

  const onInputChange = ({ target }): void => {
    const currentFormData = { ...formData }
    const name = target.name
    const value = target.value
    if (target.value.value) {
      currentFormData[name] = value.value
    } else {
      if (!value.length) {
        currentFormData[name] = null
      } else {
        currentFormData[name] = value
      }
    }
    updateFormData(currentFormData)
  }

  const isFormChanged = (): boolean => {
    return JSON.stringify(initialFormData) !== JSON.stringify(formData)
  }

  React.useEffect(() => {
    updateIsEditing(isFormChanged())
  }, [formData])

  const submitForm = (): void => {
    const isValid = isFormChanged() && validateForm()

    if (isValid) {
      updateIsEditing(false)
      const currentFormData = { ...formData }
      updateInitialFormData(currentFormData)
      if (formData.type === 'particular') {
        additionalFields.map((field) => {
          currentFormData[field.name] = null
        })
      }

      UpdateCustomerByToken.Execute({ userToken: userCookie.token, data: currentFormData })
        .then((response) => {
          handleToast('success')
          const newUserEmail = currentFormData.email
          userCookie.email = newUserEmail
          changeUserEmail(newUserEmail)
          updateInitialFormData(currentFormData)
          updateIsEditing(false)
          if (response.result?.token) {
            userCookie.token = response.result.token
          }
        })
        .catch((error) => {
          if (error.status === 412) handleToast('invalidMail')
          else handleToast('error')
        })
    }
  }

  const handleToast = (type): void => {
    addToastCustom(toastData[type])
  }

  const validateForm = (): boolean => {
    const invalidFields = structure.filter((field) => {
      const value = formData[field.name]
      const isEmpty = value === null || value === ''

      const matchesRegex = field.regex ? field.regex.test(value) : true
      const emailWithSpecialCharacters = reSpecialCharacters.test(String(formData.email).toLowerCase())

      return field.required && (isEmpty || !matchesRegex || emailWithSpecialCharacters)
    })

    const isValid = invalidFields.length === 0

    if (!isValid) {
      handleErrors(invalidFields)
    }

    return isValid
  }

  const handleErrors = (invalidFields): void => {
    const currentStr = [...structure]
    const invalidFieldNames = invalidFields.map((field) => field.name)
    currentStr.forEach((field) => {
      if (invalidFieldNames.includes(field.name)) {
        field.validate = false
      }
    })

    updateStructure(currentStr)
  }

  const onInputClick = (event): void => {
    const {
      target: { name }
    } = event
    const currentStr = [...structure]
    const currentSelection = currentStr.find((field) => field.name === name)

    currentSelection.validate = true

    updateStructure(currentStr)
  }

  const onTypeChange = ({ target: { value } }): void => {
    handleStructure(value === 'company')
    updateCustomerType(value)
  }

  const updateCustomerType = (type): void => {
    const currentFormData = { ...formData }

    currentFormData.type = type
    updateFormData(currentFormData)
  }

  const optionalComponent = (
    <Radio
      name='type'
      value={formData.type}
      class='--tabs'
      items={customerTypeOptions}
      isValidGroup={true}
      validate={true}
      onChange={onTypeChange}
      small
    />
  )

  const snackbarPasswordUpdater = (): void => {
    addToastCustom({
      title: changePasswordData.editPasswordToastSuccess?.title ?? '',
      autoDismiss: true,
      type: changePasswordData.editPasswordToastSuccess?.type ?? '',
      text: changePasswordData.editPasswordToastSuccess?.text ?? ''
    })
  }

  const parseSelectValue = (
    value
  ): {
    label: any
    value: any
  } => ({ label: value, value })

  const renderFields = structure.map((field) => {
    const Input = formTypes[field.type]
    const value = field.type === 'select' ? parseSelectValue(formData[field.name]) : formData[field.name]
    return (
      <div key={field.name} className='fields--profile__form-group'>
        <Input {...field} value={value} onChange={onInputChange} onClick={onInputClick} />
      </div>
    )
  })

  return (
    <React.Fragment>
      {modalOpen && (
        <UpdatePasswordModal
          closeFunction={closeModal}
          updateSnackbar={snackbarPasswordUpdater}
          changePasswordData={changePasswordData}
          endpointsErrors={endpoinstErrorsChangePassword}
        />
      )}

      <Section
        title={profileInformation?.Title ?? ''}
        subtitle={`${profileInformation?.referenceNumber ?? ''}: [${customer.reference}]`}
        className='section--profile-item section--header--column'
        optionalComponent={optionalComponent}
      >
        <div className='fields--profile'>
          {renderFields}
          <Button
            color='secondary'
            disabled={!isEditing}
            label={profileInformation?.ctaSaveChanges ?? ''}
            noLink
            onClick={submitForm}
            centered
          />
        </div>
      </Section>
    </React.Fragment>
  )
}
