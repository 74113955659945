/* eslint-disable @typescript-eslint/no-explicit-any */

import { datadogLogs } from '@datadog/browser-logs'

import { type CreateSubscriptionNoAuthRequest, type CreateSubscriptionNoAuthResponse } from './interfaces'
import { CreateSubscriptionNoAuth } from '../infra'
import { UserCookie } from '../../../services/storage/cookies/UserCookie'

export function CreateSubscriptionNoAuthUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  request
}: {
  successCallback: (response: CreateSubscriptionNoAuthResponse) => void
  errorCallback?: (error: any) => void
  finallyCallback?: () => void
  request: CreateSubscriptionNoAuthRequest
}): void {
  CreateSubscriptionNoAuth(request)
    .then((response) => {
      const user = new UserCookie()
      if (user.token) {
        datadogLogs.logger.error(
          'CreateSubscriptionNoAuthUseCase: Usuario logeado intenta crear una subscripcion sin autenticacion',
          request
        )
        return
      }
      datadogLogs.logger.info('CreateSubscriptionNoAuthUseCase', request)
      successCallback(response)
    })
    .catch((error) => {
      datadogLogs.logger.error('CreateSubscriptionNoAuthUseCase', request, error)
      if (errorCallback) errorCallback(error)
    })
    .finally(() => {
      if (finallyCallback) finallyCallback()
    })
}
