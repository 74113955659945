import { datadogLogs } from '@datadog/browser-logs'

import { CustomerAuth, GetCustomer } from '../infra'
import { type CustomerAuthRequest, type CustomerAuthResponse } from './interfaces'
import { type ApiError } from '@legal/shared/HttpClient'
import { UserCookie } from 'src/services/storage/cookies/UserCookie'
import { setAmplitudeUserId } from '@legal/shared/amplitude'

export function CustomerAuthUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  request
}: {
  successCallback: (response: CustomerAuthResponse) => void
  errorCallback?: (error?: ApiError) => void
  finallyCallback?: () => void
  request: CustomerAuthRequest
}): void {
  const user = new UserCookie()
  if (user.token) {
    datadogLogs.logger.error('CustomerAuthUseCase', { error: 'User already authenticated' })
    return
  }
  CustomerAuth(request)
    .then(async (response) => {
      if (response.customerId) setAmplitudeUserId(response.customerId)
      else await getCustomerToAmplitude(response.token)
      datadogLogs.logger.info('CustomerAuthUseCase')
      successCallback(response)
    })

    .catch((error: ApiError | undefined) => {
      datadogLogs.logger.error(`CustomerAuthUseCase: ${error?.message}`, undefined)
      if (errorCallback) errorCallback(error)
    })
    .finally(() => {
      if (finallyCallback) finallyCallback()
    })
}

const getCustomerToAmplitude = async (token?: string): Promise<void> => {
  if (token) {
    const user = new UserCookie()
    user.token = token
    const dataCustomer = await GetCustomer()
    if (dataCustomer.id) {
      setAmplitudeUserId(dataCustomer.id)
    }
  }
}
