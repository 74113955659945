/* eslint-disable react-compiler/react-compiler */
/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button } from '@npm_leadtech/legal-lib-components/Button'
import React from 'react'
import ReactDOMServer from 'react-dom/server'

import { FormContext } from '../../../services/utils/contexts'
import { type NavBarProps } from './NavBarProps'
import { NavbarOverlay } from '../NavbarOverlay'
import { UpdateApplicationDataUseCase } from '@legal/application'
import { getValueFromForm } from '../../../services/form/getValueFromForm'
import { useApplicationCookie } from '@legal/shared/hooks'
import './NavBar.scss'

export const NavBar: React.FC<NavBarProps> = ({
  allSteps,
  currentStep,
  customGoToStep,
  documentTitle,
  formData,
  isValidGroup,
  onClose,
  previewButton,
  sections,
  sectionsActivated,
  stepValidator,
  submitForm
}) => {
  const currentStepData = allSteps[currentStep - 1]
  const currentStepSlug = currentStepData.slug
  const formContext = React.useContext(FormContext)
  const { applicationCookie } = useApplicationCookie()
  const [showSectionName, setShowSectionName] = React.useState(true)
  const steps = []

  const showHideSectionName = (): void => {
    setShowSectionName(!showSectionName)
  }

  const nextStep = (): void => {
    getStepDataAndUpdate()
    if (stepValidator(currentStep - 1) && isValidGroup(currentStep - 1)) {
      customGoToStep.goToStep(currentStep + 1)
    }
    onClose()
  }

  const changeStep = (selectedStep): void => {
    getStepDataAndUpdate()
    customGoToStep.goToStep(selectedStep)
    window.scrollTo(0, 0)
    onClose()
  }

  const completeApplication = (): void => {
    const inputData = getCurrentStepData()
    const data = {
      data: inputData,
      step: currentStepData
    }

    submitForm(data)
  }

  const getCurrentStepData = (): any => {
    const data = {}

    currentStepData.groups.map((group) => {
      group.fields.map((field) => {
        const fieldName = formData[field].name
        const fieldValue = formData[field].value
        data[fieldName] = fieldValue
      })
    })

    return data
  }
  const getStepDataAndUpdate = (): void => {
    if (currentStepSlug === 'preview-and-download' || !applicationCookie?.id) return
    UpdateApplicationDataUseCase({
      applicationId: applicationCookie.id,
      request: {
        allSteps,
        data: getCurrentStepData(),
        step: currentStepSlug,
        stepNumber: currentStep,

        html: ReactDOMServer.renderToStaticMarkup(formContext.template)
      },
      successCallback: () => {
        applicationCookie.lastStep = currentStep
      }
    })
  }

  const isCurrentSection = (section): boolean => {
    return allSteps[currentStep - 1].sectionNav === section
  }

  const iterableObject = !sectionsActivated ? allSteps : sections

  for (let step = 1; step <= iterableObject.length; step += 1) {
    let isDone, isCurrent, stepLabel, stepLink
    if (!sectionsActivated) {
      isDone = currentStep > step
      isCurrent = currentStep === step
      stepLabel = allSteps[step - 1].label
      stepLink = step
    } else {
      const section = sections[step - 1]
      if (section) {
        isCurrent = isCurrentSection(section)
        isDone = sections.indexOf(allSteps[currentStep - 1].sectionNav) > step - 1
        stepLabel = section
        stepLink = allSteps.findIndex((step) => step.sectionNav === section) + 1
      }
    }
    const classNames = `${isCurrent ? '--isCurrent' : ''} ${isDone ? '--isDone' : ''}`

    if (stepLabel.includes('{{')) {
      const regexpWithCurlyBrackets = /\{{.*?\}}/g
      const resWithCurlyBrackets = stepLabel.match(regexpWithCurlyBrackets)

      if (resWithCurlyBrackets) {
        resWithCurlyBrackets.forEach((result) => {
          stepLabel = getValueFromForm(result, formData, stepLabel)
        })
      }
    }

    steps.push(
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
      <li
        key={stepLink}
        data-qa={'changeStep'}
        className={`section__item sans-serif --medium ${classNames}`}
        onClick={() => {
          changeStep(stepLink)
        }}
      >
        <>
          <span className='section__item__number'>{step}. </span>
          {showSectionName && (
            <div className='section__item__name'>
              <span className='section__item__name__text'>{stepLabel}</span>
            </div>
          )}
        </>
      </li>
    )
  }

  const navClassNames = `sections__nav sidebar ${showSectionName ? '--full' : '--narrow'} `
  const isLastStep = currentStep === allSteps.length
  const defaultButton = <Button noLink label='Save and Continue' onClick={nextStep} />
  const lastStepButton = <Button noLink label='Complete Application' onClick={completeApplication} />
  const CTA = isLastStep ? lastStepButton : defaultButton

  const overlayAttributes = {
    customClassNames: navClassNames,
    steps,
    previewButton,
    sectionTrigger: <div className={'section__trigger'} onClick={showHideSectionName} />,
    footer: CTA,
    onClose,
    documentTitle,
    sections
  }

  return <NavbarOverlay {...overlayAttributes} />
}
