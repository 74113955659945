/* eslint-disable @typescript-eslint/no-explicit-any */

import { graphql, navigate, useStaticQuery } from 'gatsby'
import { BoxForm } from '@npm_leadtech/legal-lib-components/BoxForm'
import React from 'react'

import { CreatePasswordForm, HeaderWithIcon } from '../../molecules'
import { getConfigs, useConfigs } from '@legal/core/configs'
import { type CreatePasswordPageTemplateProps } from './CreatePasswordPageTemplateProps'
import { Layout } from '../../layout'
import { ProductCookie } from '../../../services/storage/cookies/ProductCookie'
import { ReferrerCookie } from '../../../services/storage/cookies/ReferrerCookie'
import { SubscriptionCookie } from '../../../services/storage/cookies/SubscriptionCookie'
import { UserCookie } from '../../../services/storage/cookies/UserCookie'
import security from '../../../assets/images/svg/icon-24-px-security.svg'
import { sendAmplitudeData } from '@legal/shared/amplitude'
import './CreatePasswordPageTemplate.scss'

export const CreatePasswordPageTemplate: React.FC<CreatePasswordPageTemplateProps> = ({
  applicationId,
  documentType,
  categoryProduct,
  isRatafiaPage = false
}) => {
  const createPasswordData = useQueryCreatePassword()
  const { TARGET_ADDRESS } = useConfigs()
  const { RATAFIA_CONFIG } = getConfigs()

  React.useEffect(() => {
    if (!RATAFIA_CONFIG.ENABLED && isRatafiaPage) void navigate('/my-account/my-documents/')
    const userCookie = new UserCookie()
    if (userCookie.token) {
      const subscriptionCookie = new SubscriptionCookie()
      if (subscriptionCookie.subscriptionId && applicationId && documentType && categoryProduct && !isRatafiaPage) {
        const referrerCookie = new ReferrerCookie()
        const productCookie = new ProductCookie()
        const eventProps = {
          source: referrerCookie.getReferrer(),
          applicationId,
          document_type: productCookie.product || documentType,
          document_subtype: categoryProduct
        }
        sendAmplitudeData('show_password_page', eventProps)
      }
    } else {
      void navigate('/my-account/my-documents/')
    }
  }, [])

  return (
    <Layout
      headerBackground={'create-password'}
      environment={TARGET_ADDRESS}
      hideDashboardButton
      disclaimerLinks
      noFooter
      footerContactBar
      isTestAB8814
      mobileHideGoToDashboardButton
      mobileHideDocumentsList
      mobileHideResources
    >
      <div className='create-password'>
        <div className='create-password__background'>
          <div className='create-password__container'>
            <HeaderWithIcon icon={security} title={createPasswordData.title} subtitle={createPasswordData.subtitle} />
            <div className='create-password__form-container'>
              <BoxForm
                body={<CreatePasswordForm createPasswordData={createPasswordData} isRatafiaPage={isRatafiaPage} />}
                hideHeader
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const useQueryCreatePassword = (): Record<string, any> => {
  const { strapiCreatePasswordPage } = useStaticQuery(graphql`
    query {
      strapiCreatePasswordPage {
        title
        subtitle
        minCharactersError
        passwordMatchError
        changePasswordErrorToast {
          title
          text
          buttonText
          type
        }
        passwordInputField {
          label
          placeholder
        }
        repeatPasswordInputField {
          label
        }
        createAccountText
        acceptTermsText
        termsAndConditionsText
        privacyPolicyText
      }
    }
  `)

  return strapiCreatePasswordPage
}
