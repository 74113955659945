/* eslint-disable @typescript-eslint/strict-boolean-expressions */

/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { type GatsbyBrowser, Script } from 'gatsby'
import React from 'react'
import { datadogLogs } from '@datadog/browser-logs'

import {
  type BusinessHoursProps,
  type BusinessProps,
  validateDateGmt
} from './src/modules/shared/utils/validateDateGmt/validateDateGmt'
import { CookieContextProvider } from './src/modules/shared/cookies/CookieContext'
import { SnackbarLayout } from './src/components/layout'
import { enableTestABCookie } from './src/services/utils/setTestABCookie'
import { getConfigs } from './src/modules/core/configs'
import { getParamsUrl } from './src/services/utils/paramsUrl'
import { initAmplitude } from './src/modules/shared/amplitude'
import { setUtm } from './src/services/sem/setUtm'
import version from './static/version.json'

const { DATADOG_CONFIG, FRESHCHAT_CONFIG } = getConfigs()

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }): React.JSX.Element => (
  <CookieContextProvider>{element}</CookieContextProvider>
)

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element, props }) => {
  const SNACKBAR_PAGES = 'my-account'
  const snackbarLayoutOverflow = props.location.pathname.includes(SNACKBAR_PAGES) ? 'snackbar-layout-overflow' : ''

  return (
    <>
      <SnackbarLayout snackbarLayoutOverflow={snackbarLayoutOverflow} {...props}>
        {element}
      </SnackbarLayout>

      {FRESHCHAT_CONFIG.FRESHCHAT && (
        <>
          <Script
            strategy='idle'
            id='fcPreChatForm'
            src='https://snippets.freshchat.com/js/fc-pre-chat-form-v2.min.js'
            onLoad={() => {
              window.preChatTemplate = {
                heading: 'Chat with Us',
                mainbgColor: '#078080',
                maintxColor: '#fff',
                textBanner:
                  "We can't wait to talk to you. But first, please take a couple of moments to tell us a bit about yourself.",
                SubmitLabel: 'Start Chat',
                fields: {
                  field1: {
                    error: 'Please Enter a valid name',
                    fieldId: 'name',
                    label: 'Name',
                    required: 'yes',
                    type: 'text'
                  },
                  field2: {
                    error: 'Please Enter a valid Email',
                    fieldId: 'email',
                    label: 'Email',
                    required: 'yes',
                    type: 'email'
                  }
                }
              }
              window.fcSettings = {
                token: FRESHCHAT_CONFIG.TOKEN,
                host: FRESHCHAT_CONFIG.HOST,
                widgetUuid: FRESHCHAT_CONFIG.WIDGET_ID,
                config: {
                  content: {
                    actions: {
                      tab_chat: 'Chat'
                    },
                    headers: {
                      chat: 'Chat With Us'
                    }
                  },
                  cssNames: {
                    expanded: 'custom_fc_expanded',
                    widget: 'custom_fc_frame'
                  }
                },
                onInit: function () {
                  window.fcPreChatform.fcWidgetInit(window.preChatTemplate)
                }
              }
            }}
          />
          <Script
            strategy='idle'
            src='https://wchat.eu.freshchat.com/js/widget.js'
            onLoad={() => {
              window.fcWidget.on('widget:loaded', function (resp) {
                window.fcWidget.conversation.setConversationProperties({
                  cf_customer_url: window.location.href
                })
              })

              const hoursBusiness: BusinessHoursProps = {
                from: {
                  hour: 12,
                  minute: 0
                },
                to: {
                  hour: 2,
                  minute: 0
                }
              }
              const businessHoursConfig: BusinessProps = {
                Monday: hoursBusiness,
                Tuesday: hoursBusiness,
                Wednesday: hoursBusiness,
                Thursday: hoursBusiness,
                Friday: hoursBusiness,
                Saturday: hoursBusiness,
                Sunday: hoursBusiness
              }

              const businessInTime = validateDateGmt({ businessHoursConfig })
              if (!businessInTime) {
                document.body.classList.add('freshChat_hide')
              }
            }}
          />
        </>
      )}
    </>
  )
}

export const onClientEntry: GatsbyBrowser['onClientEntry'] = () => {
  enableTestABCookie('8814')

  const itemsParamsUrl = getParamsUrl()
  itemsParamsUrl && setUtm({ itemsParamsUrl })
  initAmplitude()
  datadogLogs.init({
    clientToken: DATADOG_CONFIG.CLIENT_TOKEN,
    site: DATADOG_CONFIG.SITE,
    service: DATADOG_CONFIG.SERVICE,
    env: DATADOG_CONFIG.ENVIRONMENT,
    version: version.version,
    forwardErrorsToLogs: true,
    sampleRate: 10,
    trackInteractions: true,
    enabled: true
  })
}

/* Retrieve referrer URL in every Gatsby Page - using location prop */
export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({ location, prevLocation }) => {
  if (location) {
    const referrer = prevLocation ? prevLocation.pathname : null
    location = { ...location, state: { ...location.state, referrer } }
  }
}
