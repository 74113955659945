/* eslint-disable @typescript-eslint/strict-boolean-expressions */

/* eslint-disable @typescript-eslint/no-explicit-any */

import { Button } from '@npm_leadtech/legal-lib-components/Button'
import { DocumentStatus } from '@npm_leadtech/legal-lib-components/DocumentStatus'
import React from 'react'
import { navigate } from 'gatsby'

import {
  CreateCustomerSubscriptionUseCase,
  GetSubscriptionTypeByCodeUseCase,
  GetSubscriptionTypeUpgradeUseCase,
  type Subscription,
  type UpgradeSubscriptionResponse,
  UpgradeSubscriptionUseCase
} from '@legal/subscription'
import DocIcon from '../../../assets/images/componentsSvg/doc-24-p.svg'
import { DocumentActionsMenu } from '../../molecules'
import { type DocumentCardProps } from './DocumentCardProps'
import { formatDate } from '@legal/shared/utils/formatDate'

import Download from '../../../assets/images/componentsSvg/save-24-px.svg'

import { PaymentCookie } from '../../../services/storage/cookies/PaymentCookie'
import { SubscriptionCookie } from '../../../services/storage/cookies/SubscriptionCookie'
import { TextInput } from '../../form'
import { UpdateApplicationNameUseCase } from '@legal/application'
import './DocumentCard.scss'

export const DocumentCard: React.FC<DocumentCardProps> = ({
  name,
  downloadPdf,
  subscriptionId,
  applicationId,
  actions,
  formType,
  subscriptionTypeId,
  formTypeName,
  editClicked,
  updatedAt,
  state,
  status,
  deleteClicked,
  duplicateClicked,
  previewClicked,
  setOpenMenu,
  openMenu,
  onEditApplicationName,
  currentSubscription,
  data
}) => {
  const [editingFormName, setEditingFormName] = React.useState<boolean>(false)
  const [formName, setFormName] = React.useState<string>(name)
  const [originalFormName, setOriginalFormName] = React.useState<string>(name)
  const inputFormNameRef = React.useRef(null)
  const [subscriptionName, setSubscriptionName] = React.useState<string>()
  const paymentCookie = new PaymentCookie()
  let actionButton = null

  React.useEffect(() => {
    if (inputFormNameRef.current) {
      inputFormNameRef.current.focus()
    }
  }, [editingFormName])

  const onChangeFormName = React.useCallback((e: any): void => {
    const target = e.target
    const value = target.value

    if (value.length < 40) {
      setFormName(value)
    }
  }, [])

  const onBlurFormName = React.useCallback((e: any): void => {
    UpdateApplicationNameUseCase({
      applicationId,
      name: e.target.value,
      successCallback: () => {
        setOriginalFormName(e.target.value)

        onEditApplicationName(applicationId, e.target.value)
      },
      errorCallback: () => {
        setFormName(originalFormName)
      },
      finallyCallback: () => {
        setEditingFormName(false)
      }
    })
  }, [])

  const getSubscriptionTypeByCodeSuccess = (subscriptions: Subscription[]): void => {
    const subscriptionCookie = new SubscriptionCookie()
    subscriptionCookie.subscriptionId = currentSubscription
    subscriptionCookie.subscriptionTypeId = subscriptions[0].id
    setSubscriptionName(subscriptions[0].type.name)
    CreateCustomerSubscriptionUseCase({
      request: {
        subscriptionPriceId: subscriptions[0].id,
        applicationId
      },
      successCallback: createCustomerSubscriptionSuccessCallback
    })
  }

  const createCustomerSubscriptionSuccessCallback = (subscriptionId: string): void => {
    const subscriptionCookie = new SubscriptionCookie()
    if (subscriptionId) subscriptionCookie.subscriptionId = subscriptionId
    const subType = subscriptionName ? subscriptionName.toLowerCase().split(' ').join('-') : ''
    let formUrl = `/payment/?product=${formType.toLowerCase().split(' ').join('-')}`
    if (state !== formType) {
      formUrl = `${formUrl}&type=${state.toLowerCase().split(' ').join('-')}`
    }
    if (subType !== '') {
      formUrl = `${formUrl}&subs-type=${subType}`
    }
    void navigate(formUrl, {
      state: {
        applicationId,
        subscriptionId,
        subscriptionTypeId: subscriptionTypeId ?? null,
        formName: formTypeName
      }
    })
  }

  const upgradeAccount = (): void => {
    if (currentSubscription) {
      GetSubscriptionTypeUpgradeUseCase({
        successCallback: (response) => {
          if (response[0]?.type?.name) {
            changeToNewSubscription(response[0].type.name, response[0].id)
          }
        }
      })
    } else {
      GetSubscriptionTypeByCodeUseCase({ successCallback: getSubscriptionTypeByCodeSuccess, tokenRequired: true })
    }
  }

  const upgradeSubscriptionCallback = (
    response: UpgradeSubscriptionResponse,
    cookie: SubscriptionCookie,
    subName: string
  ): void => {
    if (response.subscriptionId) cookie.subscriptionId = response.subscriptionId
    const subType = subName.toLowerCase().split(' ').join('-')
    let formUrl = `/payment/?product=${formType.toLowerCase().split(' ').join('-')}`
    if (state !== formType) formUrl = `${formUrl}&type=${state.toLowerCase().split(' ').join('-')}`
    if (subType !== '') formUrl = `${formUrl}&subs-type=${subType}`
    void navigate(formUrl, {
      state: {
        applicationId,
        subscriptionId,
        subscriptionTypeId: subscriptionTypeId ?? null,
        formName: formTypeName
      }
    })
  }

  const changeToNewSubscription = (subName: string, currentSubType: string): void => {
    const subscriptionCookie = new SubscriptionCookie()
    subscriptionCookie.subscriptionId = currentSubscription
    subscriptionCookie.subscriptionTypeId = currentSubType

    UpgradeSubscriptionUseCase({
      request: {
        currentSubscriptionId: currentSubscription,
        subscriptionPriceId: currentSubType
      },
      successCallback: (response) => {
        upgradeSubscriptionCallback(response, subscriptionCookie, subName)
      }
    })
  }

  let continueActive = false

  if (actions.pay) {
    actionButton = (
      <Button
        noLink
        onClick={upgradeAccount}
        color='secondary3'
        label={data.strapiMyDocumentsPage?.documentsSection?.myDocumentCard?.upgradeAccountText}
      />
    )
  } else if (actions.download) {
    actionButton = (
      <Button
        noLink
        onClick={downloadPdf}
        functionParameters={applicationId}
        color='secondary3'
        label={data.strapiMyDocumentsPage?.documentsSection?.myDocumentCard?.downloadText}
        dataQa='download_document_button'
      >
        <Download />
      </Button>
    )
  } else if (actions.edit) {
    continueActive = true
    actionButton = (
      <Button
        givenClass='edit-button'
        noLink
        onClick={() => {
          editClicked(applicationId, 'Continue Editing')
        }}
        functionParameters={applicationId}
        color='secondary3'
        label={data.strapiMyDocumentsPage?.documentsSection?.myDocumentCard?.continueEditingText}
      />
    )
  } else if (!actions.pay) paymentCookie.clear()

  const formatText = data.strapiMyDocumentsPage?.documentsSection?.myDocumentCard?.updatedAtFormatText
  const occurrencies = /\[(.*?)\]/.exec(formatText)
  const updatedAtFormatted =
    updatedAt && occurrencies ?
      formatDate({ date: updatedAt, format: formatText }).replace(',', ` ${occurrencies[1]}`)
    : null

  return (
    <div className={'document-card'}>
      <div className='document-card__inner'>
        <div className={'document-card__icon'}>
          <DocIcon />
        </div>
        <div className={'document-card__information'}>
          <div className={'document-card-column document-card__information__general'}>
            {!editingFormName && <p className={'document__name sans-serif --medium'}>{formName}</p>}
            {editingFormName && (
              <TextInput
                type={'text'}
                placeholder={''}
                givenClass='document-name'
                value={formName}
                required={false}
                isValidGroup={true}
                maxlength={100}
                validate={true}
                onBlur={onBlurFormName}
                onChange={onChangeFormName}
                ref={inputFormNameRef}
              />
            )}
            <p className={'document__state sans-serif --small'}>{state}</p>
            <time dateTime={updatedAtFormatted} className={'document__creation'}>
              {updatedAtFormatted}
            </time>
          </div>
          <div className={'document-card-column document-card-column__status'}>
            <div className={'document-status__wrapper'}>
              <DocumentStatus
                type={status}
                statusCompletedText={data.strapiMyDocumentsPage?.documentsSection?.myDocumentCard?.statusCompletedText}
                statusNewText={data.strapiMyDocumentsPage?.documentsSection?.myDocumentCard?.statusNewText}
                statusInProgressText={
                  data.strapiMyDocumentsPage?.documentsSection?.myDocumentCard?.statusInProgressText
                }
              />
            </div>
          </div>
          <div className={'document-card-column document-card-column__button'}>
            <div className={'document-card-column__button__inner'}>{actionButton}</div>
          </div>
          <DocumentActionsMenu
            editClicked={() => {
              editClicked(applicationId, 'Dashboard edit')
            }}
            editNameClicked={() => {
              setEditingFormName(true)
            }}
            deleteClicked={() => {
              deleteClicked(applicationId)
            }}
            deleteAction={actions.delete}
            duplicateClicked={() => {
              duplicateClicked(applicationId)
            }}
            duplicateAction={actions.duplicate}
            previewClicked={() => {
              previewClicked(applicationId)
            }}
            previewAction={actions.preview}
            setOpenMenu={setOpenMenu}
            openMenu={openMenu}
            editActive={!continueActive}
            data={data}
          />
        </div>
      </div>
    </div>
  )
}
