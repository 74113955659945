/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */

import React, { useEffect, useState } from 'react'
import sanitizeHtml from 'sanitize-html'

import { AMPLITUDE_TEST_AB_VERSION, sendAmplitudeData } from '@legal/shared/amplitude'
import { GetSubscriptionTypeByCodeUseCase, GetSubscriptionUseCase, type Subscription } from '@legal/subscription'
import { EVERY_4_WEEKS } from '@legal/shared/data'
import { type PaymentSummaryProps } from './PaymentSummaryProps'
import { SubscriptionCookie } from '../../../services/storage/cookies/SubscriptionCookie'
import { UserCookie } from '../../../services/storage/cookies/UserCookie'
import bitmap1 from '../../../assets/images/svg/Bitmap-1.svg'
import bitmap2 from '../../../assets/images/svg/Bitmap-2.svg'
import comodosecure from '../../../assets/images/png/comodo-secure.png'
import { formatPrice } from '@legal/shared/utils'
import { replaceValues } from '../../../services/utils/replaceAll'
import { useBenefitsListToShow } from '@legal/shared/hooks/useBenefitsListToshow'
import { useConfigs } from '@legal/core/configs'
import { useSubscriptionDataStrapi } from '@legal/shared/hooks/useSubscriptionDataStrapi'

import ssl from '../../../assets/images/svg/SSL_certificate.svg'

const DISPLAY_PRICE = '{{DISPLAY_PRICE}}'

export const PaymentSummary: React.FC<PaymentSummaryProps> = ({
  currencyData,
  currencySymbol,
  everyFourWeeks,
  subscriptionInformation,
  formNameForAmplitude,
  paymentSummaryData
}) => {
  const {
    IS_JONSNOW,
    SITE_METADATA: { SITE_PHONE }
  } = useConfigs()
  const [currentSubscription, setCurrentSubscription] = useState<Subscription>()
  const [currentSubscriptionInitialAmount, setCurrentSubscriptionInitialAmount] = useState<string>()

  const userCookie = new UserCookie()
  const subscriptionCookie = new SubscriptionCookie()

  const userToken = userCookie.token ?? ''

  let currentSubscriptionPrice, currentSubscriptionCurrency

  useEffect(() => {
    if (!subscriptionCookie) {
      window.location = '/pricing/'
    }
  }, [])

  useEffect(() => {
    if (subscriptionInformation) {
      getSubscriptionInformation(userToken)
    }
  }, [subscriptionInformation])

  useEffect(() => {
    if (currentSubscription) {
      const eventProps = {
        initial_document_type: formNameForAmplitude ?? '',
        change_price_allowed: currentSubscription.type.name !== 'Best Value',
        version: AMPLITUDE_TEST_AB_VERSION.PRODUCTION
      }
      sendAmplitudeData('show_payment_page', eventProps)
    }
  }, [currentSubscription])

  const getSubscriptionInformation = (userToken?: string): void => {
    const subscriptionPriceId = subscriptionCookie.subscriptionId ?? window.history.state?.subscriptionId
    if (!userToken) {
      GetSubscriptionTypeByCodeUseCase({
        successCallback: (subscriptions) => {
          setCurrentSubscription(subscriptions[0])
          setCurrentSubscriptionInitialAmount(subscriptions[0]?.initialAmountFormated)
        }
      })
      return
    }
    GetSubscriptionUseCase({
      request: { subscriptionId: subscriptionPriceId },
      successCallback: (response) => {
        if (response.id === subscriptionCookie.subscriptionId) {
          setCurrentSubscription(response)
          setCurrentSubscriptionInitialAmount(response.price?.initialAmount)
        }
      }
    })
  }

  const splitPriceAndCurrency = (displayPrice: string): any[] => {
    let [priceValue, priceCurrency] = displayPrice.split(' ')
    priceValue = formatPrice(+priceValue.replace(currencySymbol, '').replace('$', ''))
    priceCurrency = currencyData ?? 'USD'
    if (displayPrice.includes(`USD ${EVERY_4_WEEKS}`)) {
      priceCurrency = `${currencyData} ${everyFourWeeks}`
    }
    return [priceValue, priceCurrency]
  }

  if (currentSubscription?.type.config) {
    const currentDisplayPriceValue = currentSubscription.type.config.pricingPage.displayPrice.value
    ;[currentSubscriptionPrice, currentSubscriptionCurrency] = splitPriceAndCurrency(currentDisplayPriceValue)
  }

  const { benefitsListToShow } = useBenefitsListToShow({ subscription: currentSubscription })
  const { subscriptionData } = useSubscriptionDataStrapi({
    nameSubscription: currentSubscription?.type?.name ?? ''
  })

  return (
    <div className='right-container'>
      {currentSubscription && (
        <div className='m-paymentForm-container inner-container top'>
          <div>
            <div className='right-container__title'>
              <h3 className='bold sans-serif --big'>{paymentSummaryData?.title}</h3>
            </div>
            <hr />
            <>
              <p>{subscriptionData?.name}</p>
              {currentSubscription.type.config && (
                <h2 className='price'>
                  {currencySymbol}
                  {currentSubscriptionPrice}
                  <span className='currency currency-small'>{currencyData || currentSubscription.currency}</span>
                </h2>
              )}
              {benefitsListToShow?.map((item, index) => (
                <small
                  key={`subscription-content-${index}`}
                  className='small-text sans-serif --small'
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(item.includes(DISPLAY_PRICE) ? paymentSummaryData?.cancelAnyTime : item)
                  }}
                />
              ))}
              {currentSubscription.type.name !== '7-Day Access' && !IS_JONSNOW && (
                <small className='small-text sans-serif --small'>
                  {replaceValues(paymentSummaryData?.contactUs, { SITE_PHONE })}
                </small>
              )}
              <br />
            </>
          </div>
          <br />
          <h3 className='sans-serif --big'>{paymentSummaryData?.orderSummary}</h3>
          <hr className='bottom-hr' />
          <p>
            {paymentSummaryData?.totalAmount}{' '}
            <span className='bold'>{`${currencySymbol}${currentSubscriptionInitialAmount}`}</span>
            <span className='currency'>{currentSubscriptionInitialAmount && `${currentSubscriptionCurrency}`}</span>
          </p>
        </div>
      )}
      <div className='m-paymentForm-container inner-container bottom'>
        <p>{paymentSummaryData?.payWithConfidence}</p>
        <p>{paymentSummaryData?.thisSiteIsSSLSecured}</p>
        <div className='m-paymentForm-images'>
          <img alt='' src={bitmap1} />
          <img alt='' src={comodosecure} />
          <img alt='' src={bitmap2} />
          <img alt='' src={ssl} />
        </div>
      </div>
    </div>
  )
}
