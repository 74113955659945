/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable @typescript-eslint/no-unused-vars */

import { Button } from '@npm_leadtech/legal-lib-components/Button'
import React from 'react'
import { TopBar } from '@npm_leadtech/legal-lib-components/TopBar'
import classnames from 'classnames'

import { type PrivateAreaContentProps } from './PrivateAreaContentProps'
import { TopBarMobileContent } from '../TopBarMobileContent'
import Upload from '../../../assets/images/componentsSvg/upload-doc.svg'
import { useConfigs } from '@legal/core/configs'
import { useIsMobile } from '@legal/shared/hooks'
import './PrivateAreaContent.scss'

export const PrivateAreaContent: React.FC<PrivateAreaContentProps> = ({
  children,
  customClass,
  title,
  wide = false,
  activeMenuOption,
  applicationsLoaded,
  documentUploadFileDialog,
  uploadFileProcess
}): React.ReactElement => {
  const [dragStatus, setDragStatus] = React.useState('')
  const { IS_JONSNOW, PA_UPLOAD_DOCUMENT_ENABLED } = useConfigs()
  const isMobile = useIsMobile(0)
  const bodyClasses = classnames('private-area-content__body', {
    'private-area-content__body--wide': wide,
    'private-area-content__body--regular': !wide
  })

  const handleDrop = React.useCallback((event) => {
    if (!PA_UPLOAD_DOCUMENT_ENABLED || activeMenuOption !== 0) return
    setDragStatus('')
    event.preventDefault()
    const droppedFiles = event.dataTransfer.files
    if (droppedFiles.length) {
      const file = droppedFiles[0]
      uploadFileProcess(file)
    }
  }, [])

  const handleDragOver = React.useCallback((event) => {
    if (!PA_UPLOAD_DOCUMENT_ENABLED || activeMenuOption !== 0) return
    setDragStatus('dragging')
    event.preventDefault()
  }, [])

  const handleDragOut = React.useCallback((event) => {
    if (!PA_UPLOAD_DOCUMENT_ENABLED || activeMenuOption !== 0) return
    setDragStatus('')
  }, [])

  return (
    <div
      className={`private-area-content ${customClass ?? ''} ${dragStatus}`}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragOut}
    >
      {!IS_JONSNOW && isMobile && (
        <TopBar>
          <TopBarMobileContent />
        </TopBar>
      )}
      <div className='private-area-content__header'>
        {title ?
          <h1 className='private-area-content__title'>{title}</h1>
        : null}
        {PA_UPLOAD_DOCUMENT_ENABLED && !isMobile && applicationsLoaded && activeMenuOption === 0 ?
          <div className='private-area-content__header-actions'>
            <Button
              noLink
              onClick={documentUploadFileDialog}
              functionParameters={() => {}}
              color='secondary'
              label={'Upload document'}
              dataQa='upload_document_header_button'
            >
              <Upload />
            </Button>
          </div>
        : null}
      </div>
      <div className={bodyClasses}>{children}</div>
    </div>
  )
}
