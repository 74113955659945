/* eslint-disable @typescript-eslint/no-explicit-any */

import { CreateTransactionExecute } from '../infra'
import { type Transaction } from '../domain'
import { datadogLogs } from '@datadog/browser-logs'

export function CreateTransactionExecuteUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  transaction
}: {
  successCallback?: () => void
  errorCallback?: (error: any) => void
  finallyCallback?: () => void
  transaction: Transaction
}): void {
  CreateTransactionExecute(transaction)
    .then(() => {
      datadogLogs.logger.info('CreateTransactionExecuteUseCase')
      if (successCallback) successCallback()
    })
    .catch((error) => {
      datadogLogs.logger.error('CreateTransactionExecuteUseCase', undefined, error)
      if (errorCallback) errorCallback(error)
    })
    .finally(() => {
      if (finallyCallback) finallyCallback()
    })
}
