import { type Application } from '../../domain'
import { type ApplicationData } from '../dataObjects'
import { ApplicationDataToApplication } from '../adapters'
import { ENDPOINTS } from './Endpoints'
import { get } from '@legal/shared/HttpClient'

export async function FindApplicationByUuid(applicationId: string): Promise<Application> {
  const result = await get<ApplicationData>(ENDPOINTS.FIND_APPLICATION(applicationId))

  if (result === null) throw new Error('Application not found')
  return ApplicationDataToApplication(result)
}
