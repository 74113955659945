/* eslint-disable @typescript-eslint/no-explicit-any */

import { datadogLogs } from '@datadog/browser-logs'

import { AdyenExecuteTransaction } from '../infra'
import { type Transaction } from '../domain'

export function AdyenExecuteTransactionUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  transaction
}: {
  successCallback?: () => void
  errorCallback?: (error: any) => void
  finallyCallback?: () => void
  transaction: Transaction
}): void {
  AdyenExecuteTransaction(transaction)
    .then(() => {
      datadogLogs.logger.info('AdyenExecuteTransactionUseCase')
      if (successCallback) successCallback()
    })
    .catch((error) => {
      datadogLogs.logger.error('AdyenExecuteTransactionUseCase', undefined, error)
      if (errorCallback) errorCallback(error)
    })
    .finally(() => {
      if (finallyCallback) finallyCallback()
    })
}
